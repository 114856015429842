import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Error from "../../../../../../Errors/SearchError/Error";
import Pagination from "../../../../../../../config/pagination/Pagination";
import { getApi } from "../../../../../../../config/apiFunctions";
import { guide_received_bookings } from "../../../../../../../config/endpoints";
import { Oval } from "react-loader-spinner";
import { GUIDE_BOOKING_HISTORY } from "../../../../../../../redux/slices/TouristGuideReducer/guideSlice";
import moment from "moment";

const BookinghistoryTable = ({ values, currentPage, setCurrentPage }) => {
  const dispatch = useDispatch();
  const User = useSelector((state) => state.auth.SignupUser);
  const [bookingList, setbookingList] = useState();
  const [errmsg, setErrorMsg] = useState();
  const [isLoading, setLoading] = useState(false);
  const pageNumberLimit = 4;
  const [maxPageLimit, setMaxPageLimit] = useState(4);
  const [minPageLimit, setMinPageLimit] = useState(0);
  // const [isConfimed, setIsConfirmed] = useState([0,1]);
  const nPages = bookingList?.count ? Math.ceil(bookingList?.count / 10) : [];

  useEffect(() => {
    getBookinglist();
  }, [currentPage, values]);

  const getBookinglist = () => {
    let bookingstatus = "";
    let isConfimed = [0, 1];
    if (values === "allBooking") {
      bookingstatus = ["1,2,3,4,5,6,7,8,9"];
      isConfimed = [0, 1];
    } else if (values === "ongoingBooking") {
      bookingstatus = ["2,6,8,4"];
      isConfimed = [0];
    } else if (values === "completedBooking") {
      bookingstatus = ["4"];
      isConfimed = [1];
    } else if (values === "cancelledBooking") {
      bookingstatus = ["1,3,5,7,9"];
      isConfimed = [0];
    }
    setLoading(true);
    getApi(
      `${guide_received_bookings}?booking_status=[${bookingstatus}]&completed_booking=[${isConfimed}]&page_no=${currentPage}`,
      {},
      User.access_token
    ).then((res) => {
      setErrorMsg(res.data.message);
      setLoading(false);
      setbookingList(res.data.data);
      dispatch(GUIDE_BOOKING_HISTORY(res.data.data.rows));
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div
            className={
              isLoading === true ? "overflow-table loader" : "overflow-table"
            }
            style={
              bookingList?.count > 10
                ? { height: "calc(100vh - 290px)" }
                : { height: "calc(100vh - 218px)" }
            }
          >
            {isLoading === true && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <Oval
                  height={80}
                  width={80}
                  color="#1f74ca"
                  wrapperStyle={{}}
                  wrapperclassName=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#1f74ca8f"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            )}
            {isLoading === false && (
              <>
                {bookingList?.rows ? (
                  <table className="table custom-design" data-aos="fade-up">
                    <thead>
                      <tr className="border-bottom w-100">
                        <th className="text-secondary f-700">User Name</th>
                        {/* <th className="text-secondary f-700">Destination</th> */}
                        <th className="text-secondary f-700">Country</th>
                        <th className="text-secondary f-700">State</th>
                        <th className="text-secondary f-700">City</th>
                        {/* <th className="text-secondary f-700">Time Duration</th> */}
                        <th className="text-secondary f-700">Start Date</th>
                        <th className="text-secondary f-700">End Date</th>
                        <th className="text-secondary f-700">Status</th>
                        <th className="text-secondary f-700">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookingList?.rows?.map((list, i) => (
                        <tr key={i} className="border-bottom w-100">
                          <td>
                            <h6 className="text-secondary mb-1 f-600">
                              {list.last_name
                                ? `${list.first_name} ${list.last_name}`
                                : list.first_name}
                            </h6>
                            {/* <p className="text-grey mb-0 f-500">{list.email}</p> */}
                          </td>
                          {/* <td>
                            <div className="d-flex align-items-center">
                              <span className="location_iconbox me-2 bg-primary-fade d-flex align-items-center justify-content-center">
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.0004 11.1917C11.4363 11.1917 12.6004 10.0276 12.6004 8.5917C12.6004 7.15576 11.4363 5.9917 10.0004 5.9917C8.56445 5.9917 7.40039 7.15576 7.40039 8.5917C7.40039 10.0276 8.56445 11.1917 10.0004 11.1917Z"
                                    stroke="#1F74CA"
                                    strokeWidth="1.5"
                                  />
                                  <path
                                    d="M3.01675 7.07484C4.65842 -0.141827 15.3501 -0.133494 16.9834 7.08317C17.9417 11.3165 15.3084 14.8998 13.0001 17.1165C11.3251 18.7332 8.67508 18.7332 6.99175 17.1165C4.69175 14.8998 2.05842 11.3082 3.01675 7.07484Z"
                                    stroke="#1F74CA"
                                    strokeWidth="1.5"
                                  />
                                </svg>
                              </span>
                              <p className="text-secondary mb-0 f-600">
                                {list.destination}
                              </p>
                            </div>
                          </td> */}
                          <td>
                            <p className="text-secondary mb-0 f-600">
                              {list.country || "---"}
                            </p>
                          </td>
                          <td>
                            <p className="text-secondary mb-0 f-600">
                              {list.state || "---"}
                            </p>
                          </td>
                          <td>
                            <p className="text-secondary mb-0 f-600">
                              {list.city || "---"}
                            </p>
                          </td>
                          {/* <td>
                            <p className="text-secondary mb-0 f-600">
                              {list.booking_slot_start} - {list.booking_slot_end}
                            </p>
                          </td> */}
                          <td>
                            <p className="text-secondary mb-0 f-600">
                              {/* {list.booking_start} */}
                              {moment(list.booking_start, "MM-DD-YYYY").format(
                                "MM-DD-YYYY"
                              )}
                            </p>
                          </td>
                          <td>
                            <p className="text-secondary mb-0 f-600">
                              {/* {list.booking_end} */}
                              {moment(list.booking_end, "MM-DD-YYYY").format(
                                "MM-DD-YYYY"
                              )}
                            </p>
                          </td>
                          <td className="d-flex align-items-center justify-content-between">
                            {[1, 3, 5, 7, 9].includes(list.status) && (
                              <h6 className="text-red v-middle f-500 d-flex align-items-center mb-0 me-0 l-50">
                                {" "}
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5Z"
                                    stroke="#F04438"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                  <path
                                    d="M6.87695 11.1225L11.122 6.8775"
                                    stroke="#F04438"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                  <path
                                    d="M11.122 11.1225L6.87695 6.8775"
                                    stroke="#F04438"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>
                                <span className="ms-2 text-danger">
                                  Cancelled
                                </span>
                              </h6>
                            )}
                            {[2, 6, 8, 4].includes(list.status) &&
                              list.is_completed === 0 && (
                                <h6 className="text-green v-middle f-500 d-flex align-items-center mb-0 l-50 me-0">
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5Z"
                                      stroke="#12B76A"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M5.8125 9L7.935 11.1225L12.1875 6.8775"
                                      stroke="#12B76A"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                  <span className="ms-2">onGoing</span>
                                </h6>
                              )}
                            {[4].includes(list.status) &&
                              list.is_completed === 1 && (
                                <h6 className="text-green v-middle f-500 d-flex align-items-center mb-0 l-50 me-0">
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5Z"
                                      stroke="#12B76A"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M5.8125 9L7.935 11.1225L12.1875 6.8775"
                                      stroke="#12B76A"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                  <span className="ms-2">Completed</span>
                                </h6>
                              )}
                          </td>
                          <td>
                            <Link
                              to={`booking-detail/${list.id}`}
                              className="icon_box d-flex align-items-center justify-content-center me-2"
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.3866 7.99995C10.3866 9.31995 9.31995 10.3866 7.99995 10.3866C6.67995 10.3866 5.61328 9.31995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C9.31995 5.61328 10.3866 6.67995 10.3866 7.99995Z"
                                  stroke="#475467"
                                  strokeWidth="1.2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1266 14.0732 9.72665C14.6732 8.78665 14.6732 7.20665 14.0732 6.26665C12.5466 3.86665 10.3532 2.47998 7.9999 2.47998C5.64656 2.47998 3.45323 3.86665 1.92656 6.26665C1.32656 7.20665 1.32656 8.78665 1.92656 9.72665C3.45323 12.1266 5.64656 13.5133 7.9999 13.5133Z"
                                  stroke="#475467"
                                  strokeWidth="1.2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Error errmsg={errmsg} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {isLoading === false && (
        <>
          {bookingList?.count > 10 && (
            <div className="row">
              <div className="col-md-12">
                <div className="pagination-wrapperbox d-flex align-items-center justify-content-between">
                  <div className="data_count p-20 text-grey small-text f-400">
                    <span>
                      Showing
                      <span className="mx-1 f-500">{10 * currentPage - 9}</span>
                      to
                      <span className="mx-1 f-500">
                        {10 * currentPage - (10 - bookingList?.rows?.length)}
                      </span>
                      of
                      <span className="mx-1 f-500">{bookingList.count}</span>
                      entries
                    </span>
                  </div>
                  <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageNumberLimit={pageNumberLimit}
                    maxPageLimit={maxPageLimit}
                    minPageLimit={minPageLimit}
                    setMinPageLimit={setMinPageLimit}
                    setMaxPageLimit={setMaxPageLimit}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BookinghistoryTable;
