import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Scroll from "../components/main/ErrorPage";
import Booking from "../dashboard/sections/home/components/pages/recievedBookingPage/Booking";
import BookingHistory from "../dashboard/sections/home/components/pages/bookingHistoryPage/BookingHistory";
import BookingDetail from "../dashboard/sections/home/components/pages/bookingHistoryPage/bookingDetail/BookingDetail";
import Transaction from "../dashboard/sections/home/components/pages/transactionPage/Transaction";
import Gallery from "../dashboard/sections/home/components/pages/galleryPage/Gallery";
import Notification from "../dashboard/sections/home/components/pages/notificationPage/Notification";
import Message from "../dashboard/sections/home/components/pages/messagePage/Message";
import Setting from "../dashboard/sections/home/components/pages/settingPage/Setting";
import Userdashboardpage from "../dashboard/sections/home/components/pages/dashboardPage/userDashboard/Userdashboardpage";
import GuidedashboardPage from "../dashboard/sections/home/components/pages/dashboardPage/guideDashboard/GuidedashboardPage";
import FindGuide from "../dashboard/sections/home/components/tarvelerPages/findGuide/FindeGuide";
import BookingDetails from "../dashboard/sections/home/components/tarvelerPages/findGuide/sections/tableContent/bookingDetails/BookingDetails";
import MyBooking from "../dashboard/sections/home/components/tarvelerPages/myBooking/MyBooking";
import TravelerTransaction from "../dashboard/sections/home/components/tarvelerPages/transactionPages/TravelerTransaction";
import Itinerary from "../dashboard/sections/home/components/tarvelerPages/itinerary/Itinerary";
import ItineraryDetails from "../dashboard/sections/home/components/tarvelerPages/itinerary/itineraryDetails/ItineraryDetails";
import DetailedMsg from "../dashboard/sections/home/components/pages/messagePage/sections/DetailedMsg";
// import { useDispatch, useSelector } from "react-redux";
// import { getApi } from "../config/apiFunctions";
// import {
//   guide_notifications,
//   traveler_notifications,
// } from "../config/endpoints";
// import {
//   BOOKING_HISTORY,
//   RECIEVED_BOOKINGS,
//   TRAVELER_NOTIFICATION,
// } from "../redux/slices/TouristGuideReducer/guideSlice";
// import { onMessage } from "firebase/messaging";
// import { messaging } from "../App";
// import { useToast } from "@chakra-ui/react";
// import {
//   BOOKING,
//   TRAVELLR_BOOKING_NOTIFICATION,
// } from "../redux/slices/TravellerReducer/travellerSlice";
// import PreviousItineraries from "../dashboard/sections/home/components/tarvelerPages/itinerary/previousItineraries/PreviousItineraries";
import Reviews from "../dashboard/sections/home/components/pages/guideReviews/Reviews";

const DashboardRoutes = ({ User, isLoggedIn, navigate }) => {
  const [notificatonUpdate, setNotificationUpdate] = useState(false);
  const [onLoadComponent, setOnLoadComponent] = useState(false);

  return (
    <Routes>
      <Route exact path="*" element={<Scroll />} />

      {User.role_name === "GUIDE" && (
        //////Guide dashboard  Routes..................
        <Route exact path="/dashboard/">
          <Route exact index={true} element={<GuidedashboardPage />} />
          <Route path="booking" element={<Booking />} />
          <Route path="booking-history" element={<BookingHistory />} />
          <Route
            path="booking-history/booking-detail/:id"
            element={<BookingDetail />}
          />
          <Route path="transactions" element={<Transaction />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="notification" element={<Notification />} />
          <Route path="messages" element={<Message />} />
          <Route path="settings" element={<Setting />} />
          <Route exact path="*" element={<Scroll />} />
        </Route>
      )}
      {User.role_name === "TRAVELLER" && (
        /////Travelere dashboard  Routes..................
        <Route exact path="/dashboard/">
          <Route exact index={true} element={<Userdashboardpage />} />
          <Route
            path="findeguide"
            element={
              <FindGuide
                onLoadComponent={onLoadComponent}
                setOnLoadComponent={setOnLoadComponent}
              />
            }
          />
          {/* <Route
            path="itinerary/previousitineraries"
            element={<PreviousItineraries />}
          /> */}
          <Route path="findeguide/:id" element={<BookingDetails />} />
          <Route
            path="my-bookings"
            element={
              <MyBooking
                onLoadComponent={onLoadComponent}
                setOnLoadComponent={setOnLoadComponent}
              />
            }
          />
          <Route
            path="transactions"
            element={
              <TravelerTransaction
                onLoadComponent={onLoadComponent}
                setOnLoadComponent={setOnLoadComponent}
              />
            }
          />
          <Route
            path="itinerary"
            element={
              <Itinerary
                onLoadComponent={onLoadComponent}
                setOnLoadComponent={setOnLoadComponent}
              />
            }
          />
          <Route path="itinerary-details/:id" element={<ItineraryDetails />} />
          <Route path="messages" element={<Message />} />
          <Route path="messages/:id" element={<DetailedMsg />} />
          <Route
            path="notification"
            element={
              <Notification
                notificatonUpdate={notificatonUpdate}
                setNotificationUpdate={setNotificationUpdate}
              />
            }
          />
          <Route
            path="messages"
            element={
              <Message
                onLoadComponent={onLoadComponent}
                setOnLoadComponent={setOnLoadComponent}
              />
            }
          />
          <Route
            path="settings"
            element={
              <Setting
                onLoadComponent={onLoadComponent}
                setOnLoadComponent={setOnLoadComponent}
              />
            }
          />
          <Route exact path="*" element={<Scroll />} />
        </Route>
      )}
    </Routes>
  );
};

export default DashboardRoutes;
