import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useToast } from "@chakra-ui/react";
import { putApi } from "../../../../../../../config/apiFunctions";
import { guide_update_gallery } from "../../../../../../../config/endpoints";
import { ThreeDots } from "react-loader-spinner";

const EditGalleryModal = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [dt, setdt] = useState([]);
  const toast = useToast();
  const [btndisable, setBtnDisable] = useState(false);
  const [errUploadMsg, setErrUploadMsg] = useState(false);
  const Imgdata = useSelector((state) => state.touristGuide.guide_gallery);
  const [image, setImage] = useState(Imgdata);
  const [_ID, setID] = useState(0);
  const [galleryImgs, setGalleryImgs] = useState();
  const [uploadedImages, setUploadedImages] = useState([]);
  const access_token = useSelector(
    (state) => state.auth.SignupUser.access_token
  );
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(Schema),
  });

  useEffect(() => {
    setdt([]);
    setErrUploadMsg(false);
    if (!Imgdata) {
    } else {
      setGalleryImgs(Imgdata[props.keyName]?.file_urls);
    }
    if (props.show === true) {
      setValue("title", Imgdata[props.keyName]?.destination_title);
      setID(Imgdata[props.keyName].file_urls[0].id);
    }
  }, [props.keyName, props.show]);

  const handleRemove = (i, index, isNewUploadedImg = -1) => {
    //remove image from upload image state
    if (isNewUploadedImg) {
      const _UPLOAD_IMG_INDEX =
        index - (galleryImgs.length - uploadedImages.length);
      setUploadedImages(
        uploadedImages.filter((item, _index) => _index !== _UPLOAD_IMG_INDEX)
      );
    }
    ///remove image fromexisting state
    const newarr = galleryImgs?.filter(
      (item) => item.gallery_img_url !== i.gallery_img_url
    );
    setdt([...dt, i.id]);
    setGalleryImgs(galleryImgs.filter((item, _index) => _index !== index));
  };

  const changeMultipleFiles = (e) => {
    if (galleryImgs) {
      setErrUploadMsg(false);
    }
    if (e.target.files) {
      const imageArray = Array.from(e.target.files).map((file, index) => {
        const arr = URL.createObjectURL(file);
        setGalleryImgs((prevState) => [
          ...prevState,
          {
            gallery_img_url: arr,
            id: _ID,
          },
        ]);
        setUploadedImages((prev) => [...prev, e.target.files[index]]);
      });
    }
  };
  const onSubmit = (e) => {
    if (galleryImgs.length === 0) {
      setErrUploadMsg(true);
    } else {
      setErrUploadMsg(false);
      setLoading(true);
      setBtnDisable(true);
      const formData = new FormData();
      for (let i = 0; i < uploadedImages.length; i++) {
        formData.append("gallery_img", uploadedImages[i]);
      }
      putApi(
        `${guide_update_gallery}?title_id=${_ID}&title=${e.title}&delete_images_id=[${dt}]`,
        formData,
        access_token
      )
        .then((res) => {
          if (res.status === 200) {
            toast({
              title: res.data.message,
              status: "success",
              duration: 30000,
              isClosable: true,
              position: "top-right",
              onCloseComplete: () => setBtnDisable(false),
            });
            setLoading(false);
            setUploadedImages([]);
            reset();
            props.onHide();
            setID(0);
          }
          setUploadedImages([]);
        })
        .catch((err) => {
          setLoading(false);
          toast({
            title: err.response.data.message,
            status: "error",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
        });
    }
  };
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className=" modal-dialog-scrollablse w-300">
        <div className="modal-content p-30">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal_header position-relative">
              <h3 className="text-secondary f-700 mb-2">Edit Photos</h3>
              <button
                type="button"
                className="btn-close"
                onClick={props.onHide}
              ></button>
            </div>
            <div className="modal-body p-0 mt-3">
              <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                <label
                  className="small-text text-secondary mb-1 f-600"
                  htmlFor="Title"
                >
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  placeholder="Add title"
                  defaultValue={
                    !Imgdata ? "" : Imgdata[props.keyName]?.destination_title
                  }
                  {...register("title")}
                  className={`form-control ${errors.title ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">{errors.title?.message}</div>
              </div>
              <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                <label
                  className="small-text text-secondary mb-1 f-600"
                  htmlFor="images"
                >
                  Upload Photos
                </label>
                <div className="uploadedGallery w-100">
                  <div
                    className="fileUpload w-100 d-flex align-items-center justify-content-center flex-column"
                    id=""
                  >
                    <div className="gallery_uploadimg_inner show_uploaded_img">
                      <div>
                        <label
                          className="w-100 text-center d-flex flex-column align-items-center justify-content-center p-30"
                          htmlFor="galleryImageUpload"
                        >
                          <svg
                            className="mb-2"
                            width="26"
                            height="21"
                            viewBox="0 0 26 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.4878 6.95801C20.3103 2.03288 15.5535 -0.957466 10.8629 0.278856C7.19739 1.24504 4.53579 4.57156 4.26156 8.52935C1.65887 8.98003 -0.103108 11.5608 0.326105 14.2936C0.707655 16.7232 2.71257 18.5024 5.05759 18.4926H9.03777V16.821H5.05759C3.29905 16.821 1.87345 15.3241 1.87345 13.4776C1.87345 11.6311 3.29905 10.1342 5.05759 10.1342C5.49725 10.1342 5.85363 9.75998 5.85363 9.29833C5.84965 5.1437 9.05404 1.77231 13.0108 1.76818C16.4359 1.76457 19.3844 4.30712 20.0469 7.83559C20.1124 8.1879 20.3847 8.45705 20.7236 8.50427C22.8998 8.82968 24.4128 10.9459 24.1029 13.2309C23.8246 15.2828 22.1561 16.8106 20.1823 16.821H16.9981V18.4926H20.1823C23.2597 18.4829 25.747 15.8554 25.7376 12.624C25.7298 9.93415 23.9764 7.59633 21.4878 6.95801Z"
                              fill="#98A2B3"
                            />
                            <path
                              d="M12.4537 10.3764L9.26953 13.7198L10.3919 14.8983L12.2228 12.9842V21H13.8149V12.9842L15.6378 14.8983L16.7602 13.7198L13.5761 10.3764C13.2656 10.0523 12.7642 10.0523 12.4537 10.3764Z"
                              fill="#98A2B3"
                            />
                          </svg>
                          <span className="small-text f-500 lh-24">
                            <a className="text-primary">Click to upload</a> or
                            drag and drop JPG or PNG (maximum size 20MB)
                          </span>
                          {errUploadMsg === true && (
                            <p className="text-danger">
                              Please choose atleast one file to upload!
                            </p>
                          )}
                          <input
                            name="gallery_img"
                            id="galleryImageUpload"
                            type="file"
                            accept="image/*"
                            multiple
                            className="d-none"
                            {...register("gallery_img", {
                              onChange: changeMultipleFiles,
                            })}
                          />
                        </label>
                      </div>
                      <div className="edit_gallery_list">
                        {galleryImgs?.length !== 0 ? (
                          <div className="d-flex flex-wrap align-items-center justify-content-start mt-3">
                            {galleryImgs?.map((media, ind) => (
                              <div
                                key={ind}
                                className="gallery_img me-3 position-relative"
                              >
                                <span
                                  className="remove_imgbtn"
                                  onClick={() =>
                                    handleRemove(
                                      media,
                                      ind,
                                      media.gallery_img_url.split(":")[0] ===
                                        "blob"
                                        ? 1
                                        : 0
                                    )
                                  }
                                >
                                  <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M3.99935 7.33341C5.83268 7.33341 7.33268 5.83341 7.33268 4.00008C7.33268 2.16675 5.83268 0.666748 3.99935 0.666748C2.16602 0.666748 0.666016 2.16675 0.666016 4.00008C0.666016 5.83341 2.16602 7.33341 3.99935 7.33341Z"
                                      stroke="white"
                                      strokeWidth="0.7"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M3.05664 4.94331L4.94331 3.05664"
                                      stroke="white"
                                      strokeWidth="0.7"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M4.94331 4.94331L3.05664 3.05664"
                                      stroke="white"
                                      strokeWidth="0.7"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                                <img
                                  src={media.gallery_img_url}
                                  alt="gallery-img"
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 p-0">
              <button
                disabled={btndisable}
                className="btn-main btn-primary w-100 mw-100"
              >
                {isLoading ? (
                  <ThreeDots
                    height="25"
                    width="80"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  " Upload Photos"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default EditGalleryModal;
const Schema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .min(2, "Too Short!")
    .max(56, "Maximum character length is 56")
    .matches(/^[A-Z]/, "First letter should be uppercase.")
    .matches(/^[a-zA-Z0-9_.-\s]*$/, "Please enter letters and numbers only"),
  gallery_img: Yup.mixed().test("name", "Please upload a File", (value) => {
    return value && value.name !== "";
  }),
});
