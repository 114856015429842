import React, { useEffect } from "react";
// import blog_img from "../../../../../../assets/images/blog-image.png";
import { getApi } from "../../../../../../config/apiFunctions";
import { single_blog } from "../../../../../../config/endpoints";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SINGLE_BLOG } from "../../../../../../redux/slices/TouristGuideReducer/guideSlice";

const Banner = () => {
  const params = useParams();
  const id = params.slug;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.touristGuide.singleBlog);
  const signgleBlog = () => {
    getApi(single_blog + "/" + id).then((res) => {
      dispatch(SINGLE_BLOG(res.data.data));
    });
  };

  useEffect(() => {
    signgleBlog();
  }, []);

  return (
    <section className="hero">
      <div className="container spacer-y">
        <div className="blog-hero">
          <img src={data?.banner_image_url} className="w-100" alt="" />
        </div>
      </div>
    </section>
  );
};

export default Banner;
