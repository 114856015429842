import { useSelector } from "react-redux";
import { AiOutlineStar } from "react-icons/ai";
import { FaStarHalfAlt, FaStar } from "react-icons/fa";

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export const IsLogin = () => {
  const data = useSelector((state) => state.auth.SignupUser);
  const access_token = data.access_token;
  if (access_token) {
    return true;
  }
  return false;
};

export const Star = ({ stars }) => {
  const rating = Array.from({ length: 5 }, (star, index) => {
    let numbers = index + 0.5;
    return (
      <li key={index}>
        {stars >= index + 1 ? (
          <FaStar className="icon" />
        ) : stars >= numbers ? (
          <FaStarHalfAlt className="icon" />
        ) : (
          <AiOutlineStar className="stars" />
        )}
      </li>
    );
  });
  return (
    <div className="list-unstyled m-0 rating-list ">
      {rating}
      {stars}
    </div>
  );
};


export const phoneRegExp =/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
export const emailRegExp =/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

