import React from "react";
import TransactionTable from "./sections/TransactionTable";

const Transaction = () => {
  return (
    <div className="table_fixed booking_innerbox" >
      <TransactionTable />
    </div>
  );
};

export default Transaction;
