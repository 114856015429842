import { useEffect, useState } from "react";
import { getApi } from "../../../../../../../config/apiFunctions";
import { all_rating } from "../../../../../../../config/endpoints";
import { useSelector } from "react-redux";
import { Star } from "../../../../../../../config/common/common";
import { Oval } from "react-loader-spinner";
import Pagination from "../../../../../../../config/pagination/Pagination";
import defaultPicture from "../../../../../../../../src/assets/images/profile-placeholder1.jpg";

const ReviewsList = () => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const pageNumberLimit = 4;
  const [maxPageLimit, setMaxPageLimit] = useState(4);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const User = useSelector((state) => state.auth.SignupUser);
  const nPages = data?.count ? Math.ceil(data?.count / 10) : [];

  const reviewsData = () => {
    setLoading(true);
    getApi(`${all_rating}?page_no=${currentPage}`, {}, User.access_token)
      .then((res) => {
        setData(res.data?.data);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reviewsData();
  }, [currentPage]);

  return (
    <>
      {isLoading === true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            margin: "auto",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="guide_review_inner mt-3">
          <div className="p-20">
            <div className="row">
              {data?.rows?.map((list, i) => {
                return (
                  <div key={i} className="review_box border-all p-20 my-3">
                    <div className="image_wrapper me-3">
                      <img
                        alt="Guide"
                        src={
                          list?.ratingGivenUserDetails?.user_detail
                            ?.profile_picture
                            ? list?.ratingGivenUserDetails?.user_detail
                                ?.profile_picture
                            : defaultPicture
                        }
                      />
                    </div>
                    <Star stars={list?.ratings?.toString()} />
                    <h5 className=" review_title font-2 mb-3 text-grey-scale f-700">
                      {list.user_name}
                    </h5>
                    <p className="f-400 text-grey-600">
                      <pre
                        style={{
                          whiteSpace: "break-spaces",
                          font: "var(--primary-font)",
                          fontSize: " 1rem",
                        }}
                        className="f-400 text-grey-600"
                      >
                        {list.review_message}
                      </pre>
                    </p>
                    <div className="row pt-3 border-top">
                      <div className="col-md-12 d-flex align-items-center">
                        <h6 className="review-author text-primary f-400 mb-0">
                          {list?.user_name}
                        </h6>
                        <div className="inline_icon d-flex align-items-center">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 0C4.48622 0 0 4.48622 0 10C0 15.5138 4.48622 20 10 20C15.5138 20 20 15.5138 20 10C20 4.48622 15.5138 0 10 0ZM15.589 7.36842L9.198 13.7093C8.82206 14.0852 8.22055 14.1103 7.81955 13.7343L4.43609 10.6516C4.03509 10.2757 4.01003 9.64912 4.3609 9.24812C4.73684 8.84712 5.36341 8.82206 5.76441 9.198L8.44612 11.6541L14.1604 5.93985C14.5614 5.53885 15.188 5.53885 15.589 5.93985C15.99 6.34085 15.99 6.96742 15.589 7.36842Z"
                              fill="#344054"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {data?.rows?.length === 0 && (
              <h2 className="review_link text-primary f-700 d-block text-center">
                Hasn't recieved any review yet
              </h2>
            )}
          </div>
        </div>
      )}
      {isLoading === false && (
        <>
          {data?.count > 10 && (
            <div className="row">
              <div className="col-md-12">
                <div className="pagination-wrapperbox d-flex align-items-center justify-content-between">
                  <div className="data_count p-20 text-grey small-text f-400">
                    <span>
                      Showing
                      <span className="mx-1 f-500">{10 * currentPage - 9}</span>
                      to
                      <span className="mx-1 f-500">
                        {10 * currentPage - (10 - data?.rows?.length)}
                      </span>
                      of
                      <span className="mx-1 f-500">{data?.count}</span>
                      entries
                    </span>
                  </div>
                  <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageNumberLimit={pageNumberLimit}
                    maxPageLimit={maxPageLimit}
                    minPageLimit={minPageLimit}
                    setMinPageLimit={setMinPageLimit}
                    setMaxPageLimit={setMaxPageLimit}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ReviewsList;
