import React, { useState } from "react";
import profile_icon from "../../../../../assets/images/profile-icon.svg";
import calendar from "../../../../../assets/images/calendar.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import RecentPost from "../../recentPosts/RecentPost";
import Pagination from "../../../../../config/pagination/Pagination";
import parse from "html-react-parser";
import moment from "moment";


const LatestBlog = () => {
  const pageNumberLimit = 4;
  const [maxPageLimit, setMaxPageLimit] = useState(4);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const data = useSelector((state) => state.touristGuide.blog?.rows);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(3);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data
    ? data.slice(indexOfFirstRecord, indexOfLastRecord)
    : [];
  const nPages = data ? Math.ceil(data.length / recordsPerPage) : [];
  return (
    <section className="latest-blogs">
      <div className="container spacer-y">
        <div className="row justify-content-center text-center mb-40">
          <div className="col-lg-7">
            <h2 className="primary__color p-0 mb-3" data-aos="fade-up">
              Latest Blog
            </h2>
            <p className="m-0" data-aos="fade-up" data-aos-delay="100">
            Read our travel blogs, developed to give you a complete picture of elegant destinations you may wish to visit.
            </p>
          </div>
        </div>
        <div className="row g-3 gy-4 gy-md-0 gx-lg-4">
          <div className="col-lg-8 col-md-12">
            {currentRecords?.map((list, i) => (
              <div key={i} className="blog-wrapper" data-aos="fade-up">
                <div className="blog-image-wrap">
                  <img src={list.banner_image_url} className="w-100" alt="" />
                </div>
                <div className="blog-content-wrap">
                  <h6 className="mb-3">{list.title}</h6>
                  <div className="profile-date d-flex align-items-center gap-3 mb-3">
                    <p className="d-flex align-items-center gap-2 m-0">
                      <img src={calendar} alt="" />
                      <span>{moment.utc(list?.published_at).format('DD-MMM-YYYY')}</span>
                    </p>
                    <p className="d-flex align-items-center gap-2 m-0">
                      <img src={profile_icon} alt="" />
                      <span>{list.user.last_name? `${list.user.name} ${list.user.last_name}`:list.user.name}</span>
                    </p>
                  </div>
                  <p> {parse(`${list.content.slice(0, 400)}`)}</p>
                 
                  <Link
                    to={`/blog-detail/${list.slug}`}
                    className="button-primary"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <RecentPost />
        </div>
       
        {currentRecords.length !== 0 ? (
          <div className="mt-5">
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageNumberLimit={pageNumberLimit}
              maxPageLimit={maxPageLimit}
              minPageLimit={minPageLimit}
              setMinPageLimit={setMinPageLimit}
              setMaxPageLimit={setMaxPageLimit}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </section>
  );
};

export default LatestBlog;
