import React from "react";
import ContactUs from "../homePage/section/contactUs/ContactUs";
// import TouristGuideList from './sections/touristguidelists/TouristGuideList';
import Search from "./sections/search/Search";
import "./index.css";

const FindTouristGuide = () => {
  return (
    <>
      <Search />
      {/* <TouristGuideList /> */}
      <ContactUs />
    </>
  );
};

export default FindTouristGuide;
