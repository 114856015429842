import React, { useEffect } from "react";
import MybookingTable from "./myBookingTable/MybookingTable";
import { useDispatch } from "react-redux";
import { BOOKING } from "../../../../../../redux/slices/TravellerReducer/travellerSlice";

const MyBooking = () => {

  const dispatch = useDispatch()

  useEffect(()=>{
   dispatch(BOOKING(0))
  },[])

  return (
    <div className="table_fixed booking_innerbox">
      <MybookingTable />
    </div>
  );
};

export default MyBooking;
