import React, { useState } from "react";
import GalleryHead from "./sections/GalleryHead";
import GalleryDisplay from "./sections/GalleryDisplay";

const Gallery = () => {
  const [destinations, setdestinations] = useState(false);
  return (
    <div className="table_fixed gallery_table booking_innerbox">
      <GalleryHead setdestinations={() => setdestinations(true)} />
      <GalleryDisplay
        setdestinations={() => setdestinations(false)}
        destinations={destinations}
      />
    </div>
  );
};

export default Gallery;
