import React from "react";
import "./DiscoverTravelGuideline.css";
// import guideline_img from "../../../../../assets/images/gudline_img.jpg";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useSelector } from "react-redux";

const DiscoverTravelGuideline = ({ dataList }) => {
  const User = useSelector((state) => state.auth.SignupUser);
  const waiting_status = useSelector((state) => state.auth.waiting_status);
  const token = User?.access_token;
  return (
    <section className="guideline">
      <div className="container spacer-y pt-0">
        <div
          className="guideline_outer primary__bg"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="guideline_content">
            {/* <span className="text-white">Take a Guider</span> */}
            <h2 className="text-white m-0">
              {dataList && dataList[0]?.value?.title}
            </h2>
            <div className="m-0 text-white">
              {parse(`${dataList && dataList[0]?.value?.description}`)}
            </div>
            <div className="about_btn">
              {waiting_status === false && token && (
                <Link to="/find-tourist-guide" className="button-primary">
                  Start Now
                </Link>
              )}
              {!token && (
                <Link to="/traveller-waiting-list" className="button-primary">
                  Join Our Panel
                </Link>
              )}
            </div>
          </div>
          <div className="guideline_img">
            <img
              src={
                dataList && dataList[0]?.value?.banner_image?.upload_image_url
              }
              alt="gudline"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default DiscoverTravelGuideline;
