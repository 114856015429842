import React, { useEffect, useState } from "react";
import WithdrawModal from "./WithdrawModal";
import ViewDetails from "./modals/ViewDetails";
import {
  guide_transaction_list,
  guide_transaction_view,
} from "../../../../../../../config/endpoints";
import { getApi } from "../../../../../../../config/apiFunctions";
import { useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import Pagination from "../../../../../../../config/pagination/Pagination";
import nodata from "../../../../../../../assets/images/no-result.png";
import TransactionHeader from "./TransactionHeader";
import moment from "moment";

const TransactionTable = () => {
  const User = useSelector((state) => state.auth.SignupUser);
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState(null);
  const [viewData, setViewdata] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(null);
  //pagination...........
  const pageNumberLimit = 4;
  const [maxPageLimit, setMaxPageLimit] = useState(4);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [countPage, setCountPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const nPages = data?.count ? Math.ceil(data?.count / 10) : [];

  const getTransactions = () => {
    setLoading(true);
    getApi(
      `${guide_transaction_list}?page_no=${currentPage}`,
      {},
      User.access_token
    )
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(true);
      });
  };
  useEffect(() => {
    getTransactions();
  }, [currentPage, modalShow]);

  const handleView = (booking_id) => {
    setViewdata(null);
    setModalShow(true);
    getApi(
      `${guide_transaction_view}?booking_id=${booking_id}`,
      {},
      User.access_token
    ).then((res) => {
      setViewdata(res.data.data);
    });
  };
  return (
    <>
      <TransactionHeader totalPayment={data?.totalPayment} />
      <div className="row">
        <div className="col-md-12">
          <div
            className="overflow-table"
            data-aos="fade-up"
            style={
              data?.count > 10
                ? { height: "calc(100vh - 270px)" }
                : { height: "calc(100vh - 220px)" }
            }
            // style={{ height: "calc(100vh - 220px)" }}
          >
            {isLoading === true && (
              <div
                className="loader"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <Oval
                  height={80}
                  width={80}
                  color="#1f74ca"
                  wrapperStyle={{}}
                  wrapperclassName=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#1f74ca8f"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            )}
            {isLoading === false && (
              <>
                {data?.rows.length > 0 ? (
                  <table className="table custom-design">
                    <thead>
                      <tr className="border-bottom w-100">
                        <th className="text-secondary f-700">Name</th>
                        <th className="text-secondary f-700">Transaction Id</th>
                        <th className="text-secondary f-700">Date</th>
                        <th className="text-secondary f-700">Amount</th>
                        <th className="text-secondary f-700">Booking Id</th>
                        <th className="text-secondary f-700">Action</th>
                      </tr>
                    </thead>
                    {data?.rows?.map((list, i) => {
                      return (
                        <tbody key={i}>
                          <tr className="border-bottom w-100">
                            <td>
                              <h6 className="text-secondary mb-0 lh-24 f-600">
                                {list?.bookingDetails.travellerDetails.last_name
                                  ? `${list?.bookingDetails.travellerDetails.name} ${list?.bookingDetails.travellerDetails.last_name}`
                                  : list?.bookingDetails.travellerDetails.name}
                              </h6>
                              {/* <div className="d-flex align-items-center">
                      <p className="text-grey mb-0 f-600">{list.bookingDetails.travellerDetails.email}</p>
                    </div> */}
                            </td>
                            <td>
                              <p className="text-secondary mb-0 f-600">
                                {list?.transaction_id}
                              </p>
                            </td>
                            <td>
                              <p className="text-secondary mb-0 f-600">
                                {/* {new Date(list.createdAt).toLocaleDateString().replaceAll("/","-")} */}
                                {moment(list.createdAt).format("MM-DD-YYYY")}
                              </p>
                            </td>
                            <td>
                              <p className="text-secondary mb-0 f-600">
                                ${list?.amount}
                              </p>
                            </td>
                            <td>
                              {" "}
                              <p className="text-secondary mb-0 f-600">
                                {list?.booking_id}
                              </p>
                            </td>
                            <td className="">
                              <button
                                onClick={() => {
                                  setTransactionStatus(list?.payment_type);
                                  handleView(list?.booking_id);
                                }}
                                className="btn-main btn-primary f-500 w-100 mw-100"
                              >
                                View
                              </button>
                            </td>
                          </tr>
                          {/* <tr className="border-bottom w-100">
                  <td>
                    <h6 className="text-secondary mb-0 lh-24 f-600">
                      Addie Minstra
                    </h6>
                    <div className="d-flex align-items-center">
                      <p className="text-grey mb-0 f-600">demo@gmail.com</p>
                    </div>
                  </td>
                  <td>
                    <p className="text-secondary mb-0 f-600">4455445554</p>
                  </td>
                  <td>
                    <p className="text-secondary mb-0 f-600">23-08-2022</p>
                  </td>
                  <td>
                    <p className="text-secondary mb-0 f-600">$110</p>
                  </td>
                  <td> <p className="text-secondary mb-0 f-600">ABHK00558</p></td>

                  <td className="">
                    <button
                      onClick={() => setModalShow(true)}
                      className="btn-main btn-primary f-500 w-100 mw-100"
                    >
                      View
                    </button>
                  </td>
                </tr>
                <tr className="border-bottom w-100">
                  <td>
                    <h6 className="text-secondary mb-0 lh-24 f-600">
                      Addie Minstra
                    </h6>
                    <div className="d-flex align-items-center">
                      <p className="text-grey mb-0 f-600">demo@gmail.com</p>
                    </div>
                  </td>
                  <td>
                    <p className="text-secondary mb-0 f-600">4455445554</p>
                  </td>
                  <td>
                    <p className="text-secondary mb-0 f-600">23-08-2022</p>
                  </td>
                  <td>
                    <p className="text-secondary mb-0 f-600">$110</p>
                  </td>
                  <td> <p className="text-secondary mb-0 f-600">ABHK00558</p></td>

                  <td className="">
                    <button
                      onClick={() => setModalShow(true)}
                      className="btn-main btn-primary f-500 w-100 mw-100"
                    >
                      View
                    </button>
                  </td>
                </tr>
                <tr className="border-bottom w-100">
                  <td>
                    <h6 className="text-secondary mb-0 lh-24 f-600">
                      Addie Minstra
                    </h6>
                    <div className="d-flex align-items-center">
                      <p className="text-grey mb-0 f-600">demo@gmail.com</p>
                    </div>
                  </td>
                  <td>
                    <p className="text-secondary mb-0 f-600">4455445554</p>
                  </td>
                  <td>
                    <p className="text-secondary mb-0 f-600">23-08-2022</p>
                  </td>
                  <td>
                    <p className="text-secondary mb-0 f-600">$110</p>
                  </td>
                  <td> <p className="text-secondary mb-0 f-600">ABHK00558</p></td>

                  <td className="">
                    <button
                      onClick={() => setModalShow(true)}
                      className="btn-main btn-primary f-500 w-100 mw-100"
                    >
                      View
                    </button>
                  </td>
                </tr>
                <tr className="border-bottom w-100">
                  <td>
                    <h6 className="text-secondary mb-0 lh-24 f-600">
                      Addie Minstra
                    </h6>
                    <div className="d-flex align-items-center">
                      <p className="text-grey mb-0 f-600">demo@gmail.com</p>
                    </div>
                  </td>
                  <td>
                    <p className="text-secondary mb-0 f-600">4455445554</p>
                  </td>
                  <td>
                    <p className="text-secondary mb-0 f-600">23-08-2022</p>
                  </td>
                  <td>
                    <p className="text-secondary mb-0 f-600">$110</p>
                  </td>
                  <td> <p className="text-secondary mb-0 f-600">ABHK00558</p></td>

                  <td className="">
                    <button
                      onClick={() => setModalShow(true)}
                      className="btn-main btn-primary f-500 w-100 mw-100"
                    >
                      View
                    </button>
                  </td>
                </tr>
                <tr className="border-bottom w-100">
                  <td>
                    <h6 className="text-secondary mb-0 lh-24 f-600">
                      Addie Minstra
                    </h6>
                    <div className="d-flex align-items-center">
                      <p className="text-grey mb-0 f-600">demo@gmail.com</p>
                    </div>
                  </td>
                  <td>
                    <p className="text-secondary mb-0 f-600">4455445554</p>
                  </td>
                  <td>
                    <p className="text-secondary mb-0 f-600">23-08-2022</p>
                  </td>
                  <td>
                    <p className="text-secondary mb-0 f-600">$110</p>
                  </td>
                  <td> <p className="text-secondary mb-0 f-600">ABHK00558</p></td>

                  <td className="">
                    <button
                      onClick={() => setModalShow(true)}
                      className="btn-main btn-primary f-500 w-100 mw-100"
                    >
                      View
                    </button>
                  </td>
                </tr> */}
                        </tbody>
                      );
                    })}
                  </table>
                ) : (
                  <div className="">
                    <h1 className="text-primary text-center mt-5">
                      <img src={nodata} className="m-auto" />
                      <p className="small"> No Results Found</p>
                    </h1>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {isLoading === false && (
        <>
          {data?.count > 10 && (
            <div className="row">
              <div className="col-md-12">
                <div className="pagination-wrapperbox d-flex align-items-center justify-content-between">
                  <div className="data_count p-20 text-grey small-text f-400">
                    <span>
                      Showing
                      <span className="mx-1 f-500">{10 * currentPage - 9}</span>
                      to
                      <span className="mx-1 f-500">
                        {10 * currentPage - (10 - data?.rows?.length)}
                      </span>
                      of
                      <span className="mx-1 f-500">{data?.count}</span>
                      entries
                    </span>
                  </div>
                  <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageNumberLimit={pageNumberLimit}
                    maxPageLimit={maxPageLimit}
                    minPageLimit={minPageLimit}
                    setMinPageLimit={setMinPageLimit}
                    setMaxPageLimit={setMaxPageLimit}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <ViewDetails
        show={modalShow}
        onHide={() => setModalShow(false)}
        viewData={viewData}
        transactionData={transactionStatus}
      />
      {/* </div> */}
    </>
  );
};

export default TransactionTable;
