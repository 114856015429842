import React, { useEffect } from "react";
import location from "../../../../../assets/images/location.svg";
import Icon from '../../../../../assets/images/profile-placeholder1.jpg';
import { Link, createSearchParams, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Star } from "../../../../../config/common/common";
import { GUIDE_DETAILED_PAGE } from "../../../../../redux/slices/TouristGuideReducer/guideSlice";
import { getApi } from "../../../../../config/apiFunctions";
import { single_guide } from "../../../../../config/endpoints";

const Banner = () => {
  const params = useParams();
  const id= params.id;
  const datas = useSelector((state) => state.touristGuide.addReview);
  const User = useSelector((state) => state.auth.SignupUser);


  useEffect(()=>{
    detailedPage();
  }, [id, datas])
  const dispatch = useDispatch();
  const data= useSelector(state=>state.touristGuide.guide_detailed_page )
 
  const detailedPage = (params) => {
    getApi(single_guide + "/" + id).then((res) => {
      dispatch(GUIDE_DETAILED_PAGE(res.data.data));
    });
  };

  return (
    <section className="hero">
      <div className="container spacer-y">
        <div className="tourist-hero tourist-wrapper">
          <div className="tour-guide-image" data-aos="fade-up">
            <img src={!data?.GuideDetails?.user_detail?.profile_picture ? Icon : data.GuideDetails.user_detail?.profile_picture} alt="" />
          </div>
          <div className="tour-guide-content">
            <div className="row gy-3 gy-md-0 mb-3 align-items-center">
              <div className="col-md-8 col-sm-8">
                <div className="name-rating-wrap" data-aos="fade-up">
                  <h2 className="primary__color m-0 p-0">{data?.GuideDetails?.name}</h2>
                  <Star stars={(data?.AvgRatings)?.toString()}/>
                </div>
                <div className="guide-location" data-aos="fade-up" data-aos-delay="100">
                  <div className="location-icon">
                    <img src={location} alt="" />
                  </div>
                  <div className="location-address dark-text text-capitalize">
                   {data?.GuideDetails?.country}
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 text-sm-end" data-aos="fade-up" data-aos-delay="200">
                <p className="guide-cost m-0">{data?.GuideDetails?.user_detail?.price ? `$${data?.GuideDetails?.user_detail?.price}/hr`: ''}</p>
              </div>
            </div>
            <p data-aos="fade-up" data-aos-delay="300">
               {data?.GuideDetails?.user_detail?.bio}
            </p>
            {(User.access_token&& User.role_name==="TRAVELLER") &&(
                     <Link to="/dashboard/findeguide" state={{id:id, show:true}} className="button-primary small-button">
                     Book Now
                   </Link>
                    )}
                     {(User.access_token&& User.role_name==="GUIDE") &&(
                    <Link to="/" className="button-primary small-button">
                      Book Now
                    </Link>
                    )}
                     {!User.access_token &&(
                    <Link to="/login" className="button-primary small-button">
                      Book Now
                    </Link>
                    )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
