import React from "react";
import { useSelector } from "react-redux";

const InterestingPlaces = () => {
  const data = useSelector((state) => state.touristGuide.guide_detailed_page);
  return (
    <section className="interesting-places">
      {data?.media_files?.length !== 0 && (
        <div className="container spacer-y pt-0">
          <h5 className="mb-4" data-aos="fade-up">
            Find more interesting place
          </h5>
          <div className="mb-3" data-aos="fade-up">
            <div className="">
              {data?.media_files?.map((place, i) => (
                <div key={i}>
                  <p className="fw-bold color__grey subtitle mt-2">
                    {place.destination_title}
                  </p>
                  <div className="place-wrapper">
                    {data?.media_files[i].file_urls?.map((images, index) => {
                      return (
                        <>
                          <div className="place-images" key={index}>
                            <img src={images} alt="errplace" />
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default InterestingPlaces;
