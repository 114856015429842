import React, { useEffect } from "react";
import loginSuccess from "../../../assets/images/loginSuccess.png";
import "../../auth/LoginSignup.css";

const Success = (props) => {
  return (
    <div
      id="success"
      className={
        props.show === true ? "mail-sent-popup active" : "mail-sent-popup "
      }
    >
      <div className="main-sent-wrapper zindex-up d-flex flex-column align-items-center justify-content-center">
        <img src={loginSuccess} alt="" />
        <p className="mt-0">
          {/* You have successful logged In */}
          You have successfully logged In
        </p>
      </div>
      <div className="popup-overlay" onClick={props.onHide}></div>
    </div>
  );
};

export default Success;
