import React, { useState } from "react";
// import blog_image from "../../../../../assets/images/blog-image.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { postApi } from "../../../../../config/apiFunctions";
import { contact_us } from "../../../../../config/endpoints";
import "react-phone-number-input/style.css";
import { emailRegExp } from "../../../../../config/common/common";
import { useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { ThreeDots } from "react-loader-spinner";

const ContactWrapper = ({ dataList }) => {
  const [btndisable, setBtnDisable] = useState(false);
  const toast = useToast();
  const formOptions = { resolver: yupResolver(Schema) };
  const { register, handleSubmit, formState, reset, setValue, trigger } =
    useForm(formOptions);
  const { errors } = formState;
  const User = useSelector((state) => state.auth.SignupUser);

  const [contactDetails, setContatDetails] = useState({
    phone: User.phone,
    isoCode: User.country_code_iso,
    dialCode: User.country_code,
  });

  const onContactNoChange = (value, country, e, formattedValue) => {
    const { dialCode: country_code, countryCode: country_iso_code } = country;
    const _phone = value.slice(country_code.length);
    setContatDetails({
      ...contactDetails,
      phone: _phone,
      isoCode: country_iso_code,
      dialCode: `+${country_code}`,
    });
    setValue("contact_no", _phone);
    trigger("contact_no");
  };

  const onSubmit = (e) => {
    setBtnDisable(true);
    delete e.contact_no;
    const obj = {
      ...e,
      submitted_from: 1,
      contact_no: contactDetails?.phone,
      country_code: contactDetails.dialCode,
      country_code_iso: contactDetails.isoCode,
    };
    postApi(contact_us, obj, User?.access_token)
      .then((res) => {
        setBtnDisable(false);
        toast({
          title: res.data.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
        reset();
        setContatDetails({
          phone: "",
          isoCode: "",
          dialCode: "",
        });
      })
      .catch((err) => {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
  };
  return (
    <section className="contact-wrapper">
      <div className="container spacer-y pt-0">
        <div className="row gx-lg-5 gy-4 gy-md-3 align-items-center">
          <div className="col-md-6 order-2 order-md-1">
            <h2 className="mb-3 p-0">Get in touch</h2>
            <p className="mb-40">
              Our friendly team would love to hear from you.
            </p>
            <div className="">
              <form
                className=" row gy-3 gx-4"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="col-lg-6">
                  <label
                    htmlFor=""
                    className="form-label theme-label color__paragraph"
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    className={`form-control theme-controls ${
                      errors.first_name ? "is-invalid" : ""
                    }`}
                    placeholder="First Name"
                    {...register("first_name")}
                  />
                  <div className="invalid-feedback">
                    {errors.first_name?.message}
                  </div>
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor=""
                    className="form-label theme-label color__paragraph"
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    className={`form-control theme-controls ${
                      errors.last_name ? "is-invalid" : ""
                    }`}
                    placeholder="Last Name"
                    {...register("last_name")}
                  />
                  <div className="invalid-feedback">
                    {errors.last_name?.message}
                  </div>
                </div>
                <div className="col-lg-12">
                  <label
                    htmlFor=""
                    className="form-label theme-label color__paragraph"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="user_email"
                    className={`form-control theme-controls ${
                      errors.user_email ? "is-invalid" : ""
                    }`}
                    placeholder="your@company.com"
                    {...register("user_email")}
                  />
                  <div className="invalid-feedback">
                    {errors.user_email?.message}
                  </div>
                </div>
                <div className="col-lg-12">
                  <label
                    className="small-text text-grey-scale mb-1 f-600"
                    htmlFor="Phone number"
                  >
                    Phone Number
                  </label>
                  <PhoneInput
                    // disableDropdown
                    //  country={contactDetails.isoCode}
                    value={`${contactDetails.dialCode}${contactDetails.phone}`}
                    placeholder="Enter contact number"
                    countryCodeEditable={false}
                    enableSearch={true}
                    onChange={(value, country, e, formattedValue) =>
                      onContactNoChange(value, country, e, formattedValue)
                    }
                    sx={{ width: "100%" }}
                    isValid={() => (!!errors.phone ? "" : true)}
                  />
                  <input
                    {...register("contact_no")}
                    type="hidden"
                    value={contactDetails.phone}
                  />
                  {errors.contact_no && (
                    <div
                      className="text-danger mt-1 fs-6"
                      sx={{ ml: "1px" }}
                      error={true}
                    >
                      <div className="phoneerr small">
                        Please enter valid number
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-12">
                  <label
                    htmlFor=""
                    className="form-label theme-label color__paragraph"
                  >
                    Message
                  </label>
                  <textarea
                    name="message"
                    id=""
                    className={`form-control theme-controls ${
                      errors.message ? "is-invalid" : ""
                    }`}
                    rows="4"
                    {...register("message")}
                    placeholder="Write something here"
                  />
                  <div className="invalid-feedback">
                    {errors.message?.message}
                  </div>
                </div>
                <div className="col-lg-12">
                  <button
                    type="submit"
                    className="button-primary w-100 next-step create-profile-btn"
                  >
                    {btndisable === false ? (
                      "Send Message"
                    ) : (
                      <ThreeDots
                        height="20"
                        width="80"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6 order-1 order-md-2">
            <div className="contact-image">
              <img
                src={
                  dataList && dataList?.value?.banner_image?.upload_image_url
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactWrapper;
const Schema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .min(2, "Too Short!")
    .matches(/^[A-Za-z\s’']*$/, "Please enter letters only"),
  last_name: Yup.string()
    .required("Last name is required")
    .min(2, "Too Short!")
    .matches(/^[A-Za-z\s’']*$/, "Please enter letters only"),
  user_email: Yup.string()
    .required("Email is required")
    .matches(emailRegExp, "Please enter valid email address"),
  message: Yup.string()
    .required("Please enter message")
    .min(15, "Message must be at least 15 characters long.")
    .trim(" ")
    .max(250, "You have exceed limit"),
  contact_no: Yup.string()
    .required("Phone number is required")
    .min(10, "Enter valid Phone Number")
    .max(15, "Enter valid Phone Number"),
});
