import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  // LoginUser: [],
  LoginUser: {},
  SignupUser: {},
  token: [],
  device_token: [],
  push_notification: [],
  chat_notification: [],
  toggle_notification: [],
  expire_token: [],
  waiting_status: [false],
};

const LoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    LOGIN_USER: (state, action) => {
      return { LoginUser: action.payload };
    },
    SIGNUP_USER_DETAILS: (state, action) => {
      return { ...state, SignupUser: action.payload };
    },
    TOKEN: (state, action) => {
      return { ...state, token: action.payload };
    },
    DEVICE_TOKEN: (state, action) => {
      return { ...state, device_token: action.payload };
    },
    PUSH_NOTIFICATION: (state, action) => {
      return { ...state, push_notification: action.payload };
    },
    CHAT_NOTIFICATION: (state, action) => {
      return { ...state, chat_notification: action.payload };
    },
    TOGGLE_NOTIFICATION: (state, action) => {
      return { ...state, toggle_notification: action.payload };
    },
    EXPIRE_TOKEN: (state, action) => {
      return { ...state, expire_token: action.payload };
    },
    WAITING_STATUS: (state, action) => {
      return { ...state, waiting_status: action.payload };
    },
  },
});
export const {
  LOGIN_USER,
  SIGNUP_USER_DETAILS,
  PROFILE_PIC,
  TOKEN,
  DEVICE_TOKEN,
  PUSH_NOTIFICATION,
  CHAT_NOTIFICATION,
  TOGGLE_NOTIFICATION,
  EXPIRE_TOKEN,
  WAITING_STATUS,
} = LoginSlice.actions;
export default LoginSlice.reducer;
