import React, { useEffect, useState } from 'react'
import TableHeader from './sections/ tableHeader/TableHeader'
import TableContent from './sections/tableContent/TableContent'
import BookingDetail from '../../pages/bookingHistoryPage/bookingDetail/BookingDetail';
import BookingDetails from './sections/tableContent/bookingDetails/BookingDetails';
import { useLocation } from 'react-router';


const FindeGuide = ({onLoadComponent,setOnLoadComponent}) => {
  
  useEffect(()=>{
    setOnLoadComponent(!onLoadComponent)
  },[])

  return (
   <>
   <div className="table_fixed booking_innerbox"  style={{ height: "calc(100vh - 130px)" }}>
   <TableHeader />                         
    {/* <TableContent 
     /> */}
  </div>
   </>
  )
}

export default FindeGuide;


