import { createSlice } from "@reduxjs/toolkit";
const initialState={
    profilePic:[],
    id_proof:[],
}

const ProfilePictureSlice = createSlice({
name:"pfofilePic",
initialState,
reducers:{
 
      PROFILE_PIC: (state,action) =>{
        return {profilePic:(action.payload)}
      },
      ID_PROOF: (state,action) =>{
        return {id_proof:(action.payload)}
      },
  }
});
export const {PROFILE_PIC, ID_PROOF} = ProfilePictureSlice.actions;
export default ProfilePictureSlice.reducer;



