import React from "react";
import HomePage from "../main/homePage/Tourist";

import "./../Home/Home.css";
const Home = () => {
  return (
    <div>
      <HomePage />
    </div>
  );
};
export default Home;
