import React, { useEffect, useState } from "react";
import camera from "../../../../assets/images/camera.svg";
import defaulticon from "../../../../assets/images/profile-placeholder1.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { putApi } from "../../../../config/apiFunctions/index.js";
import {
  ProfileImage,
  Traveler_Update_Profile,
} from "../../../../config/endpoints";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { SIGNUP_USER_DETAILS } from "../../../../redux/slices/auth/LoginSlice";
import { PROFILE_PIC } from "../../../../redux/slices/profilePicture/ProfilePictureSlice";
import { phoneRegExp } from "../../../../config/common/common";
import PhoneInput from "react-phone-input-2";
import { ThreeDots } from "react-loader-spinner";
import { GetCountries, GetState, GetCity } from "react-country-state-city";

const CreateTravelerProfile = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const [btndisable, setBtnDisable] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [state, setStateSelect] = useState([]);
  const [getCityLoader, setGetLoader] = useState({
    countries: false,
    state: false,
    city: false,
  });
  const [city, setCity] = useState([]);

  const formOptions = { resolver: yupResolver(Schema) };
  const { register, handleSubmit, formState, reset, setValue, trigger } =
    useForm(formOptions);
  const { errors } = formState;
  const Userdata = useSelector((state) => state.auth.SignupUser);
  const device_token = useSelector((state) => state.auth.device_token);
  const Profile_Picture = useSelector((state) => state.profilePic.profilePic);

  useEffect(() => {
    if (Profile_Picture) {
      if (Profile_Picture.length !== 0) {
        setValue("pp", Profile_Picture.toString());
        trigger("pp");
      }
    }
  }, [Profile_Picture]);

  function handleChange(e) {
    const formdata = new FormData();
    formdata.append("profile_picture", e.target.files[0]);
    formdata.append("id", Userdata?.id);
    formdata.append("email", Userdata?.email);
    putApi(ProfileImage, formdata)
      .then((res) => {
        setValue("pp", res.data.data.image_url);
        trigger("pp");
        // setFile(res.data.data.image_url);
        dispatch(PROFILE_PIC(res.data.data.image_url));

        toast({
          title: res.data.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((err) => {
        setBtnDisable(false);
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
        });
      });
  }
  // const [contactDetails, setContatDetails] = useState({
  //   phone: "",
  //   isoCode: "+1",
  //   dialCode: "us",
  // });

  // const onContactNoChange = (value, country, e, formattedValue) => {
  //   const { dialCode: country_code, countryCode: country_iso_code } = country;
  //   const _phone = value.slice(country_code.length);
  //   setContatDetails({
  //     ...contactDetails,
  //     phone: _phone,
  //     isoCode: country_iso_code,
  //     dialCode: country_code,
  //   });
  //   setValue("phone", _phone);
  //   trigger("phone");
  // };
  const onSubmit = (e) => {
    setTimeout(() => {
      setBtnDisable(true);
    }, 100);
    let data = {};
    if (device_token.length === 0) {
      data = {
        id: Userdata.id,
        email: Userdata.email,
        // phone: e.phone,
        // country_code: `+${contactDetails.dialCode}`,
        // country_code_iso: contactDetails.isoCode,
        country: e.country,
        state: e.state,
        city: e.city,
        application_type: 1,
        // "device_token":device_token
      };
    } else {
      data = {
        id: Userdata.id,
        email: Userdata.email,
        // phone: e.phone,
        // country_code: `+${contactDetails.dialCode}`,
        // country_code_iso: contactDetails.isoCode,
        country: e.country,
        state: e.state,
        city: e.city,
        application_type: 1,
        device_token: device_token,
      };
    }

    putApi(Traveler_Update_Profile, data)
      .then((res) => {
        dispatch(SIGNUP_USER_DETAILS(res.data.data));
        if (res.status === 200) {
          // setBtnDisable(false);
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
          navigate("/dashboard");
          reset();
        }
      })
      .catch((err) => {
        // setBtnDisable(false);
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
    //setFile(null);
  };

  // set country ,city ,state
  const handleAddress = async (event, type) => {
    switch (type) {
      case "country":
        {
          setGetLoader({ ...getCityLoader, ["state"]: true });
          const { value } = event.target;
          const country = countries.find((country) => country.name === value);
          setCountryId(country.id);
          await GetState(country.id).then(async (state) => {
            setValue("state", state[0]?.name);
            setStateSelect(state);
            await GetCity(country.id, state[0]?.id).then((result) => {
              setValue("city", result[0]?.name);
              setCity(result);
            });
          });
          setGetLoader({ ...getCityLoader, ["state"]: false });
        }
        break;

      case "state":
        {
          setGetLoader({ ...getCityLoader, ["city"]: true });
          const { value } = event.target;
          const selectedState = state.find((state) => state.name === value);
          await GetCity(countryId, selectedState.id).then((result) => {
            setValue("city", result[0]?.name);
            setCity(result);
          });
          setGetLoader({ ...getCityLoader, ["city"]: false });
        }
        break;

      default:
        getDefaultAddress();
        break;
    }
  };

  // get default address
  const getDefaultAddress = async () => {
    await GetCountries().then(async (country) => {
      const filterArray = country.find((e) => e.name === "United States");
      setValue("country", filterArray.name);
      setCountryId(filterArray.id);
      setCountries(country);
      await GetState(filterArray.id).then(async (state) => {
        setValue("state", state[0].name);
        setStateSelect(state);
        await GetCity(filterArray.id, state[0].id).then((result) => {
          setValue("city", result[0].name);
          setCity(result);
        });
      });
    });
  };

  useEffect(() => {
    getDefaultAddress();
  }, []);

  return (
    <section className="login-signup-wrapper create-profile-page signup-page">
      <div className="container-fluid px-0 spacer-y">
        <div className="row gy-4 gy-lg-0 gx-0 zindex-up">
          <div className="col-lg-6 pe-lg-5 order-2 order-lg-1 spacing-left">
            <h2 className="pb-0 mb-3">Create Your Profile</h2>
            <p>Create your profile to see next advantures.</p>
            <p className="text-sm">
              <strong>Note:</strong> Enhance your experience with us! Please
              update your profile information by filling out our quick profile
              update form. Once completed, you'll be redirected to your
              dashboard, where you can unlock all the exciting features and
              benefits of our platform.
            </p>
            <div className="create-profile-wrapper">
              <div className="col-lg-12">
                <div className="profile-pic-blk">
                  <label htmlFor="profile-pic" className="profile-image">
                    <input
                      type="file"
                      name="profile-pic"
                      className="profile-pic"
                      id="profile-pic"
                      onChange={handleChange}
                      accept="image/*"
                    />
                    <img
                      src={
                        Profile_Picture.length !== 0
                          ? Profile_Picture
                          : defaulticon
                      }
                      alt=""
                    />
                  </label>
                  <div className="camera-icon">
                    <img src={camera} alt="" />
                  </div>
                </div>
                <label
                  htmlFor=""
                  className="form-label theme-label d-block mb-0 color__paragraph "
                >
                  Upload Profile Photo
                </label>
                {/* <div className="text-danger" style={{ fontSize: ".875em" }}>
                  {errors.pp?.message}
                </div> */}
              </div>
              <form
                className="create-profile-form mt-4"
                onSubmit={handleSubmit(onSubmit)}
              >
                {/* <input type="hidden" {...register("name")} name="pp" /> */}
                <div className="row gy-3 gy-lg-4">
                  <div className="col-lg-6">
                    <label
                      htmlFor=""
                      className="form-label theme-label color__paragraph"
                    >
                      Name
                    </label>
                    <input
                      disabled
                      name="name"
                      placeholder="Enter name"
                      defaultValue={Userdata?.name}
                      className="form-control theme-controls"
                    />
                    {/* <input
                      disabled
                      name="name"
                      placeholder="Enter name"
                      defaultValue={Userdata?.name}
                      {...register("name")}
                      className={`form-control theme-controls ${
                        errors.name ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.name?.message}
                    </div> */}
                  </div>
                  <div className="col-lg-6">
                    <label
                      htmlFor=""
                      className="form-label theme-label color__paragraph"
                    >
                      Last Name
                    </label>
                    <input
                      disabled
                      name="last_name"
                      placeholder="Enter last name"
                      defaultValue={Userdata?.last_name}
                      {...register("last_name")}
                      className={`form-control theme-controls ${
                        errors.last_name ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.last_name?.message}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <label
                      htmlFor=""
                      className="form-label theme-label color__paragraph"
                    >
                      Email
                    </label>
                    <input
                      disabled
                      name="email"
                      rows="3"
                      placeholder="you@company.com"
                      defaultValue={Userdata?.email}
                      {...register("email")}
                      className={`form-control theme-controls ${
                        errors.email ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.email?.message}
                    </div>
                  </div>
                  {/* <div className="col-lg-12">
                    <label
                      htmlFor=""
                      className="form-label theme-label color__paragraph"
                    >
                      Contact Number
                    </label>
                    <PhoneInput
                      country="us"
                      value={`${contactDetails.dialCode}${contactDetails.phone}`}
                      placeholder="Enter contact number"
                      countryCodeEditable={false}
                      enableSearch={true}
                      onChange={(value, country, e, formattedValue) =>
                        onContactNoChange(value, country, e, formattedValue)
                      }
                      sx={{ width: "100%" }}
                      isValid={() => (!!errors.phone ? "" : true)}
                    />
                   
                    <input
                      type="hidden"
                      name="phone"
                      minLength="10"
                      maxLength="15"
                      placeholder="Enter your contact number"
                      {...register("phone")}
                    />
                    <div className="invalid-feedback">
                      {errors.phone?.message}
                    </div>
                  </div> */}
                  <div className="col-lg-12">
                    <label
                      htmlFor=""
                      className="form-label theme-label color__paragraph"
                    >
                      Country
                    </label>
                    {/* <input
                      type="text"
                      name="country"
                      placeholder="Enter country/city"
                      {...register("country")}
                      className={`form-control theme-controls ${
                        errors.country ? "is-invalid" : ""
                      }`}
                      maxLength={25}
                    /> */}

                    {countries.length > 0 && (
                      <select
                        {...register("country")}
                        name="country"
                        defaultValue={"United States"}
                        onChange={(e) => handleAddress(e, "country")}
                        className={`form-control theme-controls ${
                          errors.country ? "is-invalid" : ""
                        }`}
                      >
                        {getCityLoader.country && (
                          <option value={""}>Loading...</option>
                        )}

                        {countries.length > 0 &&
                          countries.map((country, index) => {
                            return (
                              <option value={country.name} key={index}>
                                {country.name}
                              </option>
                            );
                          })}
                      </select>
                    )}
                    <div className="invalid-feedback">
                      {errors.country?.message}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label
                      htmlFor=""
                      className="form-label theme-label color__paragraph"
                    >
                      State
                    </label>
                    {/* <input
                      type="text"
                      name="country"
                      placeholder="Enter country/city"
                      {...register("country")}
                      className={`form-control theme-controls ${
                        errors.country ? "is-invalid" : ""
                      }`}
                      maxLength={25}
                    /> */}
                    {state.length > 0 ? (
                      <select
                        {...register("state")}
                        type="text"
                        onChange={(e) => handleAddress(e, "state")}
                        className={`form-control theme-controls ${
                          errors.state ? "is-invalid" : ""
                        }`}
                      >
                        {getCityLoader.state && (
                          <option value={""}>Loading...</option>
                        )}
                        {state.length > 0 &&
                          state.map((state, index) => {
                            return (
                              <option value={state.name} key={index}>
                                {state.name}
                              </option>
                            );
                          })}
                      </select>
                    ) : (
                      <input
                        name="state"
                        placeholder="State"
                        {...register("state")}
                        className={`form-control theme-controls ${
                          errors.state ? "is-invalid" : ""
                        }`}
                      />
                    )}
                    <div className="invalid-feedback">
                      {errors.state?.message}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label
                      htmlFor=""
                      className="form-label theme-label color__paragraph"
                    >
                      City
                    </label>
                    {/* <input
                      type="text"
                      name="country"
                      placeholder="Enter country/city"
                      {...register("country")}
                      className={`form-control theme-controls ${
                        errors.country ? "is-invalid" : ""
                      }`}
                      maxLength={25}
                    /> */}
                    {city.length > 0 ? (
                      <select
                        {...register("city")}
                        type="text"
                        className={`form-control theme-controls ${
                          errors.city ? "is-invalid" : ""
                        }`}
                      >
                        {getCityLoader.city && (
                          <option value={""}>Loading...</option>
                        )}
                        {city.length > 0 &&
                          city.map((city, index) => {
                            return (
                              <option value={city.name} key={index}>
                                {city.name}
                              </option>
                            );
                          })}
                      </select>
                    ) : (
                      <input
                        name="city"
                        placeholder="City"
                        {...register("city")}
                        className={`form-control theme-controls ${
                          errors.city ? "is-invalid" : ""
                        }`}
                      />
                    )}
                    <div className="invalid-feedback">
                      {errors.city?.message}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button
                      type="submit"
                      // className="button-primary w-100 next-step create-profile-btn"
                      disabled={btndisable}
                      className="button-primary create-profile-btn w-100 d-flex justify-content-center"
                    >
                      {
                        btndisable === true ? (
                          <ThreeDots
                            height="20"
                            width="80"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          "Submit"
                        )
                        // <input
                        //   type="submit"
                        //   value="Submit"
                        //   disabled={btndisable}
                        //   className="button-primary create-profile-btn w-100"
                        // />
                      }
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="aside-image"></div>
          </div>
        </div>
      </div>
      <div className="gradient-overlay hero"></div>
    </section>
  );
};

export default CreateTravelerProfile;

const Schema = Yup.object().shape({
  // phone: Yup.string()
  //   .required("Phone number is required")
  //   .matches(phoneRegExp, "Please enter valid mobile number"),
  // country: Yup.string()
  //   .required("Country is required")
  //   .max(30, "You have exceeded character limit"),
  // pp: Yup.string().required("Profile picture  is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string(),
});
