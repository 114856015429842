import React from "react";
import { useSelector } from "react-redux";

const RecentPost = () => {
  const data = useSelector((state) => state.touristGuide.blog?.rows);
  return (
    <div className="col-lg-4 col-md-12 recent-posts mt-5 mt-lg-0">
      <h6 className="section-heading" data-aos="fade-up">
        Recent Posts
      </h6>
      <ul className="list-unstyled recent-post-list mb-0">
        {data?.slice(0, 3).map((list, i) => (
          <li key={i} className="recent-post-wrap" data-aos="fade-up">
            <div className="recent-post-image">
              <a href={`/blog-detail/${list.slug}`}>
                <img src={list?.banner_image_url} className="w-100" alt="" />
              </a>
            </div>
            <div className="recent-post-content">
              <p className="mb-0 small fw-bold dark-text">
                <a href={`/blog-detail/${list.slug}`}>{list?.title}</a>
              </p>
              <span className="date text-sm">20 Jan 2022</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentPost;
