import React, { useEffect, useState } from "react";
import WhatsInApp from "../homePage/section/whatsInApp/WhatsInApp";
import Banner from "./sections/banner/Banner";
import ContactWrapper from "./sections/getInTouch/ContactWrapper";
import Details from "./sections/details/Details";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "../../../config/apiFunctions";
import { website_content } from "../../../config/endpoints";
import { CONTACT_US_PAGE } from "../../../redux/slices/content/ContentSlice";
import { Oval } from "react-loader-spinner";

const ContactUs = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.content.contactUsPage);
  const [loading, setLoading] = useState(false);

  const contactUsPageData = () => {
    setLoading(true);
    getApi(`${website_content}?key=CONTACT_US_PAGE&section=CONTACT_US`)
      .then((res) => {
        dispatch(CONTACT_US_PAGE(res?.data?.data));
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    contactUsPageData();
  }, []);
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            margin: "auto",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          <Banner dataList={data} />
          <ContactWrapper dataList={data} />
          <Details dataList={data} />
          <WhatsInApp />
        </>
      )}
    </>
  );
};

export default ContactUs;
