import React, { useState } from "react";
// import popular_img1 from "../../../../../assets/images/popular_img1.png";
// import popular_img3 from "../../../../../assets/images/popular_img3.png";
import "./TravelePlace.css";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import parse from "html-react-parser";

const TravelPlace = ({ dataList }) => {
  const [startCount, setStartCount] = useState(false);
  const { ref, inView } = useInView();

  // Start counting up when the component comes into view
  if (inView && !startCount) {
    setStartCount(true);
  }

  return (
    <section className="place theme-gradient">
      <div className="container spacer-y">
        <div className="row gy-4 gy-md-0 align-items-center">
          <div className="col-lg-6 col-md-12 order-2 order-md-1">
            <div className="place_content">
              <h2 className="primary__color" data-aos="fade-up">
                {dataList && dataList[0]?.value?.title}
              </h2>
              <div data-aos="fade-up" data-aos-delay="300">
                {parse(`${dataList && dataList[0]?.value?.description}`)}
              </div>
              <div className="numbering" ref={ref}>
                <div className="numbering_inner">
                  <div className="numbering_item">
                    <h4 className="primary__color m-0">
                      {startCount && <CountUp end={100} duration={1} />}
                      M+
                    </h4>
                    <p className="m-0">Visitors</p>
                  </div>
                  <div className="numbering_item">
                    <h4 className="primary__color m-0">
                      {startCount && <CountUp end={500} duration={1} />}
                    </h4>
                    <p className="m-0">Hotels</p>
                  </div>
                </div>
                <div className="numbering_inner">
                  <div className="numbering_item">
                    <h4 className="primary__color m-0">
                      {startCount && <CountUp end={1000} duration={1} />}
                    </h4>
                    <p className="m-0">Restaurants</p>
                  </div>
                  <div className="numbering_item">
                    <h4 className="primary__color m-0">
                      {startCount && <CountUp end={50} duration={1} />}
                    </h4>
                    <p className="m-0">Traveling Spots, and many more.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-8 mx-auto order-1 order-md-2 mt-5 mt-lg-0">
            <div className="left-small-right-big">
              <img
                src={
                  dataList && dataList[0]?.value?.banner_image1.upload_image_url
                }
                alt="banner"
                data-aos="fade-up"
              />
              <img
                src={
                  dataList && dataList[0]?.value?.banner_image2.upload_image_url
                }
                alt="banner"
                data-aos="fade-up"
                data-aos-delay="200"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TravelPlace;
