import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';


const Popup = (props) => {
    const navigate = useNavigate();
    const handleRedirect=()=>{
    props.onHide();
    navigate('/dashboard/settings')

    }
  return (
    <Modal
    {...props}
    size="sm"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <div className="modal-content p-30">
      <div className="modal_header position-relative">
        <h3 className="text-secondary f-700 mb-2">Sorry, We are unable to proceed with your request. Please add your bank details first! </h3>
        <p className="text-grey mb-2">
        Dear User,

We would like to inform you that clicking the "Yes" option will direct you to a page where you can securely add your bank details. Please ensure that you are providing accurate and up-to-date information. Protecting your sensitive information is our utmost priority, so please make sure you are on a legitimate and secure website before proceeding.
        </p>
        <button
          type="button"
          className="btn-close"
          onClick={props.onHide}
        ></button>
      </div>
        <div className="modal-body p-0 mt-3">
        </div>

        <div className="  modal-footer border-0 p-0">
          <button
            className="btn-main btn-red w-50 mw-100"
            onClick={props.onHide}
          >
           cancel
          </button>
          <button
            className="btn-main btn-primary w-50 mw-100"
            onClick={handleRedirect}
          >
           Yes
          </button>
        </div>
    </div>
  </Modal>
  )
}

export default Popup
