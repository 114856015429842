import React, {useState } from "react";
import "./Write.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { add_review } from "../../../../../config/endpoints";
import { emailRegExp } from "../../../../../config/common/common";
import { postApi } from "../../../../../config/apiFunctions";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ADDREVIEW } from "../../../../../redux/slices/TouristGuideReducer/guideSlice";
import { useDispatch } from "react-redux";

const WriteReview = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formOptions = { resolver: yupResolver(Schema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  const toast = useToast();
  const [toggle, setToggle] = useState(false);
  const [value, setValue] = useState(null);

  const handleToggle = (value) => {
    setToggle(true);
    setValue(value);
  };
   const onSubmit = (e) => {
    const obj = {
      ...e,
      rated_to_user_id: props.id,
      ratings: value,
    };
    postApi(add_review, obj)
      .then((res) => {
        dispatch(ADDREVIEW(res.status))
        if (res.status === 200) {
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            // onCloseComplete: () => setBtnDisable(false),
          });
          reset();
          setToggle(false);
          setValue(null);
          props.onHide();
          
        }
      })
      .catch((errors) => {
        toast({
          description: errors.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          // onCloseComplete: () => setBtnDisable(false),
        });
      });
  };

 
  return (
    <>
      <section
        className={props.show === true ? "write-review active" : "write-review"}
      >
        <div className="write-review-blk zindex-up">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2 className="text-center primary__color p-0 mb-40">
              {/* Write A Review */}
            </h2>
            <div>
              {/* <div>
            {[1, 2, 3, 4, 5].map((index) => {
                  return (
                    <span className="rating-stars">
                      {/* <i className="fa-solid fa-star"></i> */}
              {/* <StarRating value={0} />
                    </span>
                  );
                })}
            </div> */}
              <label htmlFor="" className="form-label color__grey">
                Your Ratings
              </label>
              <div className="stars-blk mb-3">
                <span
                  className={
                    toggle === true && value === 1
                      ? "rating-stars-fill"
                      : "rating-stars"
                  }
                  onClick={() => handleToggle(1)}
                >
                  <i name="ratings" className="fa-solid fa-star" value={1}></i>
                </span>
                <span
                  className={
                    toggle === true && value === 2
                      ? "rating-stars-fill"
                      : "rating-stars"
                  }
                  onClick={() => handleToggle(2)}
                >
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                </span>
                <span
                  className={
                    toggle === true && value === 3
                      ? "rating-stars-fill"
                      : "rating-stars"
                  }
                  onClick={() => handleToggle(3)}
                >
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                </span>
                <span
                  className={
                    toggle === true && value === 4
                      ? "rating-stars-fill"
                      : "rating-stars"
                  }
                  onClick={() => handleToggle(4)}
                >
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                </span>
                <span
                  className={
                    toggle === true && value === 5
                      ? "rating-stars-fill"
                      : "rating-stars"
                  }
                  onClick={() => handleToggle(5)}
                >
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                </span>
              </div>
            </div>
            <div className="">
              <label htmlFor="" className="form-label color__grey">
                Your Review
              </label>
              <textarea
                name="review_message"
                id=""
                {...register("review_message")}
                className={`form-control theme-controls mb-3 ${
                  errors.review_message ? "is-invalid" : ""
                }`}
                rows="4"
              ></textarea>
              <div className="invalid-feedback">
                {errors.review_message?.message}
              </div>
            </div>
            <div className="row g-3 mb-40">
              <div className="col-md-12">
                <label htmlFor="" className="form-label color__grey">
                  Name*
                </label>
                <input
                  name="user_name"
                  type="text"
                  {...register("user_name")}
                  className={`form-control theme-controls ${
                    errors.user_name ? "is-invalid" : ""
                  }`}
                  placeholder="Your name"
                />
                <div className="invalid-feedback">
                  {errors.user_name?.message}
                </div>
              </div>
              {/* <div className="col-md-6">
                <label htmlFor="" className="form-label color__grey">
                  Email*
                </label>
                <input
                  type="email"
                  name="user_email"
                  {...register("user_email")}
                  className={`form-control theme-controls ${
                    errors.user_email ? "is-invalid" : ""
                  }`}
                  placeholder="Your email"
                />
                <div className="invalid-feedback">
                  {errors.user_email?.message}
                </div>
              </div> */}
            </div>
            <div className="text-center">
              <input type="submit" className="button-primary" value="Submit" />
            </div>
          </form>
        </div>
        <div className="review-overlay" onClick={props.onHide}></div>
      </section>
    </>
  );
};

export default WriteReview;
const Schema = Yup.object().shape({
  user_name: Yup.string()
    .required("Name is required")
    .min(2, "Too Short!")
    .matches(/^[A-Za-z\s’']*$/, "Please enter letters only"),
  // user_email: Yup.string()
  //   .required("Email is required")
  //   .matches(emailRegExp, "Please enter valid email address"),
  review_message: Yup.string().required("Description is required"),
});
