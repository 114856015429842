import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { putApi } from "../../../../../../../../config/apiFunctions";
import { request_itinerary } from "../../../../../../../../config/endpoints";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";

const EditItinerary = (props) => {

  const toast = useToast();
  const [value, setvalue] = useState(null);
  const [BtnDisable, setBtnDisable] = useState(false);
  const access_token = useSelector(
    (state) => state.auth.SignupUser.access_token
  );

  const requestItinerary = () => {
    setBtnDisable(true);
    const obj = {
      booking_id: props.ids,
      description: value,
    };
    putApi(request_itinerary, obj, access_token)
      .then((res) => {
        toast({
          title: res.data.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
        props.onHide();
        // navigate("/dashboard/itinerary");
      })
      .catch((err) => {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-content p-30">
        <div className="modal_header position-relative">
          <h3 className="text-secondary f-700 mb-2">Edit Package</h3>
          <p className="text-grey mb-2">Please let us know why.</p>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            onClick={props.onHide}
          ></button>
        </div>
        <div className="modal-body p-0">
          <div>
            <div className="form">
              <div className="my-3">
                <div>
                  <div className="input-box mt-3">
                    <textarea
                      className="form-control"
                      name="itinerary-details"
                      id=""
                      cols="30"
                      rows="6"
                      placeholder=""
                      onChange={(e) => setvalue(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer border-0 p-0">
          <a className="btn-main text-red w-100 p-15" onClick={props.onHide}>
            Not Now
          </a>
          <button
            disabled={BtnDisable}
            onClick={requestItinerary}
            className="btn-main btn-primary w-100 p-15 mw-160"
          >
            Send
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditItinerary;
