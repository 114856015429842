import React, { useEffect, useState } from "react";
import { getApi } from "../../../../../../config/apiFunctions";
import { get_commision_value } from "../../../../../../config/endpoints";

// getApi(guide_get_account, {}, User.access_token).then((res) => {
//   setAcc(JSON.parse(res.data.data.value));
// });

function Commision() {
  const [commisionValue, setCommisionValue] = useState();

  const getValue = async () => {
    await getApi(get_commision_value).then((res) => {
      const data = res.data.data;
      const getCommisionValue = data.filter((e) => e.key === "COMMISSION");
      //   console.log(getCommisionValue[0].value, "fjhklfhkhfshf");
      setCommisionValue(getCommisionValue[0].value);
    });
  };

  useEffect(() => {
    getValue();
  }, []);
  return <>{commisionValue}%</>;
}

export default Commision;
