import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { ADDREVIEW } from "../../../../../../../../redux/slices/TouristGuideReducer/guideSlice";
import { add_review } from "../../../../../../../../config/endpoints";
import { emailRegExp } from "../../../../../../../../config/common/common";
import { postApi } from "../../../../../../../../config/apiFunctions";
import { useNavigate } from "react-router-dom";

const AddReviewModal = (props) => {
  const [toggle, setToggle] = useState(false);
  const [value, setValuess] = useState(null);
  const [BtnDisable, setBtnDisable] = useState(false);
  const access_token = useSelector((state) => state.auth.SignupUser.access_token);
  const User = useSelector((state) => state.auth.SignupUser);
const navigate= useNavigate();
  const dispatch = useDispatch();
  const formOptions = { resolver: yupResolver(Schema) };
  const { register, handleSubmit, reset, formState, setValue, trigger } =
    useForm(formOptions);
  const { errors } = formState;
  const toast = useToast();
  const handleToggle = (value) => {
    setToggle(true);
    setValuess(value);
    setValue("review", value);
    trigger("review");
  };
  useEffect(() => {
    reset();
    setValuess(null);
    setValue("review", null);
  }, [props.show]);

  const onSubmit = (e) => {
    delete e.review;
    setBtnDisable(true);
    let new_review_message;
    // The textbox is contains only whitespace or empty
    if (e.review_message.trim() === "") {
      new_review_message = {
        review_message: e.review_message.trim(""),
      };
    } else {
      new_review_message = {
        review_message: e.review_message.trim(""),
      };
    }
    const obj = {
      ...e,
      user_name: e.user_name,
      user_email: e.user_email,
      review_message: new_review_message.review_message,
      booking_id: props.id,
      ratings: value,
    };
    postApi(add_review, obj, access_token)
      .then((res) => {
        dispatch(ADDREVIEW(res.status));
        props.onHide();
        
        if (res.status === 200) {
          navigate('/dashboard/my-bookings')
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
          reset();
          props.hideReview();
          setToggle(false);
          setValuess(null);
          props.onHide();
        }
      })
      .catch((errors) => {
        toast({
          description: errors.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
    //}
  };

  //   function vv() {
  //     var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(domainname.in|domainname.com)$/;
  //         var receivedEmail = document.getElementById("emailId").value.trim();
  //         if (!(receivedEmail.toLowerCase().match(validRegex))) {
  //             alert("Please enter valid email address.");
  //             setValue("user_email", "")
  //             trigger("user_email")
  //         }  else{
  //           alert("error")
  //         }

  // };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="review_modal">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className=" modal-dialog-centered modal-dialog-scrollablse">
            <div className="modal-content p-30">
              <div className="modal_header position-relative">
                <h2 className="text-primary text-center mb-2 font-2 f-700">
                  Write A Review
                </h2>
                {/* <!-- <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> --> */}
              </div>
              <div className="modal-body p-0 mt-4">
                <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                  <label
                    className="small-text text-grey-scale mb-1 f-600"
                    htmlFor="rating-total"
                  >
                    Your Ratings*
                  </label>
                  <div className="stars-blk mb-3">
                    <span
                      className={
                        toggle === true && value === 1
                          ? "rating-stars rating-stars-fill"
                          : "rating-stars"
                      }
                      onClick={() => handleToggle(1)}
                    >
                      <i
                        name="ratings"
                        className="fa-solid fa-star"
                        value={1}
                      ></i>
                    </span>
                    <span
                      className={
                        toggle === true && value === 2
                          ? "rating-stars rating-stars-fill"
                          : "rating-stars"
                      }
                      onClick={() => handleToggle(2)}
                    >
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                    </span>
                    <span
                      className={
                        toggle === true && value === 3
                          ? "rating-stars rating-stars-fill"
                          : "rating-stars"
                      }
                      onClick={() => handleToggle(3)}
                    >
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                    </span>
                    <span
                      className={
                        toggle === true && value === 4
                          ? "rating-stars rating-stars-fill"
                          : "rating-stars"
                      }
                      onClick={() => handleToggle(4)}
                    >
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                    </span>
                    <span
                      className={
                        toggle === true && value === 5
                          ? "rating-stars rating-stars-fill"
                          : "rating-stars"
                      }
                      onClick={() => handleToggle(5)}
                    >
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                    </span>
                  </div>
                  <input type="hidden" {...register("review")} />
                  <div className="text-danger" style={{ fontSize: ".875em" }}>
                    {errors.review?.message}
                  </div>
                </div>
                <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                  <label
                    className="small-text text-grey-scale mb-1 f-600"
                    htmlFor="review-text"
                  >
                    Your Review
                  </label>
                  <textarea
                    name="review_message"
                    id="myTextbox"
                    cols="30"
                    rows="6"
                    placeholder="Write your review here"
                    {...register("review_message")}
                    className={`form-control  ${
                      errors.review_message ? "is-invalid" : ""
                    }`}
                  ></textarea>
                  <div className="invalid-feedback">
                    {errors.review_message?.message}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4 w-100">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Name*
                      </label>
                      <input
                        type="text"
                        name="user_name"
                        defaultValue={User.last_name? `${User.name} ${User.last_name}`:User.name}
                        placeholder="Your name"
                        {...register("user_name")}
                        className={`small-text form-control ${
                          errors.user_name ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.user_name?.message}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4 w-100">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="email"
                      >
                        Email*
                      </label>
                      <input
                        id="emailId"
                        type="email"
                        name="user_email"
                        defaultValue={User.email}
                        placeholder="Your email"
                        {...register("user_email")}
                        className={`small-text form-control ${
                          errors.user_email ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.user_email?.message}
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="modal-footer border-0 p-0">
                <button
                  className="btn-main btn-primary w-100 p-15 m-auto mw-160"
                  disabled={BtnDisable}
                >
                  Post Review
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddReviewModal;
const Schema = Yup.object().shape({
  user_name: Yup.string()
    .required("Name is required")
    .min(2, "Too short!")
    .trim(" ")
    .max(28, "Name will not allow more then 28 characters")
    .matches(/^[A-Z]/, "First letter should be uppercase.")
    .matches(/^[A-Za-z\s’']*$/, "Please enter letters only"),
  // user_email: Yup.string()
  //   .email("Please enter valid email address")
  //   .required("Email is required"),

  // review_message: Yup.string()
  //   .required("Review is required")
  //   .min(5, "Too Short!")
  //   .max(250, "You have entered more then 250 words"),
  review: Yup.string().required("Rating is required"),
});
