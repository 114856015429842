import React, { useEffect } from "react";
import "./whatsInApp.css";
import google_play from "../../../../../assets/images/google_play.png";
import app_store from "../../../../../assets/images/app_store.png";
import Mobile_screen from "../../../../../assets/images/mobileImage.svg";
import app_shape from "../../../../../assets/images/app_shape.png";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "../../../../../config/apiFunctions";
import { website_content } from "../../../../../config/endpoints";
import { APPS } from "../../../../../redux/slices/content/ContentSlice";
import { Link } from "react-router-dom";

const WhatsInApp = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.content.apps);

  const appsData = () => {
    getApi(`${website_content}?key=HOME_PAGE&section=OUR_APP`).then((res) => {
      dispatch(APPS(res?.data?.data));
    });
  };

  useEffect(() => {
    appsData();
  }, []);

  return (
    <section className="app">
      <div className="container spacer-y">
        <div className="row gy-4 justify-content-between">
          <div className="col-lg-5 col-md-12 order-2 order-md-1">
            <div className="left_content">
              <h2 className="primary__color m-0" data-aos="fade-up">
                {data && data?.value?.title}
              </h2>
              <div
                className="paragraph_content"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {parse(`${data && data?.value?.description}`)}
              </div>
              <h4 className="primary__color m-0 py-2" data-aos="fade-up">
                Coming soon
              </h4>
              <div className="app_logo d-flex gap-4">
                <Link
                  to="/"
                  // to="https://play.google.com/store/apps/details?id=com.app.siestaglobal"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  target="blank"
                >
                  <img src={google_play} alt="google_play" />
                </Link>
                <Link
                  to="/"
                  // to="https://apps.apple.com/us/app/siesta-travel/id6473368509"
                  data-aos="fade-up"
                  data-aos-delay="400"
                  target="blank"
                >
                  <img src={app_store} alt="google_play" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 order-1 order-md-2 mt-md-5 mt-lg-0">
            <div className="app_img zindex-up">
              <div
                className="mobile_screen"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <img
                  src={data && data?.value?.banner_image?.upload_image_url}
                  className="w-100"
                  alt="screen"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="app_shape">
          <img src={app_shape} alt="shape" />
        </div>
      </div>
    </section>
  );
};

export default WhatsInApp;
