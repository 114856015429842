import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { getApi } from "../../../../../../../config/apiFunctions";
import {
  mybookings,
  // traveller_itinerary,
} from "../../../../../../../config/endpoints";
import { useSelector } from "react-redux";
import ItineraryTable from "../itineraryTable/ItineraryTable";
import moment from "moment";
// import { Link } from "react-router-dom";

const ItineraryHeader = () => {
  const access_token = useSelector(
    (state) => state.auth.SignupUser.access_token
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [myBookingList, setmyBookingList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [errorRes, setErrRes] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [squery, setSquery] = useState("");
  const [dates, setDates] = useState({
    statDate: startDate?.toISOString().slice(0, 10),
    enddate: "",
  });

  const onDateChange = (date) => {
    setStartDate(date);
    setEndDate();
    setDates({
      ...dates,
      statDate: moment(date).format("YYYY-MM-DD"),
      enddate: "",
    });
  };
  const onEndDateChange = (date) => {
    setEndDate(date);
    setDates({ ...dates, enddate: moment(date).format("YYYY-MM-DD") });
  };
  const handleChangeSearch = (e) => {
    setSquery(e);
  };

  useEffect(() => {
    itineraryDetails();
  }, [currentPage, squery, dates, endDate, startDate]);

  const itineraryDetails = () => {
    setLoading(true);
    let start_date = "";
    let end_date = "";
    if (dates.statDate || dates.enddate) {
      start_date = dates.statDate;
      end_date = dates.enddate;
    }
    getApi(
      `${mybookings}?bookingItinerary=yes&page_no=${currentPage}&search_text=${squery}&start_date=${start_date}&end_date=${end_date}`,
      {},
      access_token
    ).then((res) => {
      setErrRes(res.data.message);
      setLoading(false);
      setmyBookingList(res.data.data);
    });
  };
  const resetDate = (e) => {
    if (e === "startDate") {
      setStartDate(null);
      setDates({
        ...dates,
        statDate: "",
        enddate: "",
      });
    } else if (e === "endDate") {
      setEndDate(null);
      setDates({
        ...dates,
        enddate: "",
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="booking_header p-20 border-bottom">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-md-4 mb-3 mb-md-0">
                <div className="booking_filter position-relative">
                  <input
                    className="form-control"
                    type="text"
                    name="search"
                    maxLength="20"
                    placeholder="Search by destination"
                    onChange={(e) => handleChangeSearch(e.target.value)}
                  />
                  <button className="btn-search" style={{ cursor: "unset" }}>
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.5 21.5C16.7467 21.5 21 17.2467 21 12C21 6.75329 16.7467 2.5 11.5 2.5C6.25329 2.5 2 6.75329 2 12C2 17.2467 6.25329 21.5 11.5 21.5Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22 22.5L20 20.5"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              {/* <div className="col-md-3">
                <div className="d-flex align-items-center justify-content-end">
                  <Link
                    to="/dashboard/itinerary/previousitineraries"
                    className="btn-main btn-primary"
                  >
                    Previous Itineraries
                  </Link>
                </div>
              </div> */}
              <div className="col-md-8">
                <div className="m-flex-x d-flex align-items-center justify-content-end">
                  <div className="d-flex align-items-center">
                    <label
                      style={{ whiteSpace: "nowrap" }}
                      className="text-black f-700 me-0 me-md-3 mb-3 mb-md-0"
                      htmlFor="start_date"
                    >
                      Select Date :
                    </label>
                  </div>
                  <div className="date_selector position-relative">
                    <DatePicker
                      className="form-control dob-field small-text date-field"
                      selected={startDate}
                      onChange={(date) => onDateChange(date)}
                      dateFormat="MM-dd-yyyy"
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Choose Date"
                      // minDate={new Date()}
                      showDisabledMonthNavigation
                      required
                      onFocus={(e) => e.target.blur()}
                    />
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.33301 1.5835V3.9585"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.667 1.5835V3.9585"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.77051 7.19629H16.2288"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.625 6.72933V13.4585C16.625 15.8335 15.4375 17.4168 12.6667 17.4168H6.33333C3.5625 17.4168 2.375 15.8335 2.375 13.4585V6.72933C2.375 4.35433 3.5625 2.771 6.33333 2.771H12.6667C15.4375 2.771 16.625 4.35433 16.625 6.72933Z"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.4247 10.846H12.4318"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.4247 13.221H12.4318"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.49598 10.846H9.50309"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.49598 13.221H9.50309"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.56629 10.846H6.5734"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.56629 13.221H6.5734"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <button
                      className="dateClear"
                      onClick={() => resetDate("startDate")}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                  <h6 className="my-3 my-md-0 mx-0 mx-md-3 text-black f-700">
                    To
                  </h6>
                  <div className="date_selector d-flex align-items-center position-relative">
                    <DatePicker
                      className="form-control dob-field small-text date-field"
                      selected={endDate}
                      placeholderText="Choose Date"
                      onChange={(date) => onEndDateChange(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      dateFormat="MM-dd-yyyy"
                      required
                      onFocus={(e) => e.target.blur()}
                    />
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.33301 1.5835V3.9585"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.667 1.5835V3.9585"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.77051 7.19629H16.2288"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.625 6.72933V13.4585C16.625 15.8335 15.4375 17.4168 12.6667 17.4168H6.33333C3.5625 17.4168 2.375 15.8335 2.375 13.4585V6.72933C2.375 4.35433 3.5625 2.771 6.33333 2.771H12.6667C15.4375 2.771 16.625 4.35433 16.625 6.72933Z"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.4247 10.846H12.4318"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.4247 13.221H12.4318"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.49598 10.846H9.50309"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.49598 13.221H9.50309"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.56629 10.846H6.5734"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.56629 13.221H6.5734"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <button
                      className="dateClear"
                      onClick={() => resetDate("endDate")}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ItineraryTable
        myBookingList={myBookingList}
        isLoading={isLoading}
        errorRes={errorRes}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </>
  );
};

export default ItineraryHeader;
