import React, { useEffect, useState } from "react";
import WhatsInApp from "../homePage/section/whatsInApp/WhatsInApp";
import ContactUs from "../homePage/section/contactUs/ContactUs";
import GoExplore from "./sections/exploreWorld/GoExplore";
import TravelingTo from "./sections/travelingToWhere/TravelingTo";
import TravelPlace from "./sections/theperfectTravelPlace/TravelPlace";
import PlanYourTrip from "./sections/PlanYourTrip/PlanYourTrip";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "../../../config/apiFunctions";
import { website_content } from "../../../config/endpoints";
import { FOR_TRAVELLERS_PAGE } from "../../../redux/slices/content/ContentSlice";
import { Oval } from "react-loader-spinner";

const Traveler = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.content.forTravellerPage);
  const [goExploreData, setGoExploreData] = useState();
  const [travelingToData, setTravelingToData] = useState();
  const [travelPlaceData, setTravelPlaceData] = useState();
  const [loading, setLoading] = useState(false);
  const [planYourTripData, setPlanYourTripData] = useState();

  const forTravellerpageData = () => {
    setLoading(true);
    getApi(`${website_content}?key=FOR_TRAVELLERS`)
      .then((res) => {
        dispatch(FOR_TRAVELLERS_PAGE(res?.data?.data));
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    forTravellerpageData();
  }, []);

  useEffect(() => {
    if (data) {
      const getGoExploreData = data?.filter((e) => e.section === "INTRO");
      setGoExploreData(getGoExploreData);

      const getTravelingToData = data?.filter(
        (e) => e.section === "TRAVELLING"
      );
      setTravelingToData(getTravelingToData);

      const getTravelPlaceData = data?.filter((e) => e.section === "PLACES");
      setTravelPlaceData(getTravelPlaceData);

      const getPlanYourTripData = data?.filter((e) => e.section === "HELP");
      setPlanYourTripData(getPlanYourTripData);
    }
  }, [data]);
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            margin: "auto",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          <GoExplore dataList={goExploreData} />
          <TravelingTo dataList={travelingToData} />
          <TravelPlace dataList={travelPlaceData} />
          <PlanYourTrip dataList={planYourTripData} />
          <WhatsInApp />
          <ContactUs />
        </>
      )}
    </>
  );
};
export default Traveler;
