import React, { useEffect, useState } from "react";
import ContactUs from "../homePage/section/contactUs/ContactUs";
import Banner from "./sections/guideBanner/Banner";
import Experience from "./sections/experienceJourney/Experience";
import Benefits from "./sections/benifits/Benefits";
import Destination from "./sections/whatIsTheNextDestination/Destination";
import WhatsInApp from "../homePage/section/whatsInApp/WhatsInApp";
import "./index.css";
import { getApi } from "../../../config/apiFunctions";
import { website_content } from "../../../config/endpoints";
import { FOR_GUIDE_PAGE } from "../../../redux/slices/content/ContentSlice";
import { useDispatch, useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";

const TouristGuide = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.content.forGuidePage);
  const [bannerData, setBannerData] = useState();
  const [experienceData, setExperienceData] = useState();
  const [stepOneData, setStepOneData] = useState();
  const [stepTwoData, setStepTwoData] = useState();
  const [stepThreeData, setStepThreeData] = useState();
  const [stepFourData, setStepFourData] = useState();
  const [benefitsData, setBenefitsData] = useState();
  const [loading, setLoading] = useState(false);
  const [destinationData, setDestinationData] = useState();

  const forGuidePageData = () => {
    setLoading(true);
    getApi(`${website_content}?key=FOR_GUIDE`)
      .then((res) => {
        dispatch(FOR_GUIDE_PAGE(res?.data?.data));
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    forGuidePageData();
  }, []);

  useEffect(() => {
    if (data) {
      const getBannerData = data?.filter((e) => e.section === "INTRO");
      setBannerData(getBannerData);

      const getExperienceData = data?.filter(
        (e) => e.section === "TRAVEL_EXPERIENCE"
      );
      setExperienceData(getExperienceData);

      const getStepOneData = data?.filter((e) => e.section === "STEP_ONE");
      setStepOneData(getStepOneData);

      const getStepTwoData = data?.filter((e) => e.section === "STEP_TWO");
      setStepTwoData(getStepTwoData);

      const getStepThreeData = data?.filter((e) => e.section === "STEP_THREE");
      setStepThreeData(getStepThreeData);

      const getStepFourData = data?.filter((e) => e.section === "STEP_FOUR");
      setStepFourData(getStepFourData);

      const getBenefitsData = data?.filter((e) => e.section === "BENEFITS");
      setBenefitsData(getBenefitsData);

      const getDestinationData = data?.filter(
        (e) => e.section === "DESTINATION"
      );
      setDestinationData(getDestinationData);
    }
  }, [data]);
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            margin: "auto",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          <Banner dataList={bannerData} />
          <Experience
            dataList={experienceData}
            stepOne={stepOneData}
            stepTwo={stepTwoData}
            stepThree={stepThreeData}
            stepFour={stepFourData}
          />
          <Benefits dataList={benefitsData} />
          <Destination dataList={destinationData} />
          <WhatsInApp />
          <ContactUs />
        </>
      )}
    </>
  );
};

export default TouristGuide;
