export const signup = "/user/signup";
export const login = "/user/login";
export const sendEnquiry = "/user/send-enquiry";
export const role = "/user/fetch-role";
export const Email_Verifivation = "/user/verify-email";
export const ProfileImage = "/user/update-profile-image";
export const Traveler_Update_Profile = "/traveller/update-profile";
export const Guide_Update_Profile = "/guide/update-profile";
export const forgot_password = "/user/forgot-password";
export const reset_password = "/user/reset-password";
export const contact_us = "/user/contact-us";
export const search_guide = "/user/search-guide";
export const toprated_guide = "/user/guide-sort-by-ratings";
export const single_guide = "/user/find-guide";
export const all_blogs = "/user/get-blogs";
export const single_blog = "/user/get-blog";
export const add_review = "/traveller/add-ratings";
export const traveler_logout = "/traveller/logout";
export const guide_logout = "/guide/logout";
export const editTravelerProfilePic = "/traveller/update-profile-image";
export const editGuideProfilePic = "/guide/update-profile-image";
export const updateGuideProfile = "/guide/profile";
export const updateTravelerProfile = "/traveller/profile";
export const traveler_booking = "/traveller/booking";
export const guide_add_gallery = "/guide/gallery-add-photos";
export const guide_gallery = "/guide/gallery-photos";
export const guide_update_gallery = "/guide/update-gallery-image";
export const mybookings = "/traveller/booking-list";
export const myitineraries = "/traveller/see-sample-itinerary";
export const cancelTrip = "/traveller/cancel-booking";
export const guide_received_bookings = "/guide/booking-list";
export const guide_cancel_booking = "/guide/cancel-booking";
export const itinerary_detail = "/guide/itinerary-detail";
export const create_Itinerary = "/guide/create-itinerary";
export const create_Itinerary_by_ai = "/guide/get-ai-text";
export const edit_Itinerary = "/guide/edit-itinerary";
export const traveller_itinerary = "/traveller/itinerary-detail";
export const reject_itinerary = "/traveller/reject-itinerary";
export const request_itinerary = "/traveller/request-itinerary";
export const traveler_updatePassword = "/traveller/update-password";
export const guide_updatePassword = "/guide/update-password";
export const traveler_todays_trip = "/traveller/daily-schedule";
export const guide_todays_trip = "/guide/daily-schedule";
export const traveler_notifications = "/traveller/notifications";
export const traveler_notification_toggle = "/traveller/update-notification";
export const guide_notification_toggle = "/guide/update-notification";
export const traveler_user_list = "/traveller/eligible-message-user";
export const traveler_compose = "/traveller/send-compose-message";
export const traveler_sentList = "/traveller/sent-user-list";
export const traveler_threads = "/traveller/get-messages";
export const traveler_treadReply = "/traveller/reply-message";
export const traveler_voice_Reply = "/traveller/send-voice-message";
export const traveler_delete_message = "/traveller/delete-compose-message";
export const guide_user_list = "/guide/eligible-message-user";
export const guide_compose = "/guide/send-compose-message";
export const guide_sentList = "/guide/sent-user-list";
export const guide_threads = "/guide/get-messages";
export const guide_treadReply = "/guide/reply-message";
export const guide_voice_Reply = "/guide/send-voice-message";
export const guide_inboxList = "/guide/inbox-user-list";
export const traveler_inboxList = "/traveller/inbox-user-list";
export const traveler_deleteMSG = "/traveller/delete-compose-message";
export const guide_deleteMSG = "/guide/delete-compose-message";
export const guide_dashboard_gallery = "/guide/latest-gallery-photos";
export const traveler_dashboard_count = "/traveller/dashboard-home-counts";
export const guide_dashboard_count = "/guide/dashboard-home-counts";
export const guide_notifications = "/guide/notifications";
export const traveller = "/traveller";
export const guide = "/guide";
export const traveller_Advance_Payment = "/traveller/initial-pay";
export const complete_Booking = "/guide/complete-booking";
export const process_payment_Booking = "/guide/process-final-payment";
export const guide_availability = "/guide/update-availability";
export const traveler_transaction_list = "/traveller/transaction-list";
export const traveler_payment_status = "/traveller/initial-pay-status";
export const traveler_transaction_view = "/traveller/view-transaction";
export const guide_transaction_list = "/guide/transaction-list";
export const guide_transaction_view = "/guide/view-transaction";
export const traveller_final_payment = "/traveller/final-pay";
export const traveler_final_payment_status = "/traveller/final-pay-status";
export const guide_Advance_withdraw = "/guide/withdraw-amount";
export const guide_total_withdraw = "/guide/withdraw-all";
export const guide_add_account = "/guide/update-account";
export const guide_get_account = "/guide/get-account";
export const guide_delete_Id_Proof = "/guide/delete-documents";
export const guide_chart = "/guide/plot-graph";
export const user_popular_destination = "/user/popular-destinations";
export const get_commision_value = "/user/get-social-links";

// export const travaller_get_profile = "/traveller/get-profile";
// export const guide_get_profile = "/guide/get-profile";
export const get_profile = "/user/get-profile";
export const get_itinerary = "/traveller/generate-sample-itinerary";
export const payment_method_list = "/traveller/get-payment-methods-list";
export const website_content = "/user/web-content-for-users";
export const waitListPage_content = "/traveller/get-waiting-list-page-content";
export const all_rating = "/guide/all-rating-reviews";
export const delete_guide_account = "/guide/delete-account";
export const delete_traveller_account = "/traveller/delete-account";
export const check_waiting_traveller = "/traveller/check-waiting-user";
