import React, { useEffect, useState } from "react";
// import img1 from "../../../../../../../../../assets/images/guide-img-1.png";
// import gallery9 from "../../../../../../../../../assets/images/gallery-img-9.jpg";
// import gallery10 from "../../../../../../../../../assets/images/gallery-img-10.jpg";
// import gallery11 from "../../../../../../../../../assets/images/gallery-img-11.jpg";
// import gallery12 from "../../../../../../../../../assets/images/gallery-img-12.jpg";
// import gallery13 from "../../../../../../../../../assets/images/gallery-img-13.jpg";
// import gallery14 from "../../../../../../../../../assets/images/gallery-img-14.jpg";
// import gallery15 from "../../../../../../../../../assets/images/gallery-img-15.jpg";
// import gallery16 from "../../../../../../../../../assets/images/gallery-img-16.jpg";
// import gallery17 from "../../../../../../../../../assets/images/gallery-img-17.jpg";
// import gallery18 from "../../../../../../../../../assets/images/gallery-img-18.jpg";
// import gallery19 from "../../../../../../../../../assets/images/gallery-img-19.jpg";
// import gallery20 from "../../../../../../../../../assets/images/gallery-img-20.jpg";
import defaultImg from "../../../../../../../../../assets/images/profile-placeholder1.jpg";
import { Fancybox } from "@fancyapps/ui";
import BooknowModal from "../../modals/BooknowModal";
import AddReviewModal from "../../modals/AddReviewModal";
import { useParams } from "react-router-dom";
import { getApi } from "../../../../../../../../../config/apiFunctions";
import { single_guide } from "../../../../../../../../../config/endpoints";
import { GUIDE_DETAILED_PAGE } from "../../../../../../../../../redux/slices/TouristGuideReducer/guideSlice";
import { useDispatch, useSelector } from "react-redux";
import { Star } from "../../../../../../../../../config/common/common";
import NoPageFound from "../../../../../../../../Errors/SearchError/NoPageFound";
import { Oval } from "react-loader-spinner";

const BookingDetails = () => {
  const [bookmodalShow, setbookModalShow] = React.useState(false);
  const [reviewmodalShow, setReviewModalShow] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [showLess, setShowLess] = React.useState(null);
  const [guideLocation, setGuideLocation] = useState(null);
  const [userId, setUserId] = useState(null);
  const [error, setErr] = useState(false);
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.touristGuide.guide_detailed_page);
  const datas = useSelector((state) => state.touristGuide.addReview);
  const User = useSelector((state) => state.auth.SignupUser);

  const toggleReadMore = (index) => {
    if (showLess === null) {
      setShowLess(index);
    } else {
      if (showLess !== index) {
        setShowLess(index);
      } else {
        setShowLess(null);
      }
    }
  };
  Fancybox.bind(".image_gallery a", {
    groupAll: true,
  });

  useEffect(() => {
    detailedPage();
  }, [id, datas, reviewmodalShow]);

  const reviewPerRow = 3;
  const [next, setNext] = useState(reviewPerRow);
  const handleMoreImage = () => {
    setNext(next + reviewPerRow);
  };

  const detailedPage = () => {
    setLoading(true);
    getApi(single_guide + "/" + id, {}, User.access_token)
      .then((res) => {
        dispatch(GUIDE_DETAILED_PAGE(res.data.data));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setErr(true);
      });
  };
  return (
    <div className="table_fixed booking_innerbox">
      <div className="container_wrapper">
        {isLoading === true && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "auto",
              marginBottom: "auto",
              margin: "auto",
            }}
          >
            <Oval
              height={80}
              width={80}
              color="#1f74ca"
              wrapperStyle={{}}
              wrapperclassName=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#1f74ca8f"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
        {isLoading === false && (
          <div className="">
            {error === true ? (
              <NoPageFound />
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="overflow-table guide_inner full_height p-20"
                    data-aos="fade-up"
                    style={{ height: "calc(100vh - 135px)" }}
                  >
                    <div className="guide_header d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="guide_img me-3">
                          <img
                            src={
                              data?.GuideDetails.user_detail?.profile_picture
                                ? data?.GuideDetails.user_detail
                                    ?.profile_picture
                                : defaultImg
                            }
                            alt="Guide"
                          />
                        </div>
                        <div>
                          <div>
                            <h3 className="text-primary mb-2 f-700">
                              {!data?.GuideDetails.last_name
                                ? `${data?.GuideDetails.name}`
                                : `${data?.GuideDetails.name} ${data?.GuideDetails.last_name}`}
                            </h3>
                            <Star stars={data?.AvgRatings?.toString()} />
                          </div>
                        </div>
                      </div>
                      <div>
                        <button
                          className="book_guidebtn btn-main btn-primary p-10"
                          data-bs-toggle="modal"
                          data-bs-target="#booking_popup"
                          onClick={() => {
                            setbookModalShow(true);
                            setUserId(data.GuideDetails?.id);
                            setGuideLocation({
                              country: data.GuideDetails?.country,
                              state: data.GuideDetails?.state,
                              city: data.GuideDetails?.city,
                            });
                          }}
                        >
                          {/* Book Now */}
                          AI Itinerary
                          {/* Sample Itinerary */}
                        </button>
                      </div>
                      {bookmodalShow && (
                        <BooknowModal
                          show={bookmodalShow}
                          onHide={() => {
                            setbookModalShow(false);
                            localStorage.removeItem("itineraryDetail");
                          }}
                          id={userId}
                          location={guideLocation}
                        />
                      )}
                    </div>
                    <div className="guide_discription mt-4">
                      <p className="text-grey-scale f-400 mb-3">
                        {data?.GuideDetails.user_detail?.bio}
                      </p>
                    </div>
                    <div className="price_location p-20 border-bottom border-top d-flex align-items-center">
                      <div className="inline_icon d-flex align-items-center me-5">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.67188 14.8298C8.67188 16.1198 9.66188 17.1598 10.8919 17.1598H13.4019C14.4719 17.1598 15.3419 16.2498 15.3419 15.1298C15.3419 13.9098 14.8119 13.4798 14.0219 13.1998L9.99187 11.7998C9.20187 11.5198 8.67188 11.0898 8.67188 9.86984C8.67188 8.74984 9.54187 7.83984 10.6119 7.83984H13.1219C14.3519 7.83984 15.3419 8.87984 15.3419 10.1698"
                            stroke="#292D32"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 6.5V18.5"
                            stroke="#292D32"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
                            stroke="#292D32"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <h5 className="text-grey-scale f-700 ms-2 mb-0">
                          {data?.GuideDetails.user_detail?.price
                            ? `$${data?.GuideDetails.user_detail?.price}/hr`
                            : "___"}
                        </h5>
                      </div>
                      <div className="inline_icon d-flex align-items-center ms-5">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.9999 13.93C13.723 13.93 15.1199 12.5331 15.1199 10.81C15.1199 9.08687 13.723 7.69 11.9999 7.69C10.2768 7.69 8.87988 9.08687 8.87988 10.81C8.87988 12.5331 10.2768 13.93 11.9999 13.93Z"
                            stroke="#344054"
                            strokeWidth="1.5"
                          />
                          <path
                            d="M3.61971 8.99C5.58971 0.330002 18.4197 0.340003 20.3797 9C21.5297 14.08 18.3697 18.38 15.5997 21.04C13.5897 22.98 10.4097 22.98 8.38971 21.04C5.62971 18.38 2.46971 14.07 3.61971 8.99Z"
                            stroke="#344054"
                            strokeWidth="1.5"
                          />
                        </svg>
                        <h5 className="text-grey-scale f-700 ms-2 mb-0">
                          {data?.GuideDetails.country}
                        </h5>
                      </div>
                    </div>
                    {data?.media_files.length !== 0 && (
                      <div className="guide_gallery mt-4">
                        <h4 className="font-2 mb-3 text-grey-scale f-700">
                          Find more interesting place
                        </h4>
                        <div className="gallery_innerbox">
                          <div className="gallery_inner">
                            <div className="image_gallery">
                              {data?.media_files.map((title, i) => {
                                return (
                                  <div key={i} className="gallery_wrapper">
                                    <h5 className="gallery_title f-700 mb-4">
                                      {title.destination_title}
                                    </h5>
                                    <div className="gallery_list d-flex flex-wrap align-items-center justify-content-start">
                                      {data.media_files[i].file_urls?.map(
                                        (image, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className="position-relative gallery_img me-4"
                                            >
                                              <a
                                                href={image}
                                                data-fancybox="gallery"
                                              >
                                                <img
                                                  src={image}
                                                  alt="gallery-img"
                                                />
                                              </a>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* {data?.GuideDetails?.rating_and_reviews.length!==0&&} */}
                    <div className="guide_review_inner mt-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="font-2 text-grey-scale pt-5 ">
                          Rating & Reviews
                        </h4>
                        {/* <button
                          className="book_guidebtn btn-main btn-primary p-10"
                          data-bs-toggle="modal"
                          onClick={() =>   setReviewModalShow(true)}
                        >
                          Write a Review
                        </button> */}
                      </div>
                      {/* <AddReviewModal
                        show={reviewmodalShow}
                        onHide={() => setReviewModalShow(false)}
                        id={data?.GuideDetails.id}
                      /> */}
                      {data?.GuideDetails?.rating_and_reviews
                        ?.slice(0, next)
                        ?.map((list, i) => {
                          return (
                            <div
                              key={i}
                              className="review_box border-all p-20 my-3"
                            >
                              <Star stars={list?.ratings?.toString()} />
                              <h5 className=" review_title font-2 mb-3 text-grey-scale f-700">
                                {list.user_name}
                              </h5>
                              {list.review_message && (
                                <p className="f-400 text-grey-600">
                                  {list.review_message.length < 150 ? (
                                    <pre
                                      style={{
                                        whiteSpace: "break-spaces",
                                        font: "var(--primary-font)",
                                        fontSize: " 1rem",
                                      }}
                                      className="f-400 text-grey-600"
                                    >
                                      {list.review_message}
                                    </pre>
                                  ) : (
                                    <>
                                      {showLess !== i ? (
                                        list.review_message.slice(0, 150)
                                      ) : (
                                        <pre
                                          style={{
                                            whiteSpace: "break-spaces",
                                            font: "var(--primary-font)",
                                            fontSize: " 1rem",
                                          }}
                                          className="f-400 text-grey-600"
                                        >
                                          {list.review_message}
                                        </pre>
                                      )}
                                      <a
                                        style={{
                                          color: "#1f74ca",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => toggleReadMore(i)}
                                      >
                                        &nbsp;.....View{" "}
                                        {showLess !== i ? "More" : "Less"}
                                      </a>
                                    </>
                                  )}
                                </p>
                              )}
                              <div className="row pt-3 border-top">
                                <div className="col-md-12 d-flex align-items-center">
                                  <h6 className="review-author text-primary f-400 mb-0">
                                    {list?.user_name}
                                  </h6>
                                  <div className="inline_icon d-flex align-items-center">
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M10 0C4.48622 0 0 4.48622 0 10C0 15.5138 4.48622 20 10 20C15.5138 20 20 15.5138 20 10C20 4.48622 15.5138 0 10 0ZM15.589 7.36842L9.198 13.7093C8.82206 14.0852 8.22055 14.1103 7.81955 13.7343L4.43609 10.6516C4.03509 10.2757 4.01003 9.64912 4.3609 9.24812C4.73684 8.84712 5.36341 8.82206 5.76441 9.198L8.44612 11.6541L14.1604 5.93985C14.5614 5.53885 15.188 5.53885 15.589 5.93985C15.99 6.34085 15.99 6.96742 15.589 7.36842Z"
                                        fill="#344054"
                                      />
                                    </svg>
                                    <h6 className="text-grey-scale f-400 ms-2 mb-0">
                                      {/* {list.user_email} */}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      {next <
                        data?.GuideDetails?.rating_and_reviews?.length && (
                        <div className="pt-1 text-center">
                          <a
                            onClick={handleMoreImage}
                            className="review_link text-primary f-700 d-block"
                          >
                            View More
                          </a>
                        </div>
                      )}
                      <>
                        {data?.GuideDetails?.rating_and_reviews.length ===
                          0 && (
                          <h2 className="review_link text-primary f-700 d-block">
                            {!data?.GuideDetails.last_name
                              ? `${data?.GuideDetails.name}`
                              : `${data?.GuideDetails.name} ${data?.GuideDetails.last_name} `}{" "}
                            hasn't recieved any review yet
                          </h2>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingDetails;
