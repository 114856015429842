import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { postApi, putApi } from "../../../../../../../config/apiFunctions";
import {
  create_Itinerary,
  edit_Itinerary,
} from "../../../../../../../config/endpoints";
import { emailRegExp } from "../../../../../../../config/common/common";
import { ThreeDots } from "react-loader-spinner";

const EditItinerary = (props) => {
  const toast = useToast();
  const [btndisabled, setBtnDisable] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [text, setText] = useState(props.user?.itinerary?.descriptions);
  const access_token = useSelector(
    (state) => state.auth.SignupUser.access_token
  );
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(Schema),
  });
  //   useEffect(() => {
  //     setValue("itinerary_text", text);
  //   }, [text]);

  useEffect(() => {
    reset();
    setText(props.user?.itinerary.descriptions);
    setValue("itinerary_text", props.user?.itinerary.descriptions);
    trigger("itinerary_text");
  }, [props.show]);

  const onSubmit = (e) => {
    setSubmit(true);
    setBtnDisable(true);
    const obj = {
      booking_id: props.user.id,
      currency: "USD",
      itinerary_text: text,
      advance_price: e.advance_price,
      final_price: e.final_price,
      title: "Lorium Ipsum",
    };
    putApi(edit_Itinerary, obj, access_token)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setSubmit(false);
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
          props.onHide(false);
        }
      })
      .catch((err) => {
        setSubmit(false);
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
  };

  const handleChange = (e) => {
    if (e.target.value) {
      const numberInput = document.getElementById("numberInput");
      numberInput.addEventListener("wheel", (event) => {
        event.preventDefault();
      });
    }
  };

  return (
    <Modal
      {...props}
      size="mw-600"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-content p-30">
              <div className="modal_header position-relative">
                <h3 className="text-grey-500 f-700 mb-2">Edit Itinerary</h3>
                <p className="text-grey mb-2">
                  Journey Evolved: Your Transformed Itinerary Awaits
                </p>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={props.onHide}
                ></button>
              </div>
              <div className="modal-body p-0 mt-3">
                <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                  <label
                    className="small-text text-grey-scale mb-1 f-600"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    {...register("name")}
                    defaultValue={
                      props.user?.last_name
                        ? `${props.user?.first_name} ${props.user?.last_name}`
                        : props.user?.first_name
                    }
                  />
                  <div className="invalid-feedback">{errors.name?.message}</div>
                </div>
                {/* <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                  <label
                    className="small-text text-grey-scale mb-1 f-600"
                    htmlFor="email"
                  >
                    Email Address
                  </label>
                  <input
                    className={`form-control ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    type="email"
                    name="email"
                    placeholder="Enter email id"
                    {...register("email")}
                    defaultValue={props.user?.email}
                  />
                  <div className="invalid-feedback">
                    {errors.email?.message}
                  </div>
                </div> */}
                <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                  <label
                    className="small-text text-grey-scale mb-1 f-600"
                    htmlFor="advance_price"
                  >
                    Advance Amount
                  </label>
                  <input
                    id="numberInput"
                    className={`form-control ${
                      errors.advance_price ? "is-invalid" : ""
                    }`}
                    type="number"
                    name="advance_price"
                    placeholder="Enter your price"
                    {...register("advance_price", {
                      onChange: handleChange,
                    })}
                    defaultValue={props.user?.itinerary?.price}
                  />
                  <p className="extra-small-text text-grey-light mb-0">
                    Note: Prepayment will be required
                  </p>
                  <div className="invalid-feedback">
                    {errors.advance_price?.message}
                  </div>
                </div>
                <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                  <label
                    className="small-text text-grey-scale mb-1 f-600"
                    htmlFor="final_price"
                  >
                    Final Amount
                  </label>
                  <input
                    id="numberInput"
                    className={`form-control ${
                      errors.final_price ? "is-invalid" : ""
                    }`}
                    type="number"
                    name="final_price"
                    placeholder="Enter your price"
                    {...register("final_price", {
                      onChange: handleChange,
                    })}
                    defaultValue={props.user?.itinerary?.final_price}
                  />
                  {/* <p className="extra-small-text text-grey-light mb-0">
                    Note: Prepayment will be required
                  </p> */}
                  <div className="invalid-feedback">
                    {errors.final_price?.message}
                  </div>
                </div>
                <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                  <label
                    className="small-text text-grey-scale mb-1 f-600"
                    htmlFor="itinerary_text"
                  >
                    Create Itinerary
                  </label>
                  <div className=" ck w-100 h-50">
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        // plugins: [ Paragraph, Bold, Italic, Essentials ],
                        toolbar: [
                          "font",
                          "heading",
                          "bold",
                          "italic",
                          "undo",
                          "redo",
                          "blockQuote",
                          "bulletedlist",
                          "numberedlist",
                          "indent",
                          "outdent",
                          "underline",
                        ],
                      }}
                      data={text}
                      onChange={(event, editor) => {
                        setText(editor.getData());
                        setValue("value", text);
                        // trigger("itinerary_text")
                        trigger("itinerary_text", "jbjhbjb");
                      }}
                    />
                    <input
                      name="itinerary_text"
                      type="hidden"
                      {...register("itinerary_text")}
                      value={text}
                    />
                    <div className="text-danger" style={{ fontSize: ".875em" }}>
                      {errors.itinerary_text?.message}
                    </div>
                  </div>
                </div>
                <div className="date_fixed input-box date_selector mb-4"></div>
              </div>
              <div className="modal-footer border-0 p-0">
                <button
                  disabled={btndisabled}
                  className="btn-main btn-primary w-100 mw-100"
                  type="submit"
                >
                  {isSubmit === true ? (
                    <ThreeDots
                      height="20"
                      width="80"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "  Send"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default EditItinerary;
const Schema = Yup.object().shape({
  itinerary_text: Yup.string().required("This field is required"),
  advance_price: Yup.string()
    .required("This field is required")
    .max(5, "Please enter valid amount")
    .test("Is positive?", "Please enter valid amount", (value) => value > 0),
  final_price: Yup.string()
    .required("This field is required")
    .max(5, "Please enter valid amount")
    .test("Is positive?", "Please enter valid amount", (value) => value > 0),
  // email: Yup.string()
  //   .required("This field is required")
  //   .matches(emailRegExp, "Please enter valid email address"),
  name: Yup.string().required("This field is required"),
});
