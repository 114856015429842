import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getApi, postApi } from "../../../../../../../config/apiFunctions";
import {
  complete_Booking,
  itinerary_detail,
  process_payment_Booking,
} from "../../../../../../../config/endpoints";
import parse from "html-react-parser";
import EditItinerary from "./EditItinerary";
import { Oval, ThreeDots } from "react-loader-spinner";
import CancelBookingModal from "../../recievedBookingPage/section/modal/CancelBookingModal";
import { useToast } from "@chakra-ui/react";
import Select from "react-select";
import { bookingHistoryKey } from "../../../../../../../constant/configuration";
import { minDateCalculated } from "../../../../../../../constant/minimimDate";

const BookingDetail = () => {
  const { id } = useParams();
  const toast = useToast();
  const [modalShow, setModalShow] = useState(false);
  const [CancelShow, setCancelShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [btndisabled, setBtnDisable] = useState(false);
  const [processBtndisabled, setProcessBtnDisable] = useState(false);
  const [status, setStatus] = useState(null);
  const User = useSelector((state) => state.auth.SignupUser);
  const payment = useSelector((state) => state?.touristGuide?.payment?.payment);
  const [selectedOption, setSelectedOption] = useState(null);

  const itineraryDetails = () => {
    setLoading(true);
    getApi(
      `${itinerary_detail}?booking_id=${JSON.parse(id)}`,
      {},
      User.access_token
    ).then((res) => {
      setLoading(false);
      setStatus(res.data.data);
      if (
        [2, 4, 6, 8].includes(res.data.data?.status) &&
        [0].includes(res.data.data?.is_completed) &&
        res.data.data?.final_paid === 0
      ) {
        setSelectedOption({
          label: "on Going",
          value: "on Going",
        });
      } else if ([1, 3, 5, 7, 9].includes(res.data.data?.status)) {
        setSelectedOption({
          label: "Cancelled",
          value: "Cancelled",
        });
      } else if (
        [0].includes(res.data.data?.is_completed) &&
        [4].includes(res.data.data?.status) &&
        res.data.data?.final_paid !== 0
      ) {
        setSelectedOption({
          label: "Payment Processed",
          value: "Payment Processed",
        });
      } else if (
        [1].includes(res.data.data?.is_completed) &&
        [4].includes(res.data.data?.status)
      ) {
        setSelectedOption({
          label: "Completed",
          value: "Completed",
        });
      }
    });
  };

  useEffect(() => {
    itineraryDetails();
  }, [modalShow, payment, CancelShow]);

  const options = [
    { value: "On going", label: "On going", isDisabled: true },
    {
      value: "Cancelled",
      label: "Cancelled",
      isDisabled: [4].includes(status?.status) ? true : false,
    },
    {
      value: "Payment Processed",
      label: "Payment Processed",
      isDisabled:
        [0].includes(status?.is_completed) &&
        [4].includes(status?.status) &&
        status?.final_paid === 0
          ? false
          : true,
    },
    {
      value: "Completed",
      label: "Completed",
      isDisabled:
        [0].includes(status?.is_completed) &&
        [4].includes(status?.status) &&
        status?.final_paid !== 0
          ? false
          : true,
    },
  ];

  const handleChange = (e) => {
    setSelectedOption(e);
    if (e.value === "Cancelled") {
      setCancelShow(true);
    } else if (e.value === "Completed") {
      let idd = JSON.parse(id);
      postApi(complete_Booking, { booking_id: idd }, User.access_token).then(
        (res) => {
          itineraryDetails();
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
          });
        }
      );
    } else if (e.value === "Payment Processed") {
      setProcessBtnDisable(true);
      let idd = JSON.parse(id);
      postApi(
        process_payment_Booking,
        { booking_id: idd },
        User.access_token
      ).then((res) => {
        itineraryDetails();
        toast({
          title: res.data.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
        });
      });
    }
  };

  const handleClick = () => {
    setBtnDisable(true);
    let idd = JSON.parse(id);
    postApi(complete_Booking, { booking_id: idd }, User.access_token).then(
      (res) => {
        itineraryDetails();
        toast({
          title: res.data.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
        });
      }
    );
  };

  const handleProcessClick = () => {
    setProcessBtnDisable(true);
    let idd = JSON.parse(id);
    postApi(
      process_payment_Booking,
      { booking_id: idd },
      User.access_token
    ).then((res) => {
      itineraryDetails();
      toast({
        title: res.data.message,
        status: "success",
        duration: 30000,
        isClosable: true,
        position: "top-right",
      });
    });
  };

  useEffect(() => {
    if (selectedOption?.label === "on Going") {
      let div = document.querySelector(".css-13cymwt-control");
      div?.classList.add("going_status");
      div?.classList.remove("cancel_status");
      div?.classList.remove("complete_status");
    } else if (selectedOption?.label === "Completed") {
      let div = document.querySelector(".css-16xfy0z-control");
      div?.classList.add("complete_status");
      div?.classList.remove("cancel_status");
      div?.classList.remove("going_status");
    } else if (selectedOption?.label === "Payment Processed") {
      let div = document.querySelector(".css-16xfy0z-control");
      div?.classList.add("payment_processed");
      div?.classList.remove("cancel_status");
      div?.classList.remove("going_status");
    } else if (selectedOption?.label === "Cancelled") {
      let div = document.querySelector(".css-16xfy0z-control ");
      div?.classList.add("cancel_status");
      div?.classList.remove("complete_status");
      div?.classList.remove("going_status");
    }
  }, [status, selectedOption]);

  return (
    <div className="table_fixed booking_innerbox" data-aos="fade-up">
      {isLoading === true && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      {isLoading === false && status && (
        <div className="container_wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="booking_header p-20 border-bottom">
                <div className="row d-flex align-items-center justify-content-between">
                  <div className="col-md-6 col-7">
                    <h3 className="text-secondary mb-0 f-700">
                      Bookings Detail
                    </h3>
                    {/* {status?.status === 4 && status?.is_completed !== 0 && (
                      <p className=" text-primary mb-0">
                        Note: When you click complete booking or select
                        completed the final amount will be charged.
                      </p>
                    )} */}
                    {status?.status === 4 &&
                      status?.is_completed === 0 &&
                      status?.final_paid === 0 && (
                        <p className=" text-primary mb-0">
                          Note: When you click or select Process Final Payment
                          the final amount will be charged. Final payment must
                          be processed before{" "}
                          {minDateCalculated(
                            status?.dateDetails,
                            status?.finalPaymentDate
                          )}
                          .
                        </p>
                      )}
                  </div>
                  <div className="col-md-6 d-flex align-items-center justify-content-end">
                    {status?.status === 4 &&
                      status?.is_completed === 0 &&
                      status?.final_paid !== 0 &&
                      (btndisabled === true ? (
                        <button
                          className="btn-main btn-secondary w-100 me-2"
                          disabled={btndisabled}
                          type="submit"
                        >
                          <ThreeDots
                            height="20"
                            width="80"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </button>
                      ) : (
                        <button
                          className="btn-main btn-secondary w-100 me-2"
                          disabled={btndisabled}
                          type="button"
                          onClick={handleClick}
                        >
                          Complete Booking
                        </button>
                      ))}
                    {status?.status === 4 &&
                      status?.is_completed === 0 &&
                      status?.final_paid === 0 &&
                      (processBtndisabled === true ? (
                        <button
                          className="btn-main btn-primary w-100 me-2"
                          disabled={processBtndisabled}
                          type="submit"
                        >
                          <ThreeDots
                            height="20"
                            width="80"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </button>
                      ) : (
                        <button
                          className="btn-main btn-primary w-100 me-2"
                          disabled={processBtndisabled}
                          type="button"
                          onClick={handleProcessClick}
                        >
                          Process Final Payment
                        </button>
                      ))}
                    <div className="select_wrapper position-relative">
                      <Select
                        className="select_list"
                        defaultValue={selectedOption}
                        onChange={handleChange}
                        options={options}
                        openOnFocus={true}
                        autofocus={true}
                        isSearchable={false}
                        isDisabled={
                          status.is_cancelled === 1 ||
                          status.is_completed === 1 ||
                          (status.final_paid !== 0 && status.status === 4)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-table">
            <div className="row">
              <div className="col-md-12">
                <div className="booking_detail_active p-20">
                  <div className="row align-items-center border-bottom pb-4">
                    <div className="col-md-7">
                      <div>
                        <h4 className="text-secondary mb-3 f-600">
                          {status?.last_name
                            ? `${status?.first_name} ${status?.last_name}`
                            : status?.first_name}
                        </h4>
                        <div className="d-flex align-items-center justify-content-start f-column">
                          {/* <div className="d-flex align-items-center me-3">
                            <span className="location_iconbox me-2 bg-primary-fade d-flex align-items-center justify-content-center">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.167 17.0834H5.83366C3.33366 17.0834 1.66699 15.8334 1.66699 12.9167V7.08341C1.66699 4.16675 3.33366 2.91675 5.83366 2.91675H14.167C16.667 2.91675 18.3337 4.16675 18.3337 7.08341V12.9167C18.3337 15.8334 16.667 17.0834 14.167 17.0834Z"
                                  stroke="#1F74CA"
                                  strokeWidth="1.5"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14.1663 7.5L11.558 9.58333C10.6997 10.2667 9.29134 10.2667 8.433 9.58333L5.83301 7.5"
                                  stroke="#1F74CA"
                                  strokeWidth="1.5"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                            <p className="text-secondary mb-0 f-600">
                              {status?.email}
                            </p>
                          </div> */}
                          <div className="d-flex align-items-center">
                            <span className="location_iconbox me-2 bg-primary-fade d-flex align-items-center justify-content-center">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.0004 11.1917C11.4363 11.1917 12.6004 10.0276 12.6004 8.5917C12.6004 7.15576 11.4363 5.9917 10.0004 5.9917C8.56445 5.9917 7.40039 7.15576 7.40039 8.5917C7.40039 10.0276 8.56445 11.1917 10.0004 11.1917Z"
                                  stroke="#1F74CA"
                                  strokeWidth="1.5"
                                ></path>
                                <path
                                  d="M3.01675 7.07484C4.65842 -0.141827 15.3501 -0.133494 16.9834 7.08317C17.9417 11.3165 15.3084 14.8998 13.0001 17.1165C11.3251 18.7332 8.67508 18.7332 6.99175 17.1165C4.69175 14.8998 2.05842 11.3082 3.01675 7.07484Z"
                                  stroke="#1F74CA"
                                  strokeWidth="1.5"
                                ></path>
                              </svg>
                            </span>
                            <p className="text-secondary mb-0 f-600 text-capitalize">
                              {status?.country || "---"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 mt-md-0 mt-3">
                      <div className="m-flex-start d-flex align-items-center justify-content-end">
                        <span className="location_iconbox me-2 bg-primary d-flex align-items-center justify-content-center">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.66797 1.66626V4.16626"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.332 1.66626V4.16626"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M2.91797 7.57471H17.0846"
                              stroke="white"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M17.5 7.08293V14.1663C17.5 16.6663 16.25 18.3329 13.3333 18.3329H6.66667C3.75 18.3329 2.5 16.6663 2.5 14.1663V7.08293C2.5 4.58293 3.75 2.91626 6.66667 2.91626H13.3333C16.25 2.91626 17.5 4.58293 17.5 7.08293Z"
                              stroke="white"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.0762 11.4169H13.0836"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.0762 13.9169H13.0836"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99412 11.4169H10.0016"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.99412 13.9169H10.0016"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.90818 11.4169H6.91567"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.90818 13.9169H6.91567"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <h6 className="text-secondary mb-0 f-600">
                          {status?.dateDetails}
                        </h6>
                      </div>
                    </div>
                  </div>
                  {status?.itinerary !== null && (
                    <div className="booking_inner_content py-4">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <h3 className="f-600 mb-0 text-black-light">
                          Itinerary
                        </h3>
                        <button
                          onClick={() => setModalShow(true)}
                          className={
                            [1, 2, 3, 4, 5, 7, 8, 9].includes(status?.status)
                              ? "btn-main btn-square btn-primary disabled"
                              : "btn-main btn-square btn-primary"
                          }
                          data-bs-toggle="modal"
                          data-bs-target="#popup_modal"
                        >
                          <svg
                            className="me-2"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.2733 1.56143C11.6567 -0.0552402 10.0733 -0.0969068 8.41499 1.56143L7.40665 2.56976C7.32332 2.65309 7.28999 2.78643 7.32332 2.90309C7.95665 5.11143 9.72332 6.87809 11.9317 7.51143C11.965 7.51976 11.9983 7.52809 12.0317 7.52809C12.1233 7.52809 12.2067 7.49476 12.2733 7.42809L13.2733 6.41976C14.0983 5.60309 14.4983 4.81143 14.4983 4.01143C14.5067 3.18643 14.1067 2.38643 13.2733 1.56143Z"
                              fill="white"
                            />
                            <path
                              d="M10.4308 8.26979C10.1891 8.15312 9.9558 8.03646 9.7308 7.90312C9.54747 7.79479 9.37247 7.67812 9.19747 7.55312C9.0558 7.46146 8.88913 7.32812 8.7308 7.19479C8.71413 7.18646 8.6558 7.13646 8.58913 7.06979C8.31413 6.83646 8.0058 6.53646 7.7308 6.20312C7.7058 6.18646 7.66413 6.12812 7.6058 6.05312C7.52247 5.95312 7.3808 5.78646 7.2558 5.59479C7.1558 5.46979 7.03913 5.28646 6.9308 5.10312C6.79747 4.87812 6.6808 4.65312 6.56413 4.41979C6.41117 4.09201 5.98096 3.99464 5.72518 4.25041L1.03913 8.93646C0.9308 9.04479 0.830799 9.25312 0.805799 9.39479L0.355799 12.5865C0.272466 13.1531 0.4308 13.6865 0.7808 14.0448C1.0808 14.3365 1.49747 14.4948 1.94747 14.4948C2.04747 14.4948 2.14747 14.4865 2.24747 14.4698L5.44747 14.0198C5.59747 13.9948 5.8058 13.8948 5.9058 13.7865L10.5996 9.09268C10.8502 8.84202 10.7561 8.41074 10.4308 8.26979Z"
                              fill="white"
                            />
                          </svg>
                          <span>Edit Itinerary</span>
                        </button>
                      </div>

                      <div className="trip_content">
                        <div className="trip_pricebox d-flex align-items-center justify-content-between">
                          <div className="me-1 mb-2 mb-md-0">
                            <h5 className="text-secondary mb-1 f-700">
                              Advance Amount:{" "}
                              <span>{`$${status?.itinerary?.price}`}</span>
                            </h5>
                            {/* <p className="extra-small-text text-grey-light mb-0">
                              Note: Prepayment will be required
                            </p> */}
                          </div>
                          <h5 className="text-secondary mb-1 f-700 mb-2 mb-md-0">
                            Price:{" "}
                            <span className="text-primary">
                              ${status?.price}/per hour
                            </span>
                          </h5>
                        </div>
                        <div className="me-1 mb-2 mb-md-0">
                          <h5 className="text-secondary mb-1 f-700">
                            Final Amount:{" "}
                            <span>{`$${status?.itinerary?.final_price}`}</span>
                          </h5>
                          <p className="extra-small-text text-grey-light mb-0">
                            Note: Prepayment will be required
                          </p>
                        </div>
                        <p className="small-text text-secondary mb-3 mt-5">
                          {parse(`${status?.itinerary?.descriptions}`)}
                        </p>
                        <div></div>
                      </div>
                    </div>
                  )}
                  {status?.status === 1 && (
                    <div className="booking_inner_content py-4">
                      <div className="trip_content">
                        <h6 className="text-primary text-center mb-3 f-700 text-uppercase">
                          {/* {(status?.booking_track_histories[0]?.key).replaceAll(
                            "_",
                            " "
                          )} */}
                          {
                            bookingHistoryKey[
                              status?.booking_track_histories[0]?.key
                            ]
                          }
                        </h6>
                        <div>{status?.booking_track_histories[0]?.value}</div>
                      </div>
                    </div>
                  )}
                  {status?.booking_track_histories?.map((list, i) => {
                    return (
                      <div key={i} className="booking_inner_content py-4">
                        <hr></hr>
                        <div className="trip_content">
                          <h6 className="text-primary text-center mb-3 f-700 text-uppercase">
                            {/* {list.key.replaceAll("_", " ")} */}
                            {bookingHistoryKey[list.key]}
                          </h6>
                          <div>{list.value}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <EditItinerary
        show={modalShow}
        onHide={() => setModalShow(false)}
        user={status}
      />
      <CancelBookingModal
        show={CancelShow}
        id={status?.id}
        onHide={() => setCancelShow(false)}
      />
    </div>
  );
};

export default BookingDetail;
