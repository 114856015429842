import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { postApi } from "../../../../../../../config/apiFunctions";
import { guide_add_gallery } from "../../../../../../../config/endpoints";
import { ThreeDots } from "react-loader-spinner";

const AddPhotoModal = (props) => {
  const toast = useToast();
  const [isLoading, setLoading] = useState(false);
  const [btndisable, setBtnDisable] = useState(false);
  const [addImages, setAddImages] = useState([]);
  const [multipleImages, setMultipleImages] = useState([]);
  const access_token = useSelector(
    (state) => state.auth.SignupUser.access_token
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(Schema),
  });

  useEffect(() => {
    setAddImages([]);
    if (props.show === false) {
      setMultipleImages([]);
      reset();
    }
  }, [props.show]);

  const changeMultipleFiles = (e) => {
    if (e.target.files) {
      const imageArray = Array.from(e.target.files).map((file, index) => {
        const arr = URL.createObjectURL(file);
        setAddImages((prev) => [...prev, e.target.files[index]]);
        setMultipleImages((prevImages) => prevImages.concat(arr));
      });
    }
  };

  const onSubmit = (e) => {
    setLoading(true);
    setBtnDisable(true);
    const formData = new FormData();
    for (let i = 0; i < addImages.length; i++) {
      formData.append("gallery_img", addImages[i]);
    }
    formData.append("title", e.title);
    postApi(guide_add_gallery, formData, access_token)
      .then((res) => {
        if (res.status === 200) {
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
          setLoading(false);
          reset();
          setMultipleImages([]);
          setAddImages([]);
          props.onHide(false);
          // setBtnDisable(false);
          props.setdestinations(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        // setBtnDisable(false);
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
  };
  const handleRemove = (i, isNewIndexvalue = -1) => {
    if (isNewIndexvalue) {
      const _UPLOAD_IMG_INDEX = i - (multipleImages.length - addImages.length);
      setAddImages(
        addImages.filter((item, _index) => _index !== _UPLOAD_IMG_INDEX)
      );
    }
    setMultipleImages((current) => {
      return current.filter((item, index) => index !== i);
    });
  };
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className=" modal-dialog-scrollablse w-300">
        <div className="modal-content p-30">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal_header position-relative">
              <h3 className="text-secondary f-700 mb-2">Add Memories</h3>
              <p className="text-grey mb-2">
              A Visual Journey Through Your Gallery Images
               </p>
              <button
                type="button"
                className="btn-close"
                onClick={props.onHide}
              ></button>
            </div>
            <div className="modal-body p-0 mt-3">
              <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                <label
                  className="small-text text-secondary mb-1 f-600"
                  htmlFor="Title"
                >
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  placeholder="Add title"
                  {...register("title")}
                  className={`form-control ${errors.title ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">{errors.title?.message}</div>
              </div>
              <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                <label
                  className="small-text text-secondary mb-1 f-600"
                  htmlFor="images"
                >
                  Upload Photos
                </label>
                <div className="uploadedGallery w-100">
                  <div
                    className="fileUpload w-100 d-flex align-items-center justify-content-center flex-column"
                    id=""
                  >
                    <div className="gallery_uploadimg_inner show_uploaded_img">
                      <div>
                        <label
                          className="w-100 text-center d-flex flex-column align-items-center justify-content-center p-30"
                          htmlFor="galleryImageUpload"
                        >
                          <svg
                            className="mb-2"
                            width="26"
                            height="21"
                            viewBox="0 0 26 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.4878 6.95801C20.3103 2.03288 15.5535 -0.957466 10.8629 0.278856C7.19739 1.24504 4.53579 4.57156 4.26156 8.52935C1.65887 8.98003 -0.103108 11.5608 0.326105 14.2936C0.707655 16.7232 2.71257 18.5024 5.05759 18.4926H9.03777V16.821H5.05759C3.29905 16.821 1.87345 15.3241 1.87345 13.4776C1.87345 11.6311 3.29905 10.1342 5.05759 10.1342C5.49725 10.1342 5.85363 9.75998 5.85363 9.29833C5.84965 5.1437 9.05404 1.77231 13.0108 1.76818C16.4359 1.76457 19.3844 4.30712 20.0469 7.83559C20.1124 8.1879 20.3847 8.45705 20.7236 8.50427C22.8998 8.82968 24.4128 10.9459 24.1029 13.2309C23.8246 15.2828 22.1561 16.8106 20.1823 16.821H16.9981V18.4926H20.1823C23.2597 18.4829 25.747 15.8554 25.7376 12.624C25.7298 9.93415 23.9764 7.59633 21.4878 6.95801Z"
                              fill="#98A2B3"
                            />
                            <path
                              d="M12.4537 10.3764L9.26953 13.7198L10.3919 14.8983L12.2228 12.9842V21H13.8149V12.9842L15.6378 14.8983L16.7602 13.7198L13.5761 10.3764C13.2656 10.0523 12.7642 10.0523 12.4537 10.3764Z"
                              fill="#98A2B3"
                            />
                          </svg>
                          <span className="small-text f-500 lh-24">
                            <a className="text-primary">
                              Click to upload
                            </a>{" "}
                            or drag and drop JPG or PNG (maximum size 20MB)
                          </span>
                          {multipleImages.length === 0 ? (
                            <input
                              name="gallery_img"
                              id="galleryImageUpload"
                              type="file"
                              accept="image/*"
                              multiple
                              onChange={changeMultipleFiles}
                              className={` d-none
                             ${multipleImages.length == 0 ? "is-invalid" : ""}`}
                            />
                          ) : (
                            <input
                              name="gallery_img"
                              id="galleryImageUpload"
                              type="file"
                              accept="image/*"
                              multiple
                              {...register("gallery_img", {
                                onChange: changeMultipleFiles,
                              })}
                              className={` d-none
                               ${
                                 multipleImages.length == 0 ? "is-invalid" : ""
                               }`}
                            />
                          )}
                          <div className="invalid-feedback">
                            {errors.gallery_img?.message}
                          </div>
                        </label>
                      </div>
                      <div className="gallery_list">
                        <div className="d-flex flex-wrap align-items-center justify-content-start mt-3">
                          {multipleImages.map((images, i) => {
                            return (
                              <div key={i} className="gallery_img me-3">
                                <img
                                  className="img-fluid"
                                  src={images}
                                  alt="gallery-img"
                                />
                                <span
                                  className="remove_imgbtn"
                                  onClick={() =>
                                    handleRemove(
                                      i,
                                      images.split(":")[0] === "blob" ? 1 : 0
                                    )
                                  }
                                >
                                  <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 8 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M3.99935 7.33341C5.83268 7.33341 7.33268 5.83341 7.33268 4.00008C7.33268 2.16675 5.83268 0.666748 3.99935 0.666748C2.16602 0.666748 0.666016 2.16675 0.666016 4.00008C0.666016 5.83341 2.16602 7.33341 3.99935 7.33341Z"
                                      stroke="white"
                                      strokeWidth="0.7"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M3.05664 4.94331L4.94331 3.05664"
                                      stroke="white"
                                      strokeWidth="0.7"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M4.94331 4.94331L3.05664 3.05664"
                                      stroke="white"
                                      strokeWidth="0.7"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 p-0">
              <button
                disabled={btndisable}
                className="btn-main btn-primary w-100 mw-100"
                type="submit"
              >
                {isLoading ? (
                  <ThreeDots
                    height="25"
                    width="80"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  "Upload Photos"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AddPhotoModal;
const Schema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .min(2, "Too Short!")
    .max(56, "Maximum character length is 56")
    .matches(/^[A-Z]/, "First letter should be uppercase.")
    .matches(/^[a-zA-Z0-9_.-\s]*$/, "Please enter letters and numbers only"),
  gallery_img: Yup.mixed().test("name", "Please upload a File", (value) => {
    return value && value.name !== "";
  }),
});
