import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getApi } from "../../../../../../../../config/apiFunctions";
import { toprated_guide } from "../../../../../../../../config/endpoints";
import { TOPRATED_GUIDE } from "../../../../../../../../redux/slices/TouristGuideReducer/guideSlice";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../../../../../../config/pagination/Pagination";
import { Star } from "../../../../../../../../config/common/common";
import defaultImage from "../../../../../../../../assets/images/profile-placeholder1.jpg";
import no_result from "../../../../../../../../assets/images/no-result.png";
import { Oval } from "react-loader-spinner";
import BooknowModal from "../modals/BooknowModal";
import SendEnquiriesModal from "../../../../../../../../components/main/findTouristGuide/sections/modal";

const TableContent = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pageNumberLimit = 4;
  const [maxPageLimit, setMaxPageLimit] = useState(4);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [bookmodalShow, setbookModalShow] = useState(false);
  const [userId, setUserId] = useState(null);
  const [guideLocation, setGuideLocation] = useState(null);
  const [showModal, setModalShow] = useState(false);
  const User = useSelector((state) => state.auth.SignupUser);
  const dispatch = useDispatch();
  const guide_List = useSelector((state) => state.touristGuide.toprated_guide);

  const toprateddata = () => {
    props.setLoading(true);
    getApi(
      `${toprated_guide}?page_no=${props.currentPage}`,
      {},
      User.access_token
    ).then((res) => {
      props.setLoading(false);
      dispatch(TOPRATED_GUIDE(res.data.data));
      props.setShow(false);
    });
  };

  const [recordsPerPage] = useState(10);
  const nPages = guide_List?.counts
    ? Math.ceil(guide_List?.counts / recordsPerPage)
    : [];
  useEffect(() => {
    if (location.state !== null) {
      setUserId(location?.state.id);
      setbookModalShow(location?.state.show);
    }
  });
  useEffect(() => {
    if (props.squery === undefined || props.squery === null) {
      toprateddata();
    }
  }, [props.show, props.currentPage]);

  return (
    <>
      <div>
        <div className="row">
          <div className="col-md-12">
            <div
              className={
                props.isLoading === true
                  ? "overflow-table loader"
                  : "overflow-table"
              }
              style={
                guide_List?.counts > 10
                  ? { height: "calc(100vh - 274px)" }
                  : { height: "calc(100vh - 210px)" }
              }
            >
              {props.isLoading === true && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "auto",
                    marginBottom: "auto",
                    margin: "auto",
                  }}
                >
                  <Oval
                    height={80}
                    width={80}
                    color="#1f74ca"
                    wrapperStyle={{}}
                    wrapperclassName=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#1f74ca8f"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              )}
              {props.isLoading === false && (
                <>
                  {guide_List?.details ? (
                    <table
                      className="table custom-design mb-0 btn_spacer"
                      data-aos="fade-up"
                    >
                      <thead>
                        <tr className="border-bottom w-100">
                          <th className="text-secondary f-700">
                            Tourist Guides
                          </th>
                          {/* <th className="text-secondary f-700">Location</th> */}
                          <th className="text-secondary f-700">Country</th>
                          <th className="text-secondary f-700">State</th>
                          <th className="text-secondary f-700">City</th>
                          <th className="text-secondary f-700">Zipcode</th>
                          <th className="text-secondary f-700">Price</th>
                          <th className="text-secondary f-700">Ratings</th>
                          <th className="text-secondary f-700 text-center">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {guide_List?.details?.map((list, i) => {
                          return (
                            <tr key={i} className="border-bottom w-100">
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="message_profile d-flex align-items-center justify-content-between me-2">
                                    <img
                                      className="img-fluid"
                                      src={
                                        !list.user?.user_detail?.profile_picture
                                          ? defaultImage
                                          : list.user.user_detail
                                              .profile_picture
                                      }
                                      alt="tourist-guide"
                                    />
                                  </div>
                                  <h6 className="text-secondary mb-0 f-600">
                                    {!list.user.last_name
                                      ? `${list.user.name}`
                                      : `${list.user.name} ${list.user.last_name}`}
                                  </h6>
                                </div>
                              </td>
                              {/* <td>
                                <div className="d-flex align-items-center">
                                  <span className="location_iconbox me-2 bg-primary-fade d-flex align-items-center justify-content-center">
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M10.0004 11.1917C11.4363 11.1917 12.6004 10.0276 12.6004 8.5917C12.6004 7.15576 11.4363 5.9917 10.0004 5.9917C8.56445 5.9917 7.40039 7.15576 7.40039 8.5917C7.40039 10.0276 8.56445 11.1917 10.0004 11.1917Z"
                                        stroke="#1F74CA"
                                        strokeWidth="1.5"
                                      ></path>
                                      <path
                                        d="M3.01675 7.07484C4.65842 -0.141827 15.3501 -0.133494 16.9834 7.08317C17.9417 11.3165 15.3084 14.8998 13.0001 17.1165C11.3251 18.7332 8.67508 18.7332 6.99175 17.1165C4.69175 14.8998 2.05842 11.3082 3.01675 7.07484Z"
                                        stroke="#1F74CA"
                                        strokeWidth="1.5"
                                      ></path>
                                    </svg>
                                  </span>
                                  <p className="text-secondary mb-0 f-600">
                                    {list.user.country}
                                  </p>
                                </div>
                              </td> */}
                              <td>
                                <p className="text-secondary mb-0 f-600">
                                  {list.user.country || "---"}
                                </p>
                              </td>
                              <td>
                                <p className="text-secondary mb-0 f-600">
                                  {list.user.state || "---"}
                                </p>
                              </td>
                              <td>
                                <p className="text-secondary mb-0 f-600">
                                  {list.user.city || "---"}
                                </p>
                              </td>
                              <td>
                                <p className="text-secondary mb-0 f-600">
                                  {list.user.pincode}
                                </p>
                              </td>
                              <td>
                                <p className="text-secondary mb-0 f-600">
                                  {list.user.user_detail?.price
                                    ? `$${list?.user?.user_detail?.price}/hr`
                                    : 0}
                                </p>
                              </td>
                              <td>
                                {list.avg_ratings !== "0" ? (
                                  <Star stars={list.avg_ratings} />
                                ) : (
                                  "---"
                                )}
                              </td>
                              <td className="d-flex align-items-center justify-content-between">
                                <button
                                  //to={`/dashboard/findeguide/${list.user_id}`}
                                  onClick={() => {
                                    setbookModalShow(true);
                                    setUserId(list.user_id);
                                    setGuideLocation({
                                      country: list?.user?.country,
                                      state: list?.user?.state,
                                      city: list?.user?.city,
                                    });
                                  }}
                                  className="btn-main btn-primary w-100 mw-100 m-1"
                                >
                                  {/* Book Now */}
                                  {/* Connect
                                   */}
                                  {/* Sample Itinerary */}
                                  AI Itinerary
                                </button>
                                <Link
                                  to={`/dashboard/findeguide/${list.user_id}`}
                                  className="btn-main btn-primary-light w-100 mw-100 m-1"
                                >
                                  View Detail
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <section className="no-result">
                      <div className="container spacer-y pt-0">
                        <div className="row justify-content-center text-center">
                          <div className="col-sm-6 col-lg-4">
                            <img
                              src={no_result}
                              className="mb-3 mt-4"
                              alt=""
                              data-aos="fade-up"
                            />
                            <h2 className="primary__color mb-3 p-0">
                              No Results Found
                            </h2>
                            <p>
                              We couldn't find what you searched for. Try search
                              again.
                            </p>
                            <Link
                              onClick={() => {
                                setModalShow(true);
                              }}
                              className="btn btn-primary"
                            >
                              Send enquiry
                            </Link>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {props.isLoading === false && (
          <div className="row">
            <div className="col-md-12">
              {guide_List?.counts > 10 && (
                <div className="pagination-wrapperbox d-flex align-items-center justify-content-between">
                  <div className="data_count p-20 text-grey small-text f-400">
                    <span>
                      Showing
                      <span className="mx-1 f-500">
                        {10 * props.currentPage - 9}
                      </span>
                      to
                      <span className="mx-1 f-500">
                        {10 * props.currentPage -
                          (10 - guide_List?.details?.length)}
                      </span>
                      of
                      <span className="mx-1 f-500">{guide_List?.counts}</span>
                      entries
                    </span>
                  </div>
                  <Pagination
                    nPages={nPages}
                    currentPage={props.currentPage}
                    setCurrentPage={props.setCurrentPage}
                    pageNumberLimit={pageNumberLimit}
                    maxPageLimit={maxPageLimit}
                    minPageLimit={minPageLimit}
                    setMinPageLimit={setMinPageLimit}
                    setMaxPageLimit={setMaxPageLimit}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {bookmodalShow && (
        <BooknowModal
          list={guide_List}
          show={bookmodalShow}
          onHide={() => {
            localStorage.removeItem("itineraryDetail");
            setbookModalShow(false);
            navigate(location.state, { replace: true });
          }}
          id={userId}
          location={guideLocation}
        />
      )}
      <SendEnquiriesModal show={showModal} onHide={() => setModalShow(false)} />
    </>
  );
};

export default TableContent;
