import React, { useEffect, useState } from "react";
import TableContent from "./sections/TableContent";
import Compose from "./sections/Compose";
import { getApi } from "../../../../../../config/apiFunctions";
import {
  guide_inboxList,
  guide_sentList,
  traveler_inboxList,
  traveler_sentList,
} from "../../../../../../config/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { IoMicCircleOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";

const Message = ({ onLoadComponent, setOnLoadComponent }) => {
  const [active, setActive] = useState("0");
  const [show, setShow] = useState(false);
  const [voiceMsg, setVoiceMsg] = useState(false);
  const [showdetail, setShowDetail] = useState(false);
  const [showdreply, setShowreply] = useState(false);
  const [isLoading, setloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [defList, setDefList] = useState(false);
  const [list, setList] = useState(null);
  const toast = useToast();
  const location = useLocation();
  const User = useSelector((state) => state.auth.SignupUser);
  const chat_notification = useSelector(
    (state) => state.auth.chat_notification
  );

  useEffect(() => {
    if (
      chat_notification?.notification?.body?.includes("composed") ||
      showdetail === false
    ) {
      getList();
    }
  }, [chat_notification]);

  useEffect(() => {
    getList();
  }, [currentPage, active, searchText, defList]);

  const sQuery = (e) => {
    setTimeout(() => {
      setSearchText(e.target.value);
    }, 100);
  };
  const handlechange = (e) => {
    setShowDetail(false);
    setCurrentPage(1);
    setActive(e);
  };
  const getList = () => {
    if (active === "2") {
      setList(null);
      let api;
      if (User.role_name === "GUIDE") {
        api = guide_sentList;
      } else {
        api = traveler_sentList;
      }
      setloading(true);
      getApi(
        `${api}?page_no=${currentPage}&search_text=${searchText}  `,
        {},
        User.access_token
      )
        .then((res) => {
          setTimeout(() => {
            setList(res.data.data);
            setloading(false);
          }, 100);
        })
        .catch((err) => {
          setList();
          setloading(false);
          toast({
            title: err.response.data.message,
            status: "error",
            duration: 30000,
            isClosable: true,
            position: "top-right",
          });
        });
    } else if (active === "0") {
      setList(null);
      let api;
      if (User.role_name === "GUIDE") {
        api = guide_inboxList;
      } else {
        api = traveler_inboxList;
      }

      setloading(true);
      getApi(
        `${api}?page_no=${currentPage}&search_text=${searchText}  `,
        {},
        User.access_token
      )
        .then((res) => {
          setTimeout(() => {
            setList(res.data.data);
            setloading(false);
          }, 100);
        })
        .catch((err) => {
          setList();
          setloading(false);
          toast({
            title: err.response.data.message,
            status: "error",
            duration: 30000,
            isClosable: true,
            position: "top-right",
          });
        });
    }
    setShowDetail(false);
    setShowreply(false);
  };
  useEffect(() => {
    if (location?.state?.show) {
      setShow(true);
    }
  }, [location]);

  return (
    <div className="table_fixed booking_innerbox">
      <div className="row">
        <div className="col-md-12">
          <div className="p-20 border-bottom">
            <div className="position-relative">
              <input
                className="form-control"
                type="text"
                name="search"
                placeholder="Search by name, subjects"
                onChange={sQuery}
              />
              <button className="btn-search">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21.5C16.7467 21.5 21 17.2467 21 12C21 6.75329 16.7467 2.5 11.5 2.5C6.25329 2.5 2 6.75329 2 12C2 17.2467 6.25329 21.5 11.5 21.5Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M22 22.5L20 20.5"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container_wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="messages_inner" data-aos="fade-up">
              <div className="messages_inner_wrap d-flex align-items-start">
                <div
                  className="nav flex-column nav-pills p-15 p-sticky"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <div className="d-flex gap-2 align-items-center mb-3">
                    <button
                      className="btn-primary btn-main"
                      href=""
                      data-bs-toggle="modal"
                      data-bs-target="#mail_popup"
                      onClick={() => {
                        setShow(true);
                        handlechange("0");
                        setVoiceMsg(false);
                      }}
                    >
                      <svg
                        className="me-2"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.2733 1.56167C11.6567 -0.054996 10.0733 -0.0966626 8.41499 1.56167L7.40665 2.57C7.32332 2.65334 7.28999 2.78667 7.32332 2.90334C7.95665 5.11167 9.72332 6.87834 11.9317 7.51167C11.965 7.52 11.9983 7.52834 12.0317 7.52834C12.1233 7.52834 12.2067 7.49501 12.2733 7.42834L13.2733 6.42001C14.0983 5.60334 14.4983 4.81167 14.4983 4.01167C14.5067 3.18667 14.1067 2.38667 13.2733 1.56167Z"
                          fill="white"
                        />
                        <path
                          d="M10.4308 8.26979C10.1891 8.15312 9.9558 8.03646 9.7308 7.90312C9.54747 7.79479 9.37247 7.67812 9.19747 7.55312C9.0558 7.46146 8.88913 7.32812 8.7308 7.19479C8.71413 7.18646 8.6558 7.13646 8.58913 7.06979C8.31413 6.83646 8.0058 6.53646 7.7308 6.20312C7.7058 6.18646 7.66413 6.12812 7.6058 6.05312C7.52247 5.95312 7.3808 5.78646 7.2558 5.59479C7.1558 5.46979 7.03913 5.28646 6.9308 5.10312C6.79747 4.87812 6.6808 4.65312 6.56413 4.41979C6.41117 4.09201 5.98096 3.99464 5.72518 4.25041L1.03913 8.93646C0.9308 9.04479 0.830799 9.25312 0.805799 9.39479L0.355799 12.5865C0.272466 13.1531 0.4308 13.6865 0.7808 14.0448C1.0808 14.3365 1.49747 14.4948 1.94747 14.4948C2.04747 14.4948 2.14747 14.4865 2.24747 14.4698L5.44747 14.0198C5.59747 13.9948 5.8058 13.8948 5.9058 13.7865L10.5996 9.09268C10.8502 8.84202 10.7561 8.41074 10.4308 8.26979Z"
                          fill="white"
                        />
                      </svg>
                      <span>Compose</span>
                    </button>

                    <button
                      onClick={() => {
                        setShow(true);
                        setVoiceMsg(true);
                      }}
                    >
                      <IoMicCircleOutline size={30} />
                    </button>
                  </div>
                  {show && (
                    <Compose
                      show={show}
                      onHide={() => setShow(false)}
                      isOpenById={() => setShow(true)}
                      voiceMsgs={voiceMsg}
                    />
                  )}

                  <button
                    className={
                      active === "0"
                        ? "messages_tabbox d-flex align-items-center justify-content-start active"
                        : "messages_tabbox d-flex align-items-center justify-content-start"
                    }
                    onClick={() => handlechange("0")}
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                  >
                    <svg
                      className="me-2"
                      width="18"
                      height="18"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 15H5.92963C6.59834 15 7.2228 15.3342 7.59373 15.8906L8.40627 17.1094C8.7772 17.6658 9.40166 18 10.0704 18H13.9296C14.5983 18 15.2228 17.6658 15.5937 17.1094L16.4063 15.8906C16.7772 15.3342 17.4017 15 18.0704 15H21"
                        stroke="#323232"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 7C3 5.11438 3 4.17157 3.58579 3.58579C4.17157 3 5.11438 3 7 3H12H17C18.8856 3 19.8284 3 20.4142 3.58579C21 4.17157 21 5.11438 21 7V15V17C21 18.8856 21 19.8284 20.4142 20.4142C19.8284 21 18.8856 21 17 21H12H7C5.11438 21 4.17157 21 3.58579 20.4142C3 19.8284 3 18.8856 3 17V15V7Z"
                        stroke="#323232"
                        strokeWidth="2"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 13L12 8"
                        stroke="#323232"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.5 11L11.5 13V13C11.7761 13.2761 12.2239 13.2761 12.5 13V13L14.5 11"
                        stroke="#323232"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="f-600">Inbox</span>
                  </button>
                  <button
                    className={
                      active === "2"
                        ? "messages_tabbox d-flex align-items-center justify-content-start active"
                        : "messages_tabbox d-flex align-items-center justify-content-start"
                    }
                    onClick={() => handlechange("2")}
                    id="v-pills-messages-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-messages"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-messages"
                    aria-selected="false"
                  >
                    <svg
                      className="me-2"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.13245 3.17248L13.5525 6.38248C16.4325 7.82248 16.4325 10.1775 13.5525 11.6175L7.13245 14.8275C2.81245 16.9875 1.04995 15.2175 3.20995 10.905L3.86245 9.60748C4.02745 9.27748 4.02745 8.72998 3.86245 8.39998L3.20995 7.09498C1.04995 2.78248 2.81995 1.01248 7.13245 3.17248Z"
                        stroke="#344054"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.08008 9H8.13008"
                        stroke="#344054"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="f-600">Sent</span>
                  </button>
                </div>
                <TableContent
                  isLoading={isLoading}
                  list={list}
                  active={active}
                  showdetail={showdetail}
                  onShow={() => setShowDetail(true)}
                  onHide={() => setShowDetail(false)}
                  showdreply={showdreply}
                  Reply={() => setShowreply(true)}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  access_token={User.access_token}
                  setDefList={() => setDefList(!defList)}
                  setActive={() => setActive()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
