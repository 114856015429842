import React from "react";
import { Link, useNavigate } from "react-router-dom";
import any_question_img from "../../../../../assets/images/any_question_img.jpg";
import { useSelector } from "react-redux";
import parse from "html-react-parser";

const Banner = ({ dataList }) => {
  const navigate = useNavigate();

  const access_token = useSelector(
    (state) => state.auth.SignupUser.access_token
  );

  const handleClick = () => {
    const data = { name: "guide" };
    // console.log(data, "kjkjkjk");
    // navigate("/signup", {
    //   state: data,
    // });
    navigate("/signup", {
      state: { name: "guide" },
    });
  };
  return (
    <section className="hero">
      <div className="container spacer-y">
        <div className="row gy-4 gy-md-0 align-items-center justify-content-between">
          <div className="col-lg-6 col-md-12 order-2 order-md-1">
            <div className="banner_content">
              <h1 data-aos="fade-up">
                {dataList && dataList[0]?.value?.title}
              </h1>
              <div data-aos="fade-up" data-aos-delay="200">
                {parse(`${dataList && dataList[0]?.value?.description}`)}

                <div className="banner_btn">
                  <button
                    // onClick={() => {
                    //   navigate(access_token ? "/dashboard" : "/signup", {
                    //     replace: true,
                    //     state: { from: "guide" },
                    //   });
                    // }}
                    // onClick={() => {
                    //   navigate("/signup", { state : data });
                    // }}
                    onClick={handleClick}
                    // to={
                    //   (access_token ? "/dashboard" : "/signup",
                    //   {
                    //     replace: true,
                    //     state: { id: list?.travellerDetails?.id },
                    //   })
                    // }
                    target="_blank"
                    className="button-primary"
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-8 mx-auto order-1 order-md-2 mt-5 mt-lg-0">
            <svg
              viewBox="0 0 536 600"
              className="hero-image-masking"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <defs>
                <pattern id="img" x="0" y="0" width="1" height="1">
                  <image
                    className="h-100"
                    href={
                      dataList &&
                      dataList[0]?.value?.banner_image.upload_image_url
                    }
                  />
                </pattern>
              </defs>
              <mask id="path-1-inside-1_6774_58825" fill="white">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M170.323 0C206.48 0 240.005 11.2665 267.58 30.4804C295.155 11.2665 328.681 0 364.838 0C458.904 0 535.161 76.256 535.161 170.323C535.161 222.255 511.918 268.759 475.267 300C511.918 331.241 535.161 377.745 535.161 429.677C535.161 523.744 458.904 600 364.838 600C328.681 600 295.155 588.733 267.58 569.52C240.005 588.733 206.48 600 170.323 600C76.256 600 0 523.744 0 429.677C0 377.745 23.2426 331.241 59.8931 300C23.2426 268.759 0 222.255 0 170.323C0 76.256 76.256 0 170.323 0Z"
                />
              </mask>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M170.323 0C206.48 0 240.005 11.2665 267.58 30.4804C295.155 11.2665 328.681 0 364.838 0C458.904 0 535.161 76.256 535.161 170.323C535.161 222.255 511.918 268.759 475.267 300C511.918 331.241 535.161 377.745 535.161 429.677C535.161 523.744 458.904 600 364.838 600C328.681 600 295.155 588.733 267.58 569.52C240.005 588.733 206.48 600 170.323 600C76.256 600 0 523.744 0 429.677C0 377.745 23.2426 331.241 59.8931 300C23.2426 268.759 0 222.255 0 170.323C0 76.256 76.256 0 170.323 0Z"
                fill="url(#img)"
              />
              <path
                d="M267.58 30.4804L261.863 38.6851L267.58 42.6685L273.297 38.6851L267.58 30.4804ZM475.267 300L468.78 292.39L459.852 300L468.78 307.61L475.267 300ZM267.58 569.52L273.297 561.315L267.58 557.331L261.863 561.315L267.58 569.52ZM59.8931 300L66.3801 307.61L75.3084 300L66.3801 292.39L59.8931 300ZM273.297 22.2757C244.099 1.93092 208.589 -10 170.323 -10V10C204.37 10 235.911 20.6021 261.863 38.6851L273.297 22.2757ZM364.838 -10C326.571 -10 291.061 1.93092 261.863 22.2757L273.297 38.6851C299.249 20.6021 330.79 10 364.838 10V-10ZM545.161 170.323C545.161 70.7332 464.427 -10 364.838 -10V10C453.382 10 525.161 81.7789 525.161 170.323H545.161ZM481.755 307.61C520.539 274.551 545.161 225.307 545.161 170.323H525.161C525.161 219.204 503.297 262.968 468.78 292.39L481.755 307.61ZM468.78 307.61C503.297 337.032 525.161 380.796 525.161 429.677H545.161C545.161 374.693 520.539 325.449 481.755 292.39L468.78 307.61ZM525.161 429.677C525.161 518.221 453.382 590 364.838 590V610C464.427 610 545.161 529.267 545.161 429.677H525.161ZM364.838 590C330.79 590 299.249 579.398 273.297 561.315L261.863 577.724C291.061 598.069 326.571 610 364.838 610V590ZM170.323 610C208.589 610 244.099 598.069 273.297 577.724L261.863 561.315C235.911 579.398 204.37 590 170.323 590V610ZM-10 429.677C-10 529.267 70.7332 610 170.323 610V590C81.7789 590 10 518.221 10 429.677H-10ZM53.406 292.39C14.6218 325.449 -10 374.693 -10 429.677H10C10 380.796 31.8635 337.032 66.3801 307.61L53.406 292.39ZM-10 170.323C-10 225.307 14.6218 274.551 53.406 307.61L66.3801 292.39C31.8635 262.968 10 219.204 10 170.323H-10ZM170.323 -10C70.7332 -10 -10 70.7332 -10 170.323H10C10 81.7789 81.7789 10 170.323 10V-10Z"
                fill="white"
                mask="url(#path-1-inside-1_6774_58825)"
              />
            </svg>

            {/* <!-- <div className="mask-image tourist-hero-image">
                    <img src="images/popular_img1.png" className="w-100" alt="banner">
                </div> --> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
