import { Modal } from "react-bootstrap";
import { postApi } from "../../../../../../../../config/apiFunctions";
import { guide_cancel_booking } from "../../../../../../../../config/endpoints";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { GUIDE_RECEIVED_BOOKINGS } from "../../../../../../../../redux/slices/TouristGuideReducer/guideSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";

const CancelBookingModal = (props) => {
  
  const dispatch = useDispatch();
  const [btnDisable, setBtnDisable] = useState(false);
  const [isSubmit, setisSubmit] = useState(false)
  const navigate = useNavigate();
  const toast = useToast();
  const access_token = useSelector(
    (state) => state.auth.SignupUser.access_token
  );
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(Schema),
  });

  const canceledBooking = (e) => {
    setisSubmit(true)
    setBtnDisable(true);
    postApi(guide_cancel_booking, {booking_id: props.id , description:e.description}, access_token).then((res) => {
      setisSubmit(false)
        setBtnDisable(false);
        props.onHide(false)
        dispatch(GUIDE_RECEIVED_BOOKINGS({count:props.count-1}));
        navigate("/dashboard/booking-history");
      })
      .catch((err) => {
        setisSubmit(false)
        setBtnDisable(false);
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
        });
      });
  };
  return (
    // <!-- Modal Window -->
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div
        className={props.show === true ? "" : "modal fade"}
        id="cancel_trip"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
          <form onSubmit={handleSubmit(canceledBooking)}>
        <div className=" ">
          <div className="modal-content p-20">
            <div className="modal_header position-relative">
              <h3 className="text-grey-500 f-700 mb-2">Cancel Booking</h3>
              <p className="text-grey mb-2">
                Are you sure you want to cancel your request for this trip?
              </p>
              <div className="modal-body p-0">
                <div>
                    <div className="form" action=""> 
                        <div className="my-3">  
                            <div className="input-box mt-3">
                                <textarea name="description" id="" cols="30" rows="4" placeholder="Please write your reason here"
                               {...register("description")}
                        className={` form-control ${
                            errors.description ? "is-invalid" : ""
                          }`}
                                />
                                 <div className="invalid-feedback">
                        {errors.description?.message}
                      </div>
                               
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
              <button
                disabled={btnDisable}
                type="submit"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={props.onHide}
              ></button>
            </div>
            <div className="modal-footer border-0 p-0 pt-3">
              <a className="btn-main text-red" onClick={props.onHide}>
                Not Now
              </a>
              <button className="btn-main btn-red" type="submit">
              {isSubmit ? (
                          <ThreeDots
                            height="20"
                            width="80"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          "  Cancel Booking"
                        )}
              </button>
            </div>
          </div>
        </div>
        </form>
      </div>
    </Modal>
  );
};
export default CancelBookingModal;
const Schema = Yup.object().shape({
    description: Yup.string()
      .required("This field is required*").trim(" ")
      .max(150, "Please enter 150 characters only")
      .min(5, "Too Short!"),
    //   .matches(/^(?=.*[a-zA-Z])(?!.*[^ a-zA-Z]).*$/, "Please enter valid characters"),
 
  });