import React, { useEffect, useRef, useState } from "react";
import "./Toprated.css";
// import guider_img1 from "../../../../../assets/images/guider_img1.png";
// import guider_img2 from "../../../../../assets/images/guider_img2.png";
import imgnull from "../../../../../assets/images/profile-placeholder1.jpg";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { toprated_guide } from "../../../../../config/endpoints";
import { getApi } from "../../../../../config/apiFunctions";
import { Oval } from "react-loader-spinner";
import parse from "html-react-parser";
import { useSelector } from "react-redux";

const TopratedGuider = ({ dataList }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [list, seList] = useState(null);
  const User = useSelector((state) => state.auth.SignupUser);
  const toprateddata = () => {
    setLoading(true);
    getApi(`${toprated_guide}?page_no=${currentPage}&number_of_rows=6`).then(
      (res) => {
        setLoading(false);
        seList(res.data.data);
      }
    );
  };

  useEffect(() => {
    toprateddata();
  }, [currentPage]);

  var settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="primary__bg guider">
      <div className="container spacer-y top_guide_home">
        <div className="row heading align-items-center">
          <div className="col-9 text-white para">
            <h2
              className="m-0 text-white"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {dataList && dataList[0]?.value?.title}
            </h2>
            <div
              className="m-0 text-white"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              {parse(`${dataList && dataList[0]?.value?.description}`)}
            </div>
          </div>
          <div className="col-3 popular_right text-end">
            <Link
              to="/find-tourist-guide"
              className="text-white"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              See All
            </Link>
          </div>
        </div>
        {isLoading === true && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "auto",
              marginBottom: "auto",
              margin: "auto",
            }}
          >
            <Oval
              height={80}
              width={80}
              color="#1f74ca"
              wrapperStyle={{}}
              wrapperclassName=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#1f74ca8f"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
        {isLoading === false && (
          <div>
            <Slider className="guider_outer" {...settings}>
              {list?.details?.map((list, i) => {
                return (
                  <div key={i} className="guider_item">
                    <div className="guider_img">
                      <img
                        src={
                          list.user.user_detail.profile_picture
                            ? list.user.user_detail.profile_picture
                            : imgnull
                        }
                        alt="guider"
                      />
                    </div>
                    <div className="guider-content">
                      <h4 className="primary__color text-capitalize">
                        {list.user.last_name
                          ? `${list.user.name} ${list.user.last_name}`
                          : list.user.name}
                      </h4>
                      <p className="text-capitalize">{list.user.country}</p>
                      <div className="rated">
                        <div className="left">
                          <p>
                            $
                            {list.user.user_detail.price
                              ? list.user.user_detail.price
                              : "---"}
                            <span className="primary__color">/Per hour</span>
                          </p>
                        </div>
                        <div className="right">
                          <p>
                            <i className="fas fa-star"></i>
                            {list.avg_ratings}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="guider_btn">
                      {User.access_token && User.role_name === "TRAVELLER" ? (
                        <Link
                          to="/dashboard/findeguide"
                          state={{ id: list.user_id, show: true }}
                          className="button-secondary book-now-btn"
                        >
                          Book Now
                        </Link>
                      ) : (
                        <Link
                          to={`/guide_detailed_page/${list.user_id}`}
                          className="button-secondary book-now-btn"
                        >
                          Book Now
                        </Link>
                      )}
                      <Link
                        to={`/guide_detailed_page/${list.user_id}`}
                        className="book-now-btn "
                      >
                        Know More
                      </Link>
                    </div>
                  </div>
                );
              })}
              {/* <div className="guider_item">
              <div className="guider_img">
                <img src={guider_img2} alt="guider" />
              </div>
              <div className="guider-content">
                <h4 className="primary__color text-capitalize">Rory k.</h4>
                <p>
                  Samabe Bali Suites &amp; Villas, Deira Old Souk, Villa
                  Callisto...
                </p>
                <div className="rated">
                  <div className="left">
                    <p>
                      $40<span className="primary__color">/Per hour</span>
                    </p>
                  </div>
                  <div className="right">
                    <p>
                      <i className="fas fa-star"></i>4.0
                    </p>
                  </div>
                </div>
              </div>
              <div className="guider_btn">
                <a
                  href=""
                  className="button-secondary book-now-btn"
                >
                  Book Now
                </a>
                <a
                  href=""
                  className="book-now-btn "
                >
                  Know More
                </a>
              </div>
            </div>
            <div className="guider_item">
              <div className="guider_img">
                <img src={guider_img3} alt="guider" />
              </div>
              <div className="guider-content">
                <h4 className="primary__color text-capitalize">Adrew Milos</h4>
                <p>
                  Samabe Bali Suites &amp; Villas, Deira Old Souk, Villa
                  Callisto...
                </p>
                <div className="rated">
                  <div className="left">
                    <p>
                      $150<span className="primary__color">/Per day</span>
                    </p>
                  </div>
                  <div className="right">
                    <p>
                      <i className="fas fa-star"></i>5.0
                    </p>
                  </div>
                </div>
              </div>
              <div className="guider_btn">
                <a
                  href=""
                  className="button-secondary book-now-btn"
                >
                  Book Now
                </a>
                <a
                  href=""
                  className="book-now-btn"
                >
                  Know More
                </a>
              </div>
            </div>
            <div className="guider_item">
              <div className="guider_img">
                <img src={guider_img1} alt="guider" />
              </div>
              <div className="guider-content">
                <h4 className="primary__color text-capitalize">Daniel Scott</h4>
                <p>
                  Samabe Bali Suites &amp; Villas, Deira Old Souk, Villa
                  Callisto...
                </p>
                <div className="rated">
                  <div className="left">
                    <p>
                      $45<span className="primary__color">/Per hour</span>
                    </p>
                  </div>
                  <div className="right">
                    <p>
                      <i className="fas fa-star"></i>4.5
                    </p>
                  </div>
                </div>
              </div>
              <div className="guider_btn">
                <a   className="button-secondary button-secondary book-now-btn">
                  Book Now
                </a>
                <a   className="book-now-btn">
                  Know More
                </a>
              </div>
            </div>
            <div className="guider_item">
              <div className="guider_img">
                <img src={guider_img1} alt="guider" />
              </div>
              <div className="guider-content">
                <h4 className="primary__color text-capitalize">Daniel Scott</h4>
                <p>
                  Samabe Bali Suites &amp; Villas, Deira Old Souk, Villa
                  Callisto...
                </p>
                <div className="rated">
                  <div className="left">
                    <p>
                      $45<span className="primary__color">/Per hour</span>
                    </p>
                  </div>
                  <div className="right">
                    <p>
                      <i className="fas fa-star"></i>4.5
                    </p>
                  </div>
                </div>
              </div>
              <div className="guider_btn">
                <a
                  href=""
                  className="button-secondary book-now-btn"
                >
                  Book Now
                </a>
                <a
                  href=""
                  className="book-now-btn"
                >
                  Know More
                </a>
              </div>
            </div> */}
            </Slider>
          </div>
        )}
      </div>
    </section>
  );
};

export default TopratedGuider;
