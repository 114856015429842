import React, { useEffect, useState } from "react";
// import Header from "../../layouts/header/Header";
// import Footer from "../../layouts/footer/Footer";
import Explore from "./section/explore/Explore";
import AboutSection from "./section/about/AboutSection";
import PopularDestination from "./section/popularSlickSlider/PopularDestination";
import TopratedGuider from "./section/topratedGuider/TopratedGuider";
import ExploreWorld from "./section/exploringWorld/ExploreWorld";
import DiscoverTravelGuideline from "./section/discoverTravelGuideline/DiscoverTravelGuideline";
import WhatsInApp from "./section/whatsInApp/WhatsInApp";
import ContactUs from "./section/contactUs/ContactUs";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "../../../config/apiFunctions";
import { website_content } from "../../../config/endpoints";
import { HOMEPAGE } from "../../../redux/slices/content/ContentSlice";
import { Oval } from "react-loader-spinner";

const Tourist = () => {
  const dispatch = useDispatch();
  const [exploreData, setExploreData] = useState();
  const [aboutData, setAboutData] = useState();
  const [popularDestionationData, setPopularDestionationData] = useState();
  const [topGuideData, setTopGuideData] = useState();
  const [exploreWorldData, setExploreWorldData] = useState();
  const [discoverTravelGuidelineData, setDiscoverTravelGuidelineData] =
    useState();
  const [whatsInAppData, setWhatsInAppData] = useState();
  const [contactUsData, setContactUsData] = useState();
  const [loading, setLoading] = useState(false);
  const data = useSelector((state) => state.content.homePage);
  const User = useSelector((state) => state.auth.SignupUser);
  const token = User?.access_token;
  const waiting_status = useSelector((state) => state.auth.waiting_status);

  console.log(token, "token");

  const homePageData = () => {
    setLoading(true);
    getApi(`${website_content}?key=HOME_PAGE`)
      .then((res) => {
        dispatch(HOMEPAGE(res?.data?.data));
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    homePageData();
  }, []);

  useEffect(() => {
    if (data) {
      const getExploredata = data?.filter((e) => e.section === "INTRO");
      setExploreData(getExploredata);

      const getAboutData = data?.filter((e) => e.section === "WHAT_WE_DO");
      setAboutData(getAboutData);

      const getPopularDestionationData = data?.filter(
        (e) => e.section === "PLACES"
      );
      setPopularDestionationData(getPopularDestionationData);

      const getTopGuideData = data?.filter(
        (e) => e.section === "PROFESSIONALS"
      );
      setTopGuideData(getTopGuideData);

      const getExploreWorldData = data?.filter((e) => e.section === "WHY_US");
      setExploreWorldData(getExploreWorldData);

      const getDiscoverTravelGuidelineData = data?.filter(
        (e) => e.section === "SERVICES"
      );
      setDiscoverTravelGuidelineData(getDiscoverTravelGuidelineData);

      const getWhatsInAppData = data?.filter((e) => e.section === "OUR_APP");
      setWhatsInAppData(getWhatsInAppData);

      const getContactUsData = data?.filter((e) => e.section === "REACH_US");
      setContactUsData(getContactUsData);
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            margin: "auto",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          <Explore data={exploreData} />
          <AboutSection data={aboutData} />
          <PopularDestination dataList={popularDestionationData} />
          {token && waiting_status === false && (
            <TopratedGuider dataList={topGuideData} />
          )}
          <ExploreWorld dataList={exploreWorldData} />
          <DiscoverTravelGuideline dataList={discoverTravelGuidelineData} />
          <WhatsInApp />
          <ContactUs />{" "}
        </>
      )}
    </>
  );
};
export default Tourist;
