import React, { useState } from "react";
import Navbar from "../navbar/Navbar";
import Sidenav from "../sidenav/Sidenav";
import Dashboardroutes from "../../../routes/dashboardRoutes";
import { useSelector } from "react-redux";

const Layout = () => {
  const [togglesidenav, setToggleSidenav] = useState(false);
  const User = useSelector((state) => state.auth.SignupUser);
  return (
    <>
      <div className=""></div>
      <section className="dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Sidenav
                toggle={togglesidenav}
                Hide={() => setToggleSidenav(false)}
              />
              <div className="dashboard_page_content">
                <Navbar
                  togglesidenav={togglesidenav}
                  show={() => setToggleSidenav(true)}
                />

                <div
                  className="dashboard_innerbox"
                  onClick={() => setToggleSidenav(false)}
                >
                  <div id="snackbar" className="">
                    <div className="logout-notification">
                      <h2>
                        {/* You're log out */}
                        You have successfully logged out
                      </h2>
                      <p>Your session has expired</p>
                    </div>
                  </div>
                  <Dashboardroutes User={User} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Layout;
