import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import star_icon from "../../../../../assets/images/star-icon.svg";
import WriteReview from "../writeReview/WriteReview";
import { useSelector } from "react-redux";
import { Star } from "../../../../../config/common/common";

const RatingReview = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const data = useSelector((state) => state.touristGuide.guide_detailed_page);
  useEffect(() => {
    // rating progressbar
    let ratingBar = document.querySelectorAll(".star-progress-bar");
    ratingBar.forEach(function (el) {
      let fillSize = el.getAttribute("data-percent");
      el.children[0].style.width = `${fillSize}%`;
      // el.nextElementSibling.innerText = `${fillSize}%`;
    });

    // review trigger
  }, [data]);

  return (
    <section className="rating-review">
      <div className="container spacer-y pt-0">
        <div className="row gy-3 gy-md-0 justify-content-between align-items-center mb-40">
          <div className="col-md-6">
            <h2 className="p-0 m-0">Rating &amp; Reviews</h2>
          </div>
          {/* <div className="col-md-6 text-md-end">
            <Link
              //to={<WriteReview />}
              className="button-primary review-trigger create-profile-btn"
              onClick={() => setModalShow(true)}
            >
              Write A Review
            </Link>
            <WriteReview show={modalShow} onHide={() => setModalShow(false)} id={data?.GuideDetails?.id} />
          </div> */}
        </div>
        <div className="review-wrapper">
          <div className="row gx-lg-4 gy-3">
            <div
              className="col-lg-2 col-md-3"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="rating-count mb-3">
                <span className="h2 color__grey m-0 p-0">
                  {data?.AvgRatings}
                </span>
                <img src={star_icon} alt="" />
              </div>
              <p>
                {data?.Ratings} Ratings &amp; {data?.Reviews} Reviews
              </p>
            </div>
            <div className="col-lg-6 col-md-9">
              <ul
                className="list-unstyled m-0 star-progress-wrap"
                data-aos="fade-up"
                data-aos-delay="250"
              >
                <li className="status-good">
                  <div className="star-title">5 star</div>
                  <div
                    className="star-progress-bar"
                    data-percent={data?.percentage5Ratings}
                  >
                    <div
                      className="progress-base-fill"
                      defaultValue={data?.percentage5Ratings?.toString()}
                    ></div>
                  </div>
                  {/* <div className="star-percent  ">
                   {data?.percentage5Ratings?.toString()}
                  </div> */}
                  {data?.Star5Ratings}
                </li>
                <li className="status-good">
                  <div className="star-title color__grey">4 star</div>
                  <div
                    className="star-progress-bar"
                    data-percent={data?.percentage4Ratings?.toString()}
                  >
                    <div
                      className="progress-base-fill"
                      data-width={data?.percentage4Ratings?.toString()}
                    ></div>
                  </div>
                  {/* <div className="star-percent d-none">
                    {data?.percentage4Ratings?.toString()}
                  </div> */}
                  {data?.Star4Ratings}
                </li>
                <li className="status-good">
                  <div className="star-title">3 star</div>
                  <div
                    className="star-progress-bar"
                    data-percent={data?.percentage3Ratings?.toString()}
                  >
                    <div
                      className="progress-base-fill"
                      defaultValue={data?.percentage3Ratings?.toString()}
                    ></div>
                  </div>
                  {/* <div className="star-percent d-none">
                    {data?.percentage3Ratings?.toString()}
                  </div> */}
                  {data?.Star3Ratings}
                </li>
                <li className="status-fine">
                  <div className="star-title">2 star</div>
                  <div
                    className="star-progress-bar"
                    data-percent={data?.percentage2Ratings?.toString()}
                  >
                    <div
                      className="progress-base-fill"
                      data-width={data?.percentage2Ratings?.toString()}
                    ></div>
                  </div>
                  {/* <div className="star-percent d-none">
                    {data?.percentage2Ratings?.toString()}
                  </div> */}
                  {data?.Star2Ratings}
                </li>
                <li className="status-bad">
                  <div className="star-title">1 star</div>
                  <div
                    className="star-progress-bar"
                    data-percent={data?.percentage1Ratings?.toString()}
                  >
                    <div
                      className="progress-base-fill"
                      data-width={data?.percentage1Ratings?.toString()}
                    ></div>
                  </div>
                  {/* <div className="star-percent d-none">
                    {data?.percentage1Ratings?.toString()}
                  </div> */}
                  {data?.Star1Ratings}
                </li>
              </ul>
            </div>
          </div>

          {data?.GuideDetails?.rating_and_reviews?.map((list) => {
            return (
              <div
                key={list.user_email}
                className="user-reviews"
                data-aos="fade-up"
              >
                <Star stars={list.ratings} />
                <h6>{list.user_name}</h6>
                <p>{list.review_message}</p>
                <div className="review-user-info">
                  <div className="review-username primary__color">
                    {list.user_name}
                  </div>
                  <div className="review-mail">{/* {list.user_email} */}</div>
                </div>
              </div>
            );
          })}

          {data?.moreReviews === 0 ? (
            <></>
          ) : (
            <div className="user-reviews all-review" data-aos="fade-up">
              <a>
                <p className="mb-0 primary__color">
                  All {data?.moreReviews} Reviews
                </p>
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default RatingReview;
