import React, { useEffect } from "react";
import mail_sent from "../../../assets/images/mail-sent.svg";
import "../LoginSignup.css";
import parse from "html-react-parser";

const EmailVerificationPopup = (props) => {
  return (
    <div
      className={
        props.show === true ? "mail-sent-popup active" : "mail-sent-popup"
      }
    >
      <div className="main-sent-wrapper zindex-up">
      <button
            type="button"
            className="btn-close"
            onClick={props.onHide}
          ></button>
        <img src={mail_sent} alt="" />
        {props?.msgType === 'html' ? <div>{parse(props.message)}</div>: <p>{`${props.message || 'You have received a verification link in your email. Check it out'}`}</p>}
      </div>
      <div className="popup-overlay" onClick={props.onHide}></div>
    </div>
  );
};

export default EmailVerificationPopup;
