import React from "react";
import "./GreatThings.css";
// import popular_img1 from "../../../../../assets/images/popular_img1.png";
// import popular_img3 from "../../../../../assets/images/popular_img3.png";
import parse from "html-react-parser";

const GreatThings = ({ dataList }) => {
  return (
    <section className="about_section primary__bg">
      <div className="container spacer-y">
        <div className="row gx-lg-5 gy-4">
          <div className="col-lg-6 col-md-8 mx-auto">
            <div className="left-small-right-big col-sticky">
              <img
                src={
                  dataList &&
                  dataList[0]?.value?.banner_image1?.upload_image_url
                }
                alt="banner"
                data-aos="fade-up"
              />
              <img
                src={
                  dataList &&
                  dataList[0]?.value?.banner_image2?.upload_image_url
                }
                alt="banner"
                data-aos="fade-up"
                data-aos-delay="300"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="about_content">
              <h2 className="text-white m-0" data-aos="fade-up">
                {dataList && dataList[0]?.value?.title}
              </h2>
              <div className="" data-aos="fade-up">
                <h5 className="text-white m-0">
                  {dataList && dataList[0]?.value?.guideTitle}
                </h5>
                <div className="paragraph_content mt-2 text-white">
                  {parse(`${dataList && dataList[0]?.value?.guideDescription}`)}
                </div>
              </div>
              <div className="" data-aos="fade-up">
                <h5 className="text-white m-0">
                  {dataList && dataList[0]?.value?.travelersTitle}
                </h5>
                <div className="paragraph_content mb-0 mt-2 text-white">
                  {parse(
                    `${dataList && dataList[0]?.value?.travelersDescription}`
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GreatThings;
