import React from "react";
import AddPhotoModal from "./AddPhotoModal";

const GalleryHead = (props) => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="booking_header p-20 border-bottom">
          <div className="row d-flex align-items-center justify-content-between">
            <div className="col-md-4 col-6">
              <h3 className="text-secondary mb-0 f-700">Gallery</h3>
            </div>
            <div className="col-md-8 col-6">
              <button
                onClick={() => setModalShow(true)}
                className="btn-main btn-primary f-500 ms-auto"
                data-bs-toggle="modal"
                data-bs-target="#popup_modal"
              >
                Add Memories
              </button>
            </div>
          </div>
        </div>
      </div>
      <AddPhotoModal
        setdestinations={props.setdestinations}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default GalleryHead;
