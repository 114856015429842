import React from "react";
// import popular_img1 from "../../../../../assets/images/popular_img1.png";
// import popular_img3 from "../../../../../assets/images/popular_img3.png";
import "../exploreWorld/ExploreWorld.css";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

const ExploreWorld = ({ dataList }) => {
  return (
    <section className="hero">
      <div className="container spacer-y">
        <div className="row gy-3 gy-md-0 align-items-center">
          <div className="col-lg-6 col-md-12 order-2 order-md-1">
            <div className="hero_content">
              <h1 className="m-0" data-aos="fade-up">
                {dataList && dataList[0]?.value?.title}
              </h1>
              <div data-aos="fade-up" data-aos-delay="200">
                {parse(`${dataList && dataList[0]?.value?.description}`)}
                <div className="banner_btn">
                  <Link to="/contact-us" className="button-primary">
                    Get In Touch
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-8 mx-auto order-1 order-md-2 mt-5 mt-lg-0">
            <div className="hero-image left-big-right-small">
              <img
                src={
                  dataList && dataList[0]?.value?.banner_image1.upload_image_url
                }
                alt="banner"
                data-aos="fade-up"
              />
              <img
                src={
                  dataList && dataList[0]?.value?.banner_image2.upload_image_url
                }
                alt="banner"
                data-aos="fade-up"
                data-aos-delay="300"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ExploreWorld;
