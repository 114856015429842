import React from "react";
// import popular_img1 from "../../../../../assets/images/popular_img1.png";
// import popular_img3 from "../../../../../assets/images/popular_img3.png";
import parse from "html-react-parser";

const TravelingTo = ({ dataList }) => {
  return (
    <section className="about_section primary__bg">
      <div className="container spacer-y">
        <div className="row gy-4 gy-md-0 gx-lg-5">
          <div className="col-lg-6 col-md-8 mx-auto">
            <div className="left-big-right-small">
              <img
                src={
                  dataList && dataList[0]?.value?.banner_image1.upload_image_url
                }
                alt="banner"
                data-aos="fade-up"
              />
              <img
                src={
                  dataList && dataList[0]?.value?.banner_image2.upload_image_url
                }
                alt="banner"
                data-aos="fade-up"
                data-aos-delay="200"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 mt-5 mt-lg-0">
            <div className="about_content">
              <h2 className="text-white m-0" data-aos="fade-up">
                {dataList && dataList[0]?.value?.title}
              </h2>
              <div
                className="paragraph_content text-white"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {parse(`${dataList && dataList[0]?.value?.description}`)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TravelingTo;
