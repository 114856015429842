import React, { useEffect, useState } from "react";
import "./index.css";
import Header from "./section/JoinLocaliteHeader/Header";
import JoinLocalitesBanner from "./section/Banner/JoinLocalitesBanner";
import JoinSiesta from "./section/JoinSiesta/JoinSiesta";
import { getApi } from "../../../config/apiFunctions";
import { website_content } from "../../../config/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { LANDING_PAGE } from "../../../redux/slices/content/ContentSlice";
import { Oval } from "react-loader-spinner";
import LandingBooking from "./section/Booking/LandingBooking";
import GetStarted from "./section/GetStarted/GetStarted";
import JoinUs from "./section/JoinUs/JoinUs";

export default function JoinLocalites() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.content.landingPage);
  const [loading, setLoading] = useState(false);
  const [headerLogo, setHeaderLogo] = useState();
  const [bannerData, setBannerData] = useState();
  const [joinData, setJoinData] = useState();
  const [exploreOneData, setExpolreOneData] = useState();
  const [exploreTwoData, setExpolreTwoData] = useState();
  const [exploreThreeData, setExpolreThreeData] = useState();
  const [exploreFourData, setExpolreFourData] = useState();
  const [bookingTitleData, setBookingTitleData] = useState();
  const [platformOneData, setPlatformOneData] = useState();
  const [platformTwoData, setPlatformTwoData] = useState();
  const [platformThreeData, setPlatformThreeData] = useState();
  const [getStartedData, setGetStartedData] = useState();
  const [opportunityData, setOpportunityData] = useState();

  const landingPageData = () => {
    setLoading(true);
    getApi(`${website_content}?key=LANDING_PAGE`)
      .then((res) => {
        dispatch(LANDING_PAGE(res?.data?.data));
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    landingPageData();
  }, []);

  useEffect(() => {
    if (data) {
      const getHeaderlogo = data?.filter((e) => e.section === "LOGO");
      setHeaderLogo(getHeaderlogo);

      const getMainBannerData = data?.filter(
        (e) => e.section === "MAIN_BANNER"
      );
      setBannerData(getMainBannerData[0]?.value);

      const getJoinSiestaData = data?.filter(
        (e) => e.section === "JOIN_SIESTA"
      );
      setJoinData(getJoinSiestaData[0]?.value);

      const getExploreOneData = data?.filter(
        (e) => e.section === "EXPLORE_STEP_ONE"
      );
      setExpolreOneData(getExploreOneData[0]?.value);

      const getExploreTwoData = data?.filter(
        (e) => e.section === "EXPLORE_STEP_TWO"
      );
      setExpolreTwoData(getExploreTwoData[0]?.value);

      const getExploreThreeData = data?.filter(
        (e) => e.section === "EXPLORE_STEP_THREE"
      );
      setExpolreThreeData(getExploreThreeData[0]?.value);

      const getExploreFourData = data?.filter(
        (e) => e.section === "EXPLORE_STEP_FOUR"
      );
      setExpolreFourData(getExploreFourData[0]?.value);

      const getBookingTitleData = data?.filter(
        (e) => e.section === "MISS_OPPORTUNITY_TITLE"
      );
      setBookingTitleData(getBookingTitleData[0]?.value);

      const getPlatformOneData = data?.filter(
        (e) => e.section === "PLATFORM_STEP_ONE"
      );
      setPlatformOneData(getPlatformOneData[0]?.value);

      const getPlatformTwoData = data?.filter(
        (e) => e.section === "PLATFORM_STEP_TWO"
      );
      setPlatformTwoData(getPlatformTwoData[0]?.value);

      const getPlatformThreeData = data?.filter(
        (e) => e.section === "PLATFORM_STEP_THREE"
      );
      setPlatformThreeData(getPlatformThreeData[0]?.value);

      const getStartedData = data?.filter(
        (e) => e.section === "GET_STARTED"
      );
      setGetStartedData(getStartedData[0]?.value);
      
      const getOpportunityData = data?.filter(
        (e) => e.section === "MISS_OPPORTUNITY"
      );
      setOpportunityData(getOpportunityData[0]?.value);
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            margin: "auto",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          {/* <Header logo={headerLogo} /> */}
          <JoinLocalitesBanner data={bannerData} />
          <JoinSiesta
            joinData={joinData}
            exploreOneData={exploreOneData}
            exploreTwoData={exploreTwoData}
            exploreThreeData={exploreThreeData}
            exploreFourData={exploreFourData}
          />
          <LandingBooking
            bookingTitleData={bookingTitleData}
            platformOneData={platformOneData}
            platformTwoData={platformTwoData}
            platformThreeData={platformThreeData}
          />
          <GetStarted getStartedData={getStartedData} />
          <JoinUs opportunityData={opportunityData}/>
        </>
      )}
    </>
  );
}
