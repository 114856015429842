import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../components/Home/Home";
import Traveler from "../components/main/travelerPage/Traveler";
import TouristGuide from "../components/main/touristGuidePage/TouristGuide";
import FindTouristGuide from "../components/main/findTouristGuide/FindTouristGuide";
import ContactUs from "../components/main/contactUs/ContactUs";
import Blog from "../components/main/blog/Blog";
import React from "react";
import AboutUs from "../components/main/aboutUs/AboutUs";
import Login from "../components/auth/login/Login";
import SignUp from "../components/auth/signup/SignUp";
import EmailVerify from "../components/auth/emailverify/EmailVerify";
import GuideDetailedPage from "../components/main/touristGuideDetailsPage/sections/GuideDetailedPage";
import WriteReview from "../components/main/touristGuideDetailsPage/sections/writeReview/WriteReview";
import DetailedBlog from "../components/main/blog/detailedBlogPage/DetailedBlog";
import CreateGuideProfiles from "../components/auth/createProfile/guide/CreateGuideProfile";
import CreateTravelerProfile from "../components/auth/createProfile/traveler/CreateTravelerProfile";
import NotFound from "../components/main/findTouristGuide/sections/nodatafound/NotFound";
import ErrorPage from "../components/main/ErrorPage";
import { useSelector } from "react-redux";
import Terms from "../dashboard/sections/Terms";
import Privacy from "../dashboard/sections/Privacy";
import WaitingPage from "../components/main/waitingPage/WaitingPage";
import TravellerWaitingListForm from "../components/auth/signup/TravellerWaitingListForm";
import JoinLocalites from "../components/main/joinLocalites/JoinLocalites";

const Router = () => {
  const User = useSelector((state) => state.auth.SignupUser);
  const [isLoggedIn, setisLoggedIn] = useState(User?.access_token);
  useEffect(() => {
    setisLoggedIn(User?.access_token || null);
  }, [User]);
  return (
    <React.Fragment>
      <Routes>
        <Route path="/waiting-list" element={<WaitingPage />} />
        <Route path="/join-localites" element={<JoinLocalites />} />
        {User.access_token ? (
          <>
            {User.role_name === "GUIDE" ? (
              <Route path="*" element={<TouristGuide />} />
            ) : (
              <Route path="*" element={<Home />} />
            )}
          </>
        ) : (
          <Route path="*" element={<Login />} />
        )}
        {(User.role_name === "TRAVELLER" || !User.access_token) && (
          <>
            <Route exact path="/" element={<Home />} />
            <Route path="/traveler" element={<Traveler />} />
          </>
        )}
        {!User.access_token && (
          <>
            <Route path="/tourist-guide" element={<TouristGuide />} />
            <Route path="/traveler" element={<Traveler />} />
            <Route path="/traveller-waiting-list" element={<TravellerWaitingListForm />} />
          </>
        )}

        {User.role_name === "GUIDE" && (
          <>
            <Route exact path="/" element={<TouristGuide />} />
            <Route path="/" element={<TouristGuide />} />
          </>
        )}
        {/* <Route path="/tourist-guide" element={<TouristGuide />} /> */}
        {User.role_name === "TRAVELLER" && (
          <Route path="/find-tourist-guide" element={<FindTouristGuide />} />
        )}
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/blog-detail/:slug" element={<DetailedBlog />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/app/terms" element={<Terms />} />
        <Route path="/app/privacy" element={<Privacy />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route
          path="/guide_detailed_page/:id"
          element={<GuideDetailedPage />}
        />
        <Route
          path="/create_guide_profiles"
          element={<CreateGuideProfiles />}
        />
        <Route
          path="/create_traveler_profile"
          element={<CreateTravelerProfile />}
        />
        <Route path="/email/verify/:token" element={<EmailVerify />} />
        {!isLoggedIn ? (
          <>
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/signup" element={<SignUp />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/login/:token" element={<Login />} />
            <Route
              path="/create_guide_profiles"
              element={<CreateGuideProfiles />}
            />
            <Route path="/searcherror" element={<NotFound />} />
            <Route
              path="/guide_detailed_page/:id"
              element={<GuideDetailedPage />}
            />
            <Route
              path="/create_traveler_profile"
              element={<CreateTravelerProfile />}
            />

            <Route path="/writeReview" element={<WriteReview />} />
            <Route path="/find-tourist-guide" element={<FindTouristGuide />} />
          </>
        ) : (
          <>
            {" "}
            <Route path="*" element={<ErrorPage />} />
          </>
        )}
      </Routes>
    </React.Fragment>
  );
};

export default Router;
