import React from "react";
import CreateItinerary from "../createItenery/CreateItinerary";
import CancelBookingModal from "../../../recievedBookingPage/section/modal/CancelBookingModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import no_result from "../../../../../../../../assets/images/no-result.png";
import Pagination from "../../../../../../../../config/pagination/Pagination";
import { Oval } from "react-loader-spinner";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const BookingTable = (props) => {
  const navigate = useNavigate();

  const [modalShow, setModalShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [getitineraryModal, setGetItineraryModal] = useState(false);

  const [user, setUser] = useState(false);
  const [allActivities, setAllActivities] = useState({});
  const [showRejectModal, setRejectModal] = useState(false);
  const pageNumberLimit = 4;
  const [maxPageLimit, setMaxPageLimit] = useState(4);
  const [minPageLimit, setMinPageLimit] = useState(0);

  const nPages = props.count ? Math.ceil(props.count / 10) : [];
  const bookingList = useSelector(
    (state) => state.touristGuide?.guide_received_bookings
  );
  const itineraryModal = (list) => {
    // console.log(list, "listttt");
    setUser(list);
    setModalShow(true);
  };
  const showPopup = (id) => {
    setUser(id);
    setRejectModal(true);
  };
  const handleMessage = (e) => {
    sessionStorage.setItem("message_id", e);
    navigate("/dashboard/messages");
  };

  const openModal = (list) => {
    setShowModal(true);
    setAllActivities(list);
  };

  const clickhandler = (allActivities) => {
    setGetItineraryModal(true);
    // setAllActivities(list);
  };

  return (
    <>
      <div className="">
        <div className="col-md-12">
          <div
            className={
              props.isLoading === true
                ? "overflow-table loader"
                : "overflow-table"
            }
            data-aos="fade-up"
            style={
              props.count > 10
                ? { height: "calc(100vh - 288px)" }
                : { height: "calc(100vh - 222px)" }
            }
          >
            {props.isLoading === true && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <Oval
                  height={80}
                  width={80}
                  color="#1f74ca"
                  wrapperStyle={{}}
                  wrapperclassName=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#1f74ca8f"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            )}
            {props.isLoading === false && (
              <>
                {bookingList?.rows ? (
                  <table className="table custom-design m-0" data-aos="fade-up">
                    <thead>
                      <tr className="border-bottom w-100">
                        <th className="text-secondary f-700">User Name</th>
                        <th className="text-secondary f-700">Country</th>
                        <th className="text-secondary f-700">State</th>
                        <th className="text-secondary f-700">City</th>

                        {/* <th className="text-secondary f-700">Destination</th> */}
                        {/* <th className="text-secondary f-700">Activities</th>
                        <th className="text-secondary f-700">Type</th> */}
                        {/* <th className="text-secondary f-700">Time Duration</th> */}
                        <th className="text-secondary f-700">Start Date </th>
                        <th className="text-secondary f-700">End Date </th>
                        <th className="text-secondary f-700"></th>

                        <th className="text-secondary f-700 text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookingList?.rows?.map((list, i) => (
                        <tr key={i} className="border-bottom w-100">
                          <td>
                            <h6 className="text-secondary mb-1 f-600">
                              {list.last_name
                                ? `${list.first_name} ${list.last_name}`
                                : list.first_name}
                            </h6>
                            {/* <p className="text-grey mb-0 f-500">{list.email}</p> */}
                          </td>
                          {/* <td>
                            <div className="d-flex align-items-center">
                              <span className="location_iconbox me-2 bg-primary-fade d-flex align-items-center justify-content-center">
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.0004 11.1917C11.4363 11.1917 12.6004 10.0276 12.6004 8.5917C12.6004 7.15576 11.4363 5.9917 10.0004 5.9917C8.56445 5.9917 7.40039 7.15576 7.40039 8.5917C7.40039 10.0276 8.56445 11.1917 10.0004 11.1917Z"
                                    stroke="#1F74CA"
                                    strokeWidth="1.5"
                                  />
                                  <path
                                    d="M3.01675 7.07484C4.65842 -0.141827 15.3501 -0.133494 16.9834 7.08317C17.9417 11.3165 15.3084 14.8998 13.0001 17.1165C11.3251 18.7332 8.67508 18.7332 6.99175 17.1165C4.69175 14.8998 2.05842 11.3082 3.01675 7.07484Z"
                                    stroke="#1F74CA"
                                    strokeWidth="1.5"
                                  />
                                </svg>
                              </span>
                              <p className="text-secondary mb-0 f-600">
                                {list.destination}
                              </p>
                            </div>
                          </td> */}
                          <td>
                            <div className="d-flex align-items-center">
                              <p className="text-secondary text-center mb-0 f-600">
                                {list.country || "---"}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <p className="text-secondary text-center mb-0 f-600">
                                {list.state || "---"}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <p className="text-secondary text-center mb-0 f-600">
                                {list.city || "---"}
                              </p>
                            </div>
                          </td>
                          {/* <td>
                            <div className="d-flex align-items-center">
                              {list?.activities ? (
                                <p
                                  className="text-secondary mb-0 f-600"
                                  onClick={() => openModal(list)}
                                >
                                  {list?.activities?.slice(0, 10) + `...`}
                                </p>
                              ) : (
                                <p className="text-secondary mb-0 f-600 text-center">
                                  ---
                                </p>
                              )}
                            </div>
                          </td> */}
                          {/* <td>
                            <p className="text-secondary mb-0 f-600">
                              {list.booking_slot_start} -{" "}
                              {list.booking_slot_end}
                            </p>
                          </td> */}
                          {/* <td>
                            <div className="d-flex align-items-center">
                              <p className="text-secondary text-center mb-0 f-600">
                                {list.family_type || "---"}
                              </p>
                            </div>
                          </td> */}
                          <td>
                            <p className="text-secondary mb-0 f-600">
                              {moment(list.booking_start, "MM-DD-YYYY").format(
                                "MM-DD-YYYY"
                              )}
                            </p>
                          </td>
                          <td>
                            <p className="text-secondary mb-0 f-600">
                              {moment(list.booking_end, "MM-DD-YYYY").format(
                                "MM-DD-YYYY"
                              )}
                            </p>
                          </td>
                          <td>
                            <button
                              onClick={() => openModal(list)}
                              className="icon_box d-flex align-items-center justify-content-center me-2"
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.3866 7.99995C10.3866 9.31995 9.31995 10.3866 7.99995 10.3866C6.67995 10.3866 5.61328 9.31995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C9.31995 5.61328 10.3866 6.67995 10.3866 7.99995Z"
                                  stroke="#475467"
                                  strokeWidth="1.2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1266 14.0732 9.72665C14.6732 8.78665 14.6732 7.20665 14.0732 6.26665C12.5466 3.86665 10.3532 2.47998 7.9999 2.47998C5.64656 2.47998 3.45323 3.86665 1.92656 6.26665C1.32656 7.20665 1.32656 8.78665 1.92656 9.72665C3.45323 12.1266 5.64656 13.5133 7.9999 13.5133Z"
                                  stroke="#475467"
                                  strokeWidth="1.2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </td>
                          <td className="d-flex align-items-center justify-content-between">
                            <button
                              className="composebtn btn-main btn-primary me-2 text-white w-100 mw-50"
                              // onClick={() =>
                              //   handleMessage(list?.travellerDetails?.id)
                              // }
                              // to={{
                              //   pathname: "/dashboard/messages",
                              //   state: { data: list?.travellerDetails?.id },
                              // }}
                              onClick={() => {
                                navigate("/dashboard/messages", {
                                  replace: true,
                                  state: {
                                    id: list?.travellerDetails?.id,
                                    show: true,
                                  },
                                });
                              }}
                            >
                              {/* Send a message */}
                              Chat
                            </button>

                            <a
                              className="btn-main btn-secondary me-2 w-100 mw-160"
                              data-bs-toggle="modal"
                              data-bs-target="#popup_modal"
                              onClick={() => itineraryModal(list)}
                            >
                              {/* Accept */}
                              Review Itinerary
                              {/* {list.itinerary?.descriptions
                                ? "Review Itinerary"
                                : "Create Itinerary"} */}
                            </a>
                            <a
                              className="btn-main btn-red w-100 mw-160"
                              onClick={() => showPopup(list.id)}
                            >
                              Reject
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <section className="no-result">
                    <div className="container spacer-y pt-0">
                      <div className="row justify-content-center text-center">
                        <div className="col-sm-6 col-lg-4">
                          <img
                            src={no_result}
                            className="mb-3"
                            alt=""
                            data-aos="fade-up"
                          />
                          <h2
                            className="primary__color mb-3 p-0"
                            data-aos="fade-up"
                            data-aos-delay="100"
                          >
                            No Data Found
                          </h2>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </>
            )}
          </div>
        </div>
        {props.isLoading === false && (
          <>
            {props.count > 10 && (
              <div className="row">
                <div className="col-md-12">
                  <div className="pagination-wrapperbox d-flex align-items-center justify-content-between">
                    <div className="data_count p-20 text-grey small-text f-400">
                      <span>
                        Showing
                        <span className="mx-1 f-500">
                          {10 * props.currentPage - 9}
                        </span>
                        to
                        <span className="mx-1 f-500">
                          {10 * props.currentPage -
                            (10 - bookingList?.rows?.length)}
                        </span>
                        of
                        <span className="mx-1 f-500">{props.count}</span>
                        entries
                      </span>
                    </div>
                    <Pagination
                      nPages={nPages}
                      currentPage={props.currentPage}
                      setCurrentPage={props.setCurrentPage}
                      pageNumberLimit={pageNumberLimit}
                      maxPageLimit={maxPageLimit}
                      minPageLimit={minPageLimit}
                      setMinPageLimit={setMinPageLimit}
                      setMaxPageLimit={setMaxPageLimit}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="mw-600"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div>
          <div>
            <div className="modal-content p-30">
              <div className="modal_header position-relative">
                <h3 className="text-grey-500 f-700 mb-2">Details</h3>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body p-0 mt-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Name
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={
                          allActivities?.last_name
                            ? `${allActivities?.first_name} ${allActivities?.last_name}`
                            : allActivities?.first_name
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Country
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={allActivities?.country || "---"}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        State
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={allActivities?.state || "---"}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        City
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={allActivities?.city || "---"}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Type
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={allActivities?.family_type || "---"}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Activities
                      </label>
                      <textarea
                        disabled
                        className={`form-control `}
                        defaultValue={allActivities?.activities || "---"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Start Date
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={moment(
                          allActivities?.booking_start,
                          "MM-DD-YYYY"
                        ).format("MM-DD-YYYY")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        End Date
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={moment(
                          allActivities.booking_end,
                          "MM-DD-YYYY"
                        ).format("MM-DD-YYYY")}
                      />
                    </div>
                  </div>
                  {allActivities?.itinerary?.descriptions && (
                    <div className="col-md-12">
                      <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                        <label
                          className="small-text text-grey-scale mb-1 f-600"
                          htmlFor="name"
                        >
                          Review Itinerary
                        </label>
                        <div disabled className={`form-control `}>
                          {allActivities?.itinerary?.descriptions?.slice(
                            0,
                            150
                          ) + "..."}
                          <button
                            type="button"
                            className="buttonviewMore"
                            onClick={() => clickhandler(allActivities)}
                          >
                            View more
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  <p></p>
                  <div className="col-md-4">
                    <button
                      className="composebtn btn-main btn-primary me-2 text-white w-100 mw-50"
                      // onClick={() =>
                      //   handleMessage(list?.travellerDetails?.id)
                      // }
                      // to={{
                      //   pathname: "/dashboard/messages",
                      //   state: { data: list?.travellerDetails?.id },
                      // }}
                      onClick={() => {
                        navigate("/dashboard/messages", {
                          replace: true,
                          state: { id: allActivities?.travellerDetails?.id },
                        });
                      }}
                    >
                      {/* Send a message */}
                      Chat
                    </button>
                  </div>

                  <div className="col-md-4">
                    <a
                      className="btn-main btn-secondary me-2 w-100 mw-160"
                      data-bs-toggle="modal"
                      data-bs-target="#popup_modal"
                      onClick={() => itineraryModal(allActivities)}
                    >
                      {/* Accept */}
                      Review Itinerary
                      {/* {allActivities?.itinerary?.descriptions
                        ? "Review Itinerary"
                        : "Create Itinerary"} */}
                    </a>
                  </div>
                  <div className="col-md-4">
                    <a
                      className="btn-main btn-red w-100 mw-160"
                      onClick={() => showPopup(allActivities.id)}
                    >
                      Reject
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        show={getitineraryModal}
        onHide={() => setGetItineraryModal(false)}
        size="mw-600"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div>
          <div>
            <div className="modal-content p-30">
              <div className="modal_header position-relative">
                <h3 className="text-grey-500 f-700 mb-2">AI Itinerary</h3>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setGetItineraryModal(false)}
                ></button>
                {/* <p>{allActivities?.itinerary?.descriptions}</p> */}
                <textarea
                  disabled
                  className="w-100"
                  rows={20}
                  columns={20}
                  defaultValue={allActivities?.itinerary?.descriptions}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <CreateItinerary
        show={modalShow}
        onHide={() => setModalShow(false)}
        user={user}
        count={props.count}
      />
      <CancelBookingModal
        show={showRejectModal}
        id={user}
        onHide={() => setRejectModal(false)}
        count={props.count}
      />
    </>
  );
};

export default BookingTable;
