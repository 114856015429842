import React from "react";
import parse from "html-react-parser";

const Banner = ({ dataList }) => {
  return (
    <section className="hero">
      <div className="container spacer-y">
        <div className="row justify-content-center text-center">
          <div className="col-lg-8">
            <h1 className="mb-3 p-0">{dataList && dataList?.value?.title}</h1>
            <div className="m-0">
              {parse(`${dataList && dataList?.value?.description}`)}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
