import React from 'react';
import Banner from './banner/Banner';
import InterestingPlaces from './interestingPlaces/InterestingPlaces';
import RatingReview from './ratingReview/RatingReview';
import ContactUs from '../../homePage/section/contactUs/ContactUs';
import './GuideDetailed.css';
const GuideDetailedPage = () => {
  return (
    <>
      <Banner />
      <InterestingPlaces />
      <RatingReview />
      <ContactUs />
    </>
  )
}

export default GuideDetailedPage;
