import React, { useEffect } from "react";
import "./ContactUs.css";
import any_question_img from "../../../../../assets/images/any_question_img.jpg";
import any_question_logo from "../../../../../assets/images/any_question_logo.png";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { CONTACT_US } from "../../../../../redux/slices/content/ContentSlice";
import { getApi } from "../../../../../config/apiFunctions";
import { website_content } from "../../../../../config/endpoints";

const ContactUs = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.content.contactUs);

  const appsData = () => {
    getApi(`${website_content}?key=HOME_PAGE&section=REACH_US`).then((res) => {
      dispatch(CONTACT_US(res?.data?.data));
    });
  };

  useEffect(() => {
    appsData();
  }, []);
  return (
    <section className="any-question">
      <div className="container spacer-y">
        <div className="row justify-content-end align-items-center">
          <div className="col-lg-6">
            <img
              src={data && data?.value?.banner_image?.upload_image_url}
              className="any_question-image"
              data-aos="fade"
              data-aos-delay="300"
              alt=""
            />
          </div>
          <div className="col-lg-6 col-md-7">
            <div
              className="any_question_content zindex-up"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <h2 className="primary__color m-0">
                {data && data?.value?.title}
              </h2>
              <div className="paragraph_content">
                {parse(`${data && data?.value?.description}`)}
              </div>
              <div className="about_btn">
                <Link to="/contact-us" className="button-primary">
                  Contact us
                </Link>
              </div>
            </div>
            <div className="any_question_logo">
              <img src={any_question_logo} alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
